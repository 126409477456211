import { useMutation } from 'react-query';
import { API } from '../constants';
import { apiClient } from '../services';

export const useSignup = () => {
  const signUp = async (body: {
    name: string;
    email: string;
    phone: string;
    password: string;
    businessName: string;
    referredBy: string | undefined | null;
    type: string | undefined | null;
    freeSignupCode: string | undefined | null;
  }) => {
    const res = await apiClient.post(API.ROUTES.AUTH_SIGNUP, body);
    return res;
  };
  return useMutation(signUp);
};

export const uselinkClicks = () => {
  const linkClicks = async (body: { referralCode?: string | null }) => {
    const res = await apiClient.post(API.ROUTES.LINK_CLICKS, body);
    return res;
  };
  return useMutation(linkClicks);
};
