import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Button,
  Heading,
} from '@chakra-ui/react';

interface IConfirmModal {
  isOpen: boolean;
  onClose: () => void;
  onGoBack: () => void;
}

export default function ConfirmModal(props: IConfirmModal) {
  const { isOpen, onClose, onGoBack } = props;
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent zIndex={100}>
          <ModalBody>
            <Heading
              textAlign="center"
              py="3"
              fontSize="2xl"
              color="brand.primary.main"
            >
              Please Confirm
            </Heading>
            <Text textAlign="center" fontWeight="medium" fontSize="lg">
              We recommend you uploading mailing addresses with your list if
              possible, as it only increases the accuracy of your data
            </Text>
          </ModalBody>

          <ModalFooter display="flex" justifyContent="center">
            <Button
              colorScheme="orange"
              bg="brand.primary.main"
              mr={3}
              onClick={onGoBack}
              id="go-back-btn"
            >
              Go Back
            </Button>
            <Button
              variant="outline"
              colorScheme="orange"
              color="brand.primary.main"
              onClick={onClose}
              id="no-thanks-btn"
            >
              No, Thanks
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
