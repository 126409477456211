import React from 'react';
import { Box, Table, Thead, Tr, Th, Tbody, Td, Button } from '@chakra-ui/react';
import moment from 'moment';
import { Badge, Empty } from '../..';
import { ISkiptraceFilesRecords, ISkiptraceStats } from '../../../interfaces';

const tableHeadings = [
  'Date',
  'List Name',
  'Records Uploaded',
  'Hits',
  'Hit %',
  'Status',
  'Input',
  'Output',
];

interface BulkTraceTableProps {
  bulkTraceData: {
    stats: ISkiptraceStats;
    files: ISkiptraceFilesRecords[] | undefined;
  };
}

export default function BulkTraceTable(props: BulkTraceTableProps) {
  const { bulkTraceData } = props;

  return (
    <>
      <Box overflow="auto" mb="30px">
        <Table mt="30px" variant="simple" size="sm">
          <Thead boxShadow="sm">
            <Tr>
              {tableHeadings.map((heading, i) => (
                <Th
                  fontFamily="poppins"
                  fontSize="15px"
                  color="#2F3442"
                  fontWeight="600"
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  minWidth="10px"
                  py="20px"
                  textAlign="center"
                >
                  {heading}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {bulkTraceData !== undefined &&
              bulkTraceData.files?.map((record: any) => (
                <Tr fontSize="14px" key={record.id}>
                  <Td
                    color="#737373"
                    fontWeight="400"
                    maxWidth="10vw"
                    py="20px"
                    textAlign="center"
                  >
                    {moment(record.createdAt).format('DD-MM-YYYY')}
                  </Td>
                  <Td
                    color="#737373"
                    fontWeight="400"
                    maxWidth="10vw"
                    py="20px"
                    textAlign="center"
                  >
                    {record.listName}
                  </Td>

                  <Td
                    color="#737373"
                    fontWeight="400"
                    maxWidth="10vw"
                    py="20px"
                    textAlign="center"
                  >
                    {record.records}
                  </Td>
                  <Td
                    color="#737373"
                    fontWeight="400"
                    maxWidth="10vw"
                    py="20px"
                    textAlign="center"
                  >
                    {record.status === 'success' && record?.skiptracedRecords}
                  </Td>
                  <Td
                    color="#737373"
                    fontWeight="400"
                    maxWidth="10vw"
                    py="20px"
                    textAlign="center"
                  >
                    {record?.skiptracedRecords && record.status === 'success'
                      ? (
                          (record?.skiptracedRecords / record.records) *
                          100
                        ).toFixed(2)
                      : 0}
                    %
                  </Td>
                  <Td
                    color="#737373"
                    fontWeight="400"
                    maxWidth="10vw"
                    py="20px"
                    textAlign="center"
                  >
                    <Box>
                      <Badge
                        variant={
                          record.status.toLowerCase() === 'needsreview' ||
                          record.status.toLocaleLowerCase() === 'processing'
                            ? 'pending'
                            : record.status.toLowerCase()
                        }
                      >
                        {record.status === 'needsReview' ||
                        record.status.toLocaleLowerCase() === 'processing'
                          ? 'Pending'
                          : record.status === 'success'
                          ? 'Completed'
                          : record.status}
                      </Badge>
                    </Box>
                  </Td>
                  <Td
                    color="#737373"
                    fontWeight="400"
                    maxWidth="40vw"
                    py="20px"
                    textAlign="center"
                  >
                    {record.url && (
                      <Button
                        as="a"
                        href={record.url}
                        target="_blank"
                        rel="noreferrer"
                        id="viewFile"
                        size="sm"
                        fontSize="12px"
                        bgColor="brand.primary.main"
                        colorScheme="orange"
                      >
                        View File
                      </Button>
                    )}
                  </Td>

                  <Td
                    textAlign="center"
                    color="#737373"
                    fontWeight="400"
                    maxWidth="10vw"
                    py="20px"
                  >
                    {record.outputUrl && record.status === 'success' && (
                      <Button
                        href={record.outputUrl}
                        target="_blank"
                        rel="noreferrer"
                        as="a"
                        id="downloadLink"
                        size="sm"
                        bgColor="brand.primary.main"
                        colorScheme="orange"
                      >
                        Download
                      </Button>
                    )}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
        {bulkTraceData.files?.length === 0 && (
          <Box
            id="emptyComponent"
            display="flex"
            py="12"
            w="full"
            justifyContent="center"
          >
            <Empty message="No records available" />
          </Box>
        )}
      </Box>
    </>
  );
}
