import React from 'react';
import { Skeleton, Stack, Text, VStack, BoxProps } from '@chakra-ui/react';

interface ISats extends BoxProps {
  title: string | undefined;
  value: number | string | undefined;
  color: string | undefined;
  isLoading?: boolean;
}

const Stats = (props: ISats) => {
  const { id, title, value, color, isLoading, ...otherProps } = props;
  return (
    <VStack
      align="start"
      key={id}
      w="full"
      h="full"
      border="1px"
      borderColor="gray.200"
      borderRadius="md"
      p="15px"
      spacing={0}
      {...otherProps}
    >
      <Text color="gray.500" fontSize="18px">
        {title}
      </Text>
      {isLoading ? (
        <Stack w="full">
          <Skeleton height="20px" w="50%" mt="10px" />
        </Stack>
      ) : (
        <Text fontSize="35px" fontWeight="bold" color={color}>
          {value}
        </Text>
      )}
    </VStack>
  );
};

export default Stats;
