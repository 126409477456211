import { createSlice } from '@reduxjs/toolkit';

const affiliatesSlice = createSlice({
  name: 'affiliates',
  initialState: {
    startDate: null,
    endDate: null,
  },
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
  },
});

export const { setEndDate, setStartDate } = affiliatesSlice.actions;

export default affiliatesSlice.reducer;
