import React from 'react';
import { VStack, Text, Container } from '@chakra-ui/react';

const AffiliatesAssets = () => (
  <VStack w="full">
    <Container maxW="3xl">
      <VStack w="full" spacing="12">
        <VStack w="full" rounded="md" spacing="8" shadow="md" px="8" py="6">
          <Text fontSize="2xl" color="gray.600">
            Your latest commissions
          </Text>
          <Text color="gray.500">
            There are no uploaded assets at this time.
          </Text>
        </VStack>
      </VStack>
    </Container>
  </VStack>
);

export default AffiliatesAssets;
