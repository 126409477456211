const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/';
const APP_URL = process.env.REACT_APP_URL || 'http://localhost:3000/';

const ROUTES = {
  AUTH_LOGIN: 'auth/login',
  AUTH_SIGNUP: 'auth/signup',
  LINK_CLICKS: 'user/link-clicks',
  AUTH_FORGOT_PASSWORD: 'auth/forgot-password',
  AUTH_RESET_PASSWORD: 'auth/reset-password',
  AUTH_VERIFY_EMAIL: 'auth/verify-email',
  AUTH_RESEND_VERIFICATION_EMAIL: 'auth/resend-varification-email',
  GET_ME: 'users/me',
  UPDATE_PROFILE: 'users/me/profile',
  UPDATE_PASSWORD: 'users/me/password',
  ADD_STRIPE_CONNECT_ACCOUNT_ID: 'users/me/add-stripe-connect-account-id',
  USER_METRICS: 'users/metrics',
  AFFILIATE_STATUS: 'user/affiliate-status',
  USER_AFFILIATE_PERFORMANCE_DETAILS: 'user/affiliate/performance-details',
  SKIPTRACE_SINGLE_LEAD: 'skiptrace/single-lead',
  SKIPTRACE_SINGLE_LEADS: 'skiptrace/single-leads',
  SKIPTRACE_FIlES: 'skiptrace/files',
  UPLOAD_SKIPTRACE_FIlE: 'skiptrace/file',
  UPLOAD_SKIPTRACE_FIlE_DOC: 'skiptrace/file-document',
  CREATE_PAYMENT_METHOD: 'billing/create-payment-method',
  CREATE_PAYMENT_INTENT: 'billing/create-payment-intent',
  STRIPE_CONNECT_CREATE_ACCOUNT: 'billing/stripe-connect-create-account',
  CHARGE_WITH_EXISTING_CARD: 'billing/charge-with-existing-card',
  PADDLE_GENERATE_PAY_LINK: '/billing/generate-pay-link',
  ADD_NEW_CARD: 'billing/add-new-card',
  DETACH_PAYMENT_METHOD: 'billing/detach-payment-method',
  GET_PAYMENT_METHOD_AND_INVOICES_BY_USER: 'billing',
  PAY_WITH_CURRENT_BALANCE: 'billing/pay-with-current-balance',
  APPLY_PROMO_CODE: 'billing/apply-promo-code',
  SKIPTRACE_MAPPING: 'skiptrace/map',
  SKIPTRACE_INVOICES: 'skiptrace/invoices',
  SKIPTRACE_ORDERS: 'skiptrace/orders',
  USER_AFFILIATE_COMISSION: 'user/affiliate/referral-details',
  USER_AFFILIATE_STATS: 'user/affiliate/user-stats',
  USER_AFFILIATE_PAID_COMISSION: 'user/affiliate/paid-commisions',
  UPDATE_AFFILIATE_REFERRAL_CODE: 'user/affiliate/update-referral-code',
};

export default { API_URL, ROUTES, APP_URL };
