import { useMutation } from 'react-query';
import { API } from '../constants';
import { apiClient } from '../services';

const useVerifyEmail = () => {
  const verifyEmail = async (verificationToken: string) => {
    const res = await apiClient.post(
      `${API.ROUTES.AUTH_VERIFY_EMAIL}?token=${verificationToken}`
    );
    return res;
  };

  return useMutation(verifyEmail);
};

export const useResendVerificationEmail = () => {
  const resendEmail = async () => {
    const res = await apiClient.post(
      `${API.ROUTES.AUTH_RESEND_VERIFICATION_EMAIL}`
    );
    return res;
  };

  return useMutation(resendEmail);
};

export default useVerifyEmail;
