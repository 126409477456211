import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Grid,
  Box,
  useToast,
  VStack,
  Heading,
  HStack,
  Text,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import Joyride, { CallBackProps } from 'react-joyride';
import { FileRejection } from 'react-dropzone';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { BiCheckCircle } from 'react-icons/bi';
import { RiPlayMiniLine } from 'react-icons/ri';
import { Dropzone } from '../..';
import { RootState } from '../../../slices';

interface BulkSkipTraceModelProps {
  onClose: () => void;
  isOpen: boolean;
  isLoading: boolean;
  onUploadFile: (file: File) => void;
}

function BulkSkipTraceModel(props: BulkSkipTraceModelProps) {
  const [run, setRun] = useState(false);
  const toast = useToast();
  const { isOpen, onClose, isLoading, onUploadFile } = props;

  const { tourStarted } = useSelector((state: RootState) => state.app);

  const onDrop = (acceptedFiles: File[], fileRejections?: FileRejection[]) => {
    if (fileRejections && fileRejections[0]) {
      return toast({
        title: 'File Type Error',
        description: 'File type must be .xlsx or .csv',
        isClosable: true,
        duration: 4000,
        status: 'error',
      });
    }
    return onUploadFile(acceptedFiles[0]);
  };

  const handleClose = (callbackData: CallBackProps) => {
    if (callbackData.action === 'close') {
      localStorage.setItem('step3', JSON.stringify(1));
    }
  };

  useEffect(() => {
    if (tourStarted && !JSON.parse(localStorage.getItem('step3')!)) {
      setRun(true);
    }
  }, [tourStarted, JSON.parse(localStorage.getItem('step3')!)]);

  return (
    <>
      <Modal
        size="3xl"
        isCentered
        autoFocus={false}
        isOpen={isOpen}
        id="bulkFileUploadModal"
        onClose={onClose}
      >
        <Joyride
          run={run}
          callback={(callbackData: CallBackProps) => handleClose(callbackData)}
          steps={[
            {
              target: '.step3',
              content: (
                <div className="custom-tooltip">
                  <h1>Step 3:</h1>
                  <p>
                    Click on upload file and choose a file from your computer to
                    upload
                  </p>
                </div>
              ),
              disableBeacon: true,
              disableOverlayClose: true,
              hideCloseButton: true,
              placement: 'right',

              locale: {
                close: 'Got it',
              },
            },
          ]}
          styles={{
            options: {
              primaryColor: '#ff824c',
              zIndex: 2000,
            },
          }}
        />
        <ModalOverlay />
        <ModalContent py="4" fontFamily="rubik">
          <ModalHeader>Upload File</ModalHeader>
          <ModalCloseButton
            _focus={{ offset: '0' }}
            color="brand.primary.main"
          />
          <ModalBody pb="25px">
            <Grid h="full" templateColumns="repeat(2, 1fr)" gap={4}>
              <Dropzone onDrop={onDrop} fileUploadLoading={isLoading} />

              <VStack>
                <Box w="full" shadow="sm" rounded="xl" px="5" py="3">
                  <VStack alignItems="flex-start" w="full">
                    <HStack color="brand.primary.main">
                      <Text as="h1" fontSize="lg" fontWeight="semibold">
                        Skip Tracing Wizard
                      </Text>
                    </HStack>
                    <HStack color="brand.primary.main" pt="2">
                      <Box>
                        <RiPlayMiniLine />
                      </Box>
                      <Heading
                        as="h1"
                        fontSize="xs"
                        color="gray.500"
                        fontWeight="normal"
                      >
                        Upload File
                      </Heading>
                    </HStack>

                    <HStack color="brand.primary.main">
                      <Box>
                        <RiPlayMiniLine />
                      </Box>
                      <Heading
                        as="h1"
                        fontSize="xs"
                        color="gray.500"
                        fontWeight="normal"
                      >
                        Field Mapping
                      </Heading>
                    </HStack>

                    <HStack color="brand.primary.main">
                      <Box>
                        <RiPlayMiniLine />
                      </Box>
                      <Heading
                        as="h1"
                        fontSize="xs"
                        color="gray.500"
                        fontWeight="normal"
                      >
                        Preview Result
                      </Heading>
                    </HStack>

                    <HStack color="brand.primary.main">
                      <Box>
                        <RiPlayMiniLine />
                      </Box>
                      <Heading
                        as="h1"
                        fontSize="xs"
                        color="gray.500"
                        fontWeight="normal"
                      >
                        Payment
                      </Heading>
                    </HStack>
                  </VStack>
                </Box>

                <Box w="full" shadow="sm" rounded="xl" px="5" py="3">
                  <VStack alignItems="flex-start" w="full">
                    <HStack color="brand.primary.main">
                      <BsQuestionCircleFill />
                      <Text as="h1" fontSize="lg" fontWeight="semibold">
                        SKIP TRACING SEARCH
                      </Text>
                    </HStack>
                    <Text lineHeight="shorter" color="gray.500" fontSize="xs">
                      Skipspot is a best way to retrieve fast results on current
                      phone numbers for leads. Our team skip traces each address
                      in your list and returns an updated address with an
                      attached phone number(s). You can view/download your
                      results in your orders page.
                    </Text>

                    <HStack color="brand.primary.main" pt="2">
                      <Box>
                        <BiCheckCircle />
                      </Box>
                      <Heading as="h1" fontSize="xs" fontWeight="normal">
                        Up to 7 phone numbers per lead
                      </Heading>
                    </HStack>

                    <HStack color="brand.primary.main">
                      <Box>
                        <BiCheckCircle />
                      </Box>
                      <Heading as="h1" fontSize="xs" fontWeight="normal">
                        Fast turnaround
                      </Heading>
                    </HStack>

                    <HStack color="brand.primary.main">
                      <Box>
                        <BiCheckCircle />
                      </Box>
                      <Heading as="h1" fontSize="xs" fontWeight="normal">
                        No maximum CSV file
                      </Heading>
                    </HStack>

                    <HStack color="brand.primary.main">
                      <Box display="flex" alignItems="flex-start">
                        <BiCheckCircle />
                      </Box>
                      <Heading as="h1" fontSize="xs" fontWeight="normal">
                        Required fields for each lead: Address, City, State, Zip
                      </Heading>
                    </HStack>

                    <HStack color="brand.primary.main">
                      <Box display="flex" alignItems="flex-start" h="6">
                        <BiCheckCircle />
                      </Box>
                      <Heading as="h1" fontSize="xs" fontWeight="normal">
                        Get refunded directly in your account for every lead we
                        do not find accurate numbers for
                      </Heading>
                    </HStack>
                  </VStack>
                </Box>
              </VStack>
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BulkSkipTraceModel;
