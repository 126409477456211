import React from 'react';
import { Box, Text, Container, Image, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import auth from '../../../assets/svg/login/auth.jpg';
import logo from '../../../assets/logo.png';

export interface AuthContainerProps {
  children: React.ReactNode;
  description: string;
  to: string;
  buttonText: string;
}

const AuthContainer = (props: AuthContainerProps) => {
  const { children, buttonText, description, to } = props;
  return (
    <Box
      fontFamily="barlow"
      w="full"
      px={{ base: '0', md: '20' }}
      minH={{ base: 'full', md: '100vh' }}
      bgColor="gray.50"
      alignItems="center"
      justifyContent="center"
      display="flex"
      py={{ base: 10, md: '32px' }}
    >
      <Container maxW="container.xl">
        <Flex
          height={{ base: 'full', md: 'calc(100vh + 100px)', '2xl': '1000px' }}
          alignItems="center"
          flexDir={{ base: 'column-reverse', md: 'row' }}
          bgColor="white"
          boxShadow="lg"
          rounded="3xl"
        >
          <Box
            bgColor="white"
            h="full"
            w={{ base: 'full', md: '50%' }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDir="column"
            py={{ base: '10', md: '0' }}
            borderLeftRadius={{ base: '0', md: '3xl' }}
          >
            {children}
          </Box>
          <Box
            display={{ base: 'none', md: 'block' }}
            w={{ base: 'full', md: '50%' }}
            borderRightRadius={{ base: '0', md: '3xl' }}
            h="full"
            overflow="hidden"
            pos="relative"
          >
            <Image src={auth} w="full" h="full" />

            <Box
              top="0"
              className="auth-bg-gradient"
              pos="absolute"
              w="full"
              h="full"
              opacity="0.8"
            />

            <Box
              px="10"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              zIndex="99"
              pos="absolute"
              top="0"
              h="full"
              pb="20px"
            >
              <Image src={logo} />
              <Text
                fontSize={{ base: 'xl', md: '4xl' }}
                fontWeight={{ base: 'normal', md: 'bold' }}
                textAlign="center"
                color="white"
              >
                {description}
              </Text>
              {/* <Button
                onClick={() => onOpen()}
                leftIcon={<BiPlayCircle size={32} />}
                id="loginBtn"
                color="brand.primary.main"
                colorScheme="gray"
                mt={6}
                rounded="2xl"
                py="7"
                type="submit"
              >
                Watch Demo
              </Button> */}
              <Text
                as={Link}
                to={to}
                color="white"
                textDecoration="underline"
                fontSize="2xl"
              >
                {buttonText}
              </Text>
              {/* <VideoPlayer isOpen={isOpen} onClose={onClose} /> */}
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default AuthContainer;
