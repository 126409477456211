import { useQuery, useMutation } from 'react-query';
import { apiClient } from '../services';
import { API } from '../constants';

export const useAffiliateStatus = () => {
  // eslint-disable-next-line no-empty-pattern
  const updateAffiliateStatus = ({}) =>
    apiClient.get(API.ROUTES.AFFILIATE_STATUS);

  return useMutation(updateAffiliateStatus);
};

export const useAffiliateUserStats = () => {
  const UpdateAffiliateUserStats = () =>
    apiClient.get(API.ROUTES.USER_AFFILIATE_STATS);

  return useQuery('useAffiliateUserStats', UpdateAffiliateUserStats, {
    refetchOnWindowFocus: false,
  });
};

export const useGetAffiliatePerformanceDetails = () => {
  const AffiliatePerformanceDetails = async ({
    fromDate,
    toDate,
  }: {
    fromDate: string;
    toDate: string;
  }) => {
    const { data } = await apiClient.get(
      `${API.ROUTES.USER_AFFILIATE_PERFORMANCE_DETAILS}?toDate=${toDate}&fromDate=${fromDate}`
    );
    return data;
  };

  return useMutation(AffiliatePerformanceDetails);
};

export const useGetReferralComission = () => {
  const getReferralComission = async () => {
    const { data } = await apiClient.get(API.ROUTES.USER_AFFILIATE_COMISSION);
    return data?.referralDetails;
  };
  return useQuery(['getReferralComission'], getReferralComission);
};

export const useGetPaidCommissions = ({
  fromDate,
  toDate,
}: {
  fromDate: string;
  toDate: string;
}) => {
  const getPaidCommissions = async () => {
    const { data } = await apiClient.get(
      `${API.ROUTES.USER_AFFILIATE_PAID_COMISSION}?fromDate=${fromDate}&toDate=${toDate}`
    );
    return data;
  };

  return useQuery(
    ['affiliatePaidCommission', fromDate, toDate],
    getPaidCommissions
  );
};

export const useUpdateReferralCode = () => {
  const updateReferralCode = async (body: { referralCode: string }) => {
    const res = await apiClient.post(
      API.ROUTES.UPDATE_AFFILIATE_REFERRAL_CODE,
      body
    );
    return res;
  };
  return useMutation(updateReferralCode);
};
