import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Box, Text, Stack, Button, useToast } from '@chakra-ui/react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { routes } from './routes';
import { Guarded, Spinner, Layout } from './components';
import { RootState, useAppDispatch } from './slices';
import { setCurrentUser } from './slices/app';
import { useGetCurrentUser } from './hooks';
import { useResendVerificationEmail } from './hooks/useVerifyEmail';

function App() {
  const { mutate, isLoading } = useResendVerificationEmail();
  const toast = useToast();
  const { balance } = useSelector((state: RootState) => state.billing);
  const { currentUser } = useSelector((state: RootState) => state.app);
  const dispatch = useAppDispatch();
  const { data } = useGetCurrentUser();
  const history = useHistory();

  React.useEffect(() => {
    dispatch(setCurrentUser(data));
  }, [data]);

  const logout = () => {
    dispatch(setCurrentUser(null));
    localStorage.removeItem('authToken');
    history.push('/login');
  };

  const onResendEmail = () => {
    // @ts-ignore
    mutate(null, {
      onSuccess: (res) => {
        toast({
          description: res.data.message,
          position: 'top-right',
          isClosable: true,
          status: 'success',
        });
      },
      onError: (err: any) => {
        toast({
          description: err.response.data.message,
          position: 'top-right',
          isClosable: true,
          status: 'error',
        });
      },
    });
  };

  return (
    <Box fontFamily="rubik">
      {currentUser
        ? !currentUser.active && (
            <Stack
              direction="row"
              bgColor="yellow.200"
              py="5px"
              alignItems="center"
              justifyContent="center"
            >
              <Text textAlign="center" color="gray.600">
                We’ve sent verification email to {currentUser?.email}. Verify
                your email to continue using SkipSpot. Didn’t get the email?
              </Text>
              <Button
                onClick={onResendEmail}
                isLoading={isLoading}
                size="sm"
                colorScheme="orange"
                bgColor="brand.primary.main"
              >
                Resend
              </Button>
            </Stack>
          )
        : null}
      <Switch>
        {routes.map((Params, i) => {
          const comp = () => (
            <Suspense fallback={<Spinner />}>
              {Params.guarded ? (
                <Layout
                  currentUser={currentUser}
                  balance={balance}
                  logout={logout}
                >
                  <Params.Comp />
                </Layout>
              ) : (
                <Params.Comp />
              )}
            </Suspense>
          );
          return (
            <Route
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              exact={Params.exact}
              path={Params.path}
              render={
                Params.guarded
                  ? () => <Guarded redirectTo="/login">{comp()}</Guarded>
                  : () => comp()
              }
            />
          );
        })}
        <Redirect exact from="/" to="/dashboard" />
      </Switch>
    </Box>
  );
}

export default App;
