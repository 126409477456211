import React from 'react';
import {
  Modal,
  ModalBody,
  Text,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  VStack,
  Box,
} from '@chakra-ui/react';
import { TiTickOutline } from 'react-icons/ti';

interface FreeSignUpModalProps {
  isOpen: boolean;
  onClose: () => void;
  freeRecords: number;
}

function FreeSignUpModal({
  isOpen,
  onClose,
  freeRecords,
}: FreeSignUpModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader> </ModalHeader>
        <ModalCloseButton
          onClick={onClose}
          color="brand.primary.main"
          _focus={{ offset: 0 }}
        />
        <ModalBody p="12" textAlign="center">
          <VStack spacing="5">
            <Box
              bg="orange.100"
              p="1"
              border="2px"
              borderColor="brand.primary.main"
              color="brand.primary.main"
              rounded="full"
            >
              <TiTickOutline size={36} />
            </Box>
            <VStack>
              <Text color="brand.primary.main">Welcome to SkipSpot!</Text>
              <Text color="black">
                You can now skip trace {freeRecords} records for FREE.
              </Text>
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default FreeSignUpModal;
