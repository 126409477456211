/* eslint-disable no-nested-ternary */
import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Input } from '@chakra-ui/react';
import './index.css';

export interface LocationProps {
  label: string;
  name: string;
  value: string;
  placeholderName: string;
  handleChange: (e: any) => void;
}

export default function LocationSearchInput(props: LocationProps) {
  const { name, label, placeholderName, value, handleChange } = props;

  return (
    <PlacesAutocomplete
      value={value}
      onChange={(e) => {
        handleChange({ target: { name, value: e } });
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div>
          <Input
            {...getInputProps({
              placeholder: placeholderName,
              className: 'location-search-input',
              label,
              autocomplete: 'of',
            })}
          />
          {suggestions.length === 0 ? (
            ''
          ) : (
            <div className="autocomplete-dropdown">
              {suggestions.map((suggestion, i) => {
                // const className = suggestion.active
                //   ? 'suggestion-item--active'
                //   : 'suggestion-item';
                // inline style for demonstration purpose

                const style = suggestion.active
                  ? suggestion.active && i === suggestions.length - 1
                    ? {
                        backgroundColor: '#F0F0F0',
                        cursor: 'pointer',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                      }
                    : suggestion.active && i === 0
                    ? {
                        backgroundColor: '#F0F0F0',
                        cursor: 'pointer',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                      }
                    : { backgroundColor: '#F0F0F0', cursor: 'pointer' }
                  : { cursor: 'pointer' };
                return (
                  <div
                    className="input-suggestion"
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                    key={i}
                  >
                    <i className="material-icons">location_on </i>{' '}
                    <span className="text-description">
                      {suggestion.description}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
}
