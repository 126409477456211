import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  HStack,
  Box,
  Text,
  VStack,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
} from '@chakra-ui/react';
import Joyride, { CallBackProps } from 'react-joyride';
import { RiErrorWarningLine } from 'react-icons/ri';
import ConfirmModal from './ConfirmModal';
import { RootState } from '../../../../slices';

export default function PreviewData(props: {
  onSetFilename: (name: string) => void;
  filename: string;
  onGoBack: () => void;
}) {
  const [run, setRun] = useState(false);
  const {
    skiptrace: {
      fieldMappingColumns: { mappedColumns },
      selectedFields,
    },
    app: { tourStarted },
  } = useSelector((state: RootState) => state);

  const {
    isOpen: isConfirmModalOpen,
    onOpen: onConfirmModalOpen,
    onClose: onConfirmModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (!selectedFields.find((col) => col.value === 'Mailing Address')) {
      onConfirmModalOpen();
    }
  }, []);

  useEffect(() => {
    if (tourStarted && !JSON.parse(localStorage.getItem('step6')!)) {
      setRun(true);
    }
  }, [tourStarted, !JSON.parse(localStorage.getItem('step6')!)]);

  const { onSetFilename, filename, onGoBack } = props;

  const sortedColumns = [...selectedFields].sort((a, b) => {
    if (a.value > b.value) {
      return 1;
    }
    if (a.value < b.value) {
      return -1;
    }
    return 0;
  });

  const handleClose = (callbackData: CallBackProps) => {
    if (callbackData.status === 'finished') {
      localStorage.setItem('step6', JSON.stringify(1));
      localStorage.setItem('step7', JSON.stringify(1));
    }
  };

  return (
    <VStack
      className="step6"
      fontFamily="Poppins"
      w="full"
      spacing={10}
      align="start"
    >
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={onConfirmModalClose}
        onGoBack={onGoBack}
      />
      <HStack>
        <Joyride
          run={run}
          callback={(callbackData: CallBackProps) => handleClose(callbackData)}
          steps={[
            {
              target: '.step6',
              content: (
                <div className="custom-tooltip">
                  <h1>Step 6:</h1>
                  <p>Review the column mapping and add a name to the list.</p>
                </div>
              ),
              placement: 'bottom',
              disableBeacon: true,
              disableOverlayClose: true,
              hideCloseButton: true,
            },
            {
              target: '.step5',
              content: (
                <div className="custom-tooltip">
                  <h1>Step 7:</h1>
                  <p>Click Next</p>
                </div>
              ),
              disableBeacon: true,
              disableOverlayClose: true,
              hideCloseButton: true,
              hideBackButton: true,
              locale: {
                last: 'Got it',
              },
            },
          ]}
          styles={{
            options: {
              primaryColor: '#ff824c',
              zIndex: 3000,
            },
          }}
          continuous
        />
        <Text fontWeight="600" color="#737373">
          Name your list
        </Text>
        <Input
          id="filenameInput"
          value={filename}
          onChange={(e) => onSetFilename(e.target.value)}
          w="200px"
          focusBorderColor="brand.primary.main"
          size="sm"
        />
        {filename ? null : (
          <HStack spacing={1} color="brand.primary.main">
            <RiErrorWarningLine />
            <Text>Please name list to continue</Text>
          </HStack>
        )}
      </HStack>
      <Box w="full">
        {sortedColumns.length === 0 ? (
          <Text color="brand.primary.main" textAlign="center">
            Please go back and select the columns
          </Text>
        ) : (
          <Box w="100%" overflow="auto">
            <Table variant="simple" size="sm">
              <Thead boxShadow="sm">
                <Tr>
                  {sortedColumns.map((column) => (
                    <Th key={column.columnIndex}>{column.value}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {mappedColumns.data.map((data, i) => (
                  <Tr key={i}>
                    {sortedColumns.map((column) => (
                      <Td>{data[column.columnIndex]}</Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </Box>
    </VStack>
  );
}
