import React from 'react';
import { Helmet } from 'react-helmet';

export default function PageTitle(props: { title: string }) {
  const { title } = props;
  return (
    <Helmet>
      <title>{`${title} | SkipSpot`}</title>
    </Helmet>
  );
}
