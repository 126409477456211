import React from 'react';
import { Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';

interface Top10TableProps {
  tableHeadings: string[];
}

const CTable = ({ tableHeadings }: Top10TableProps) => (
  <Table mt="30px" variant="simple" size="sm">
    <Thead boxShadow="sm">
      <Tr>
        {tableHeadings.map((heading, i) => (
          <Th
            fontFamily="poppins"
            fontSize="15px"
            color="brand.primary.main"
            fontWeight="600"
            key={i}
            minWidth="10px"
            py="20px"
            textAlign="center"
          >
            {heading}
          </Th>
        ))}
      </Tr>
    </Thead>
    <Tbody>
      <Tr fontSize="14px">
        <Td
          color="#737373"
          fontWeight="400"
          maxWidth="10vw"
          py="20px"
          textAlign="center"
        >
          This Week
        </Td>

        <Td
          color="#737373"
          fontWeight="400"
          maxWidth="10vw"
          py="20px"
          textAlign="center"
        >
          0
        </Td>
        <Td
          color="#737373"
          fontWeight="400"
          maxWidth="10vw"
          py="20px"
          textAlign="center"
        >
          0
        </Td>
        <Td
          color="#737373"
          fontWeight="400"
          maxWidth="10vw"
          py="20px"
          textAlign="center"
        >
          0
        </Td>
      </Tr>
      <Tr fontSize="14px">
        <Td
          color="#737373"
          fontWeight="400"
          maxWidth="10vw"
          py="20px"
          textAlign="center"
        >
          Oct 25
        </Td>

        <Td
          color="#737373"
          fontWeight="400"
          maxWidth="10vw"
          py="20px"
          textAlign="center"
        >
          0
        </Td>
        <Td
          color="#737373"
          fontWeight="400"
          maxWidth="10vw"
          py="20px"
          textAlign="center"
        >
          0
        </Td>
      </Tr>
      <Tr fontSize="14px">
        <Td
          color="#737373"
          fontWeight="400"
          maxWidth="10vw"
          py="20px"
          textAlign="center"
        >
          Oct 25
        </Td>

        <Td
          color="#737373"
          fontWeight="400"
          maxWidth="10vw"
          py="20px"
          textAlign="center"
        >
          0
        </Td>
        <Td
          color="#737373"
          fontWeight="400"
          maxWidth="10vw"
          py="20px"
          textAlign="center"
        >
          0
        </Td>
        <Td
          color="#737373"
          fontWeight="400"
          maxWidth="10vw"
          py="20px"
          textAlign="center"
        >
          0
        </Td>
      </Tr>
    </Tbody>
  </Table>
);

export default CTable;
