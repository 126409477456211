import { createSlice } from '@reduxjs/toolkit';
import { IApp } from '../../interfaces';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    currentUser: null,
    isFreeSignUp: false,
    isSignup: false,
    freeRecords: 0,
    tourStarted: false,
    tourPending: false,
    step1Run: false,
  } as IApp,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setIsFreeSignup: (state, action) => {
      state.isFreeSignUp = action.payload;
    },
    setFreeRecords: (state, action) => {
      state.freeRecords = action.payload;
    },
    setIsSignup: (state, action) => {
      state.isSignup = action.payload;
    },
    setTourStarted: (state, action) => {
      state.tourStarted = action.payload;
      state.isSignup = false;
    },
    setTourPending: (state, action) => {
      state.tourPending = action.payload;
    },
    setStep1Run: (state, action) => {
      state.step1Run = action.payload;
    },
  },
});

export const {
  setCurrentUser,
  setIsFreeSignup,
  setFreeRecords,
  setIsSignup,
  setTourStarted,
  setTourPending,
  setStep1Run,
} = appSlice.actions;

export default appSlice.reducer;
