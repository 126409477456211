const Button = {
  baseStyle: {
    _focus: {
      outline: 'none',
      boxShadow: '0 0 0 2px #FF9466',
    },
    fontWeight: '400',
    _hover: {
      transform: 'scale(.98)',
      transition: '.3s ease-in-out',
    },
  },
};

export default Button;
