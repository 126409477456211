export { default as ERROR_CODES, ERROR_MESSAGE } from './errors';
export { default as API } from './api';
export const costUnder5kRecords = '0.15';
export const costAbove5kRecords = '0.12';

export const STRIPE_KEY =
  process.env.REACT_APP_STRIPE_KEY ||
  'pk_test_51Jke54Erjbba5j0EHjVwujq635QKJ2A5TjS62YQcDNk2D7mOSaW3kVVmAv3XG0SiBZS5fQssZdJd6ktaoRPAL3MH00xqrPPAza';

export const REACT_APP_URL = `${location.protocol}//${location.host}`;
