import { combineReducers } from '@reduxjs/toolkit';
import appReducer from './app';
import skiptraceReducer from './skiptrace';
import billingReducer from './billing';
import authReducer from './auth';
import affiliatesReducer from './affiliates';

const rootReducer = combineReducers({
  app: appReducer,
  skiptrace: skiptraceReducer,
  billing: billingReducer,
  auth: authReducer,
  affiliates: affiliatesReducer,
});

export default rootReducer;
