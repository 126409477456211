import React, { useEffect } from 'react';
import './index.css';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch, Link } from 'react-router-dom';
import { Box, VStack, Tooltip } from '@chakra-ui/react';
import { BiHomeAlt } from 'react-icons/bi';
import { VscFiles, VscFile } from 'react-icons/vsc';
import { FaRegHeart } from 'react-icons/fa';
import { FiCreditCard } from 'react-icons/fi';
import Joyride, { CallBackProps } from 'react-joyride';
import { RootState } from '../../../slices';
import { setStep1Run } from '../../../slices/app';

export default function Sidebar() {
  const dispatch = useDispatch();
  const { tourStarted, step1Run } = useSelector(
    (state: RootState) => state.app
  );

  useEffect(() => {
    if (tourStarted && !JSON.parse(localStorage.getItem('step1')!)) {
      dispatch(setStep1Run(true));
    }
  }, [tourStarted, JSON.parse(localStorage.getItem('step1')!)]);

  const icons = [
    {
      id: 1,
      Icon: BiHomeAlt,
      name: 'Dashboard',
      to: '/dashboard',
    },
    {
      id: 2,
      isTour: true,
      Icon: VscFiles,
      name: 'Bulk Trace',
      to: '/bulk-trace',
    },
    {
      id: 3,
      Icon: VscFile,
      name: 'Single Lead',
      to: '/single-lead',
    },
    {
      id: 5,
      Icon: FiCreditCard,
      name: 'Billing',
      to: '/billing',
    },
    {
      id: 6,
      Icon: FaRegHeart,
      name: 'Affiliate',
      to: '/affiliates/dashboard',
    },
  ];

  const match = useRouteMatch();

  const handleClose = (callbackData: CallBackProps) => {
    if (callbackData.action === 'close') {
      localStorage.setItem('step1', JSON.stringify(1));
      dispatch(setStep1Run(false));
    }
  };

  return (
    <>
      <Joyride
        run={step1Run}
        callback={(callbackData: CallBackProps) => handleClose(callbackData)}
        steps={[
          {
            target: '.step1',
            content: (
              <div className="custom-tooltip">
                <h1>Step 1:</h1>
                <p>Go to bulk skiptrace page</p>
              </div>
            ),
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            placement: 'right',
            locale: {
              close: 'Got it',
            },
          },
        ]}
        styles={{
          options: {
            primaryColor: '#ff824c',
          },
        }}
      />
      <VStack spacing={9} pt="120px" minHeight="100vh" pos="relative">
        {icons.map(({ Icon, name, id, to, isTour }) => (
          <Box key={id} className={isTour ? 'step1' : 'iconTranslate'}>
            <Tooltip
              label={name}
              placement="right"
              bgColor="brand.primary.main"
              className="iconTranslate"
            >
              <Link to={to}>
                <Icon
                  className={to === match.url ? 'iconActive' : 'icon'}
                  // eslint-disable-next-line no-constant-condition
                  // color={to === match.url ? '#FF824C' : '#737373'}
                  fontSize="25px"
                />
              </Link>
            </Tooltip>
          </Box>
        ))}
      </VStack>
    </>
  );
}
