import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  VStack,
  HStack,
  Avatar,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Badge,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RiArrowDownSLine } from 'react-icons/ri';
import { CgDollar, CgProfile } from 'react-icons/cg';
import { VscTasklist } from 'react-icons/vsc';
import { IoMdPower } from 'react-icons/io';
import { ICurrentUser } from '../../../interfaces';
import AddBalance from './AddBalance';
import { RootState } from '../../../slices';
import { setTourStarted, setTourPending } from '../../../slices/app';

interface HeaderProps {
  balance: number;
  currentUser: ICurrentUser | null;
  logout: () => void;
}

export default function Header(props: HeaderProps) {
  const { currentUser, logout } = props;
  const dispatch = useDispatch();

  const { tourPending } = useSelector((state: RootState) => state.app);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('tourStarted')!) === false) {
      dispatch(setTourPending(true));
    }
  }, [JSON.parse(localStorage.getItem('tourStarted')!)]);

  const history = useHistory();

  const handleTourStarted = () => {
    localStorage.setItem('tourStarted', JSON.stringify(true));
    dispatch(setTourStarted(true));
    dispatch(setTourPending(false));
  };

  return (
    <HStack w="100%" p="15px">
      <Box ml="auto">
        <HStack spacing={6}>
          <HStack>
            {tourPending && (
              <Badge
                mr="1"
                onClick={handleTourStarted}
                rounded="lg"
                cursor="pointer"
                p="2"
                variant="subtle"
                color="white"
                bgColor="brand.primary.main"
                className="ping"
              >
                Start Tour
              </Badge>
            )}

            <Avatar
              icon={<CgDollar fontSize="30px" color="#fff" />}
              bgColor="brand.primary.main"
            />
            <VStack spacing={0} alignItems="start">
              <Text fontWeight="light" fontSize="12px" color="gray.500">
                Balance
              </Text>
              <HStack spacing={1}>
                <Text
                  fontWeight="600"
                  fontStyle="normal"
                  color="brand.primary.main"
                >
                  ${currentUser && currentUser?.balance.toFixed(2)}
                </Text>

                <AddBalance />
              </HStack>
            </VStack>
          </HStack>
          <HStack>
            <Avatar
              name="Dan Abrahmov"
              src={`https://avatars.dicebear.com/api/bottts/:${
                currentUser && currentUser?.name.split(' ')[0]
              }.svg`}
              bg="white"
            />
            <VStack spacing={0} alignItems="start">
              <Text fontWeight="light" fontSize="12px" color="gray.500">
                User
              </Text>

              <Menu id="dropdown-menu">
                <MenuButton
                  id="dropdown-btn"
                  // onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <HStack spacing={1}>
                    <HStack spacing={0} alignItems="flex-end">
                      <Text fontWeight="600" fontStyle="normal">
                        {currentUser && currentUser?.name.split(' ')[0]}
                      </Text>
                    </HStack>
                    <RiArrowDownSLine fontSize="18px" />
                  </HStack>
                </MenuButton>
                <MenuList mt="10px" minWidth="150px">
                  <MenuItem
                    onClick={() => {
                      history.push('/profile');
                    }}
                    color="#737373"
                    icon={<CgProfile fontSize="18px" />}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push('/orders');
                    }}
                    color="#737373"
                    icon={<VscTasklist fontSize="18px" />}
                  >
                    Orders
                  </MenuItem>

                  <MenuDivider />
                  <MenuItem
                    onClick={logout}
                    icon={<IoMdPower fontSize="18px" />}
                    color="#737373"
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            </VStack>
          </HStack>
        </HStack>
      </Box>
    </HStack>
  );
}
