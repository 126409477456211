import React, { useMemo } from 'react';
import { Text, Spinner, Flex, VStack } from '@chakra-ui/react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { MdInsertDriveFile } from 'react-icons/md';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  height: '100%',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#51D55E',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

interface DropzoneProps {
  onDrop: (
    acceptedFiles: File[],
    fileRejections?: FileRejection[],
    event?: DropEvent
  ) => void;
  fileUploadLoading: boolean;
}

export default function Dropzone(props: DropzoneProps) {
  const { onDrop, fileUploadLoading } = props;
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      '',
      '.csv',
      'text/csv',
    ],
    onDrop,
    disabled: fileUploadLoading,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="step3">
      {/*  @ts-ignore */}
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Text textAlign="center">
          {fileUploadLoading ? (
            <Flex justify="center" alignItems="center">
              <Spinner color="brand.primary.main" thickness="2px" size="md" />
            </Flex>
          ) : (
            <VStack color="brand.primary.main">
              <MdInsertDriveFile size={72} />
              <Text
                casing="uppercase"
                fontSize="sm"
                rounded="lg"
                px="3"
                py="1.5"
                bg="brand.primary.main"
                color="white"
              >
                Drop or click to select file
              </Text>
            </VStack>
          )}
        </Text>
      </div>
    </div>
  );
}
