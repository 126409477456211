import { useMutation } from 'react-query';
import { API } from '../constants';
import { apiClient } from '../services';

export const useOrders = () => {
  const getOrders = async (body: {
    type?: string;
    status?: string;
    orderStartDate?: string;
    orderEndDate?: string;
  }) => {
    try {
      const { orderStartDate, orderEndDate, status, type } = body;
      const { data } = await apiClient.get(
        `${API.ROUTES.SKIPTRACE_ORDERS}/?type=${type}&status=${status}&orderStartDate=${orderStartDate}&orderEndDate=${orderEndDate}`
      );
      return data;
    } catch (error) {
      return error;
    }
  };

  const { error, data, isLoading, mutate } = useMutation(getOrders);
  return {
    ordersData: data,
    ordersError: error,
    ordersLoading: isLoading,
    ordersMutate: mutate,
  };
};
