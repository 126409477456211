import React from 'react';
import moment, { Moment } from 'moment';
import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Divider,
  Button,
  HStack,
  Select,
  Spinner,
} from '@chakra-ui/react';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { ISkiptraceStats } from '../../interfaces';
import { CDatePicker, Badge, Empty, SkiptraceStats } from '..';

const tableHeadings = [
  'Date',
  'List Name',
  'Records Uploaded',
  'Total Hits',
  'Hit %',
  'Total Cost',
  'Status',
  'Input',
  'Output',
  'Invoice',
];

export interface COrdersProps {
  ordersStartDatePicker: (start: Moment | null) => void;
  ordersEndDatePicker: (end: Moment | null) => void;
  startDate: Moment | null;
  endDate: Moment | null;
  type: string;
  status: string;
  setOrdersStatus: (value: string) => void;
  setOrdersType: (value: string) => void;
  filterHandler: () => void;
  ordersLoading: boolean;
  clearFilterHandler: () => void;
  ordersData: {
    stats: ISkiptraceStats;
    orders:
      | [
          {
            type: string;
            status: string | undefined;
            records: number | null;
            skiptracedRecords: number | null;
            cost: number | null;
            date: string;
            listName: string;
            input: string;
            output: string;
            invoiceUrl: string | null;
          }
        ]
      | [];
  };
}

const COrders = ({
  type,
  status,
  endDate,
  startDate,
  ordersStartDatePicker,
  ordersEndDatePicker,
  setOrdersType,
  setOrdersStatus,
  filterHandler,
  ordersData,
  ordersLoading,
  clearFilterHandler,
}: COrdersProps) => {
  const statuses = {
    needsReview: 'pending',
    processing: 'pending',
    completed: 'success',
    pending: 'pending',
    success: 'success',
  };

  return (
    <Box w="full">
      <Box>
        <Text
          fontWeight="600"
          fontStyle="normal"
          fontSize="40px"
          color="#393330"
        >
          Orders
        </Text>
        <Divider h="20px" />
      </Box>
      <HStack w="full" mt="30px" justifyContent="flex-end">
        <CDatePicker
          small={false}
          startDatePicker={ordersStartDatePicker}
          endDatePicker={ordersEndDatePicker}
          startDate={startDate}
          endDate={endDate}
        />
        <Select
          value={status}
          onChange={(e) => setOrdersStatus(e.target.value)}
          color="#737373"
          placeholder="Select Status"
          maxW="56"
          size="lg"
          focusBorderColor="brand.primary.light"
        >
          <option value="pending">Pending</option>
          <option value="success">Completed</option>
        </Select>
        <Select
          value={type}
          onChange={(e) => setOrdersType(e.target.value)}
          color="#737373"
          placeholder="Select Type"
          maxW="56"
          size="lg"
          focusBorderColor="brand.primary.light"
        >
          <option value="single trace">Single Trace</option>
          <option value="bulk trace">Bulk Trace</option>
        </Select>

        <Button
          id="clearButton"
          onClick={clearFilterHandler}
          p="25px"
          fontSize="18px"
          bgColor="brand.primary.main"
          colorScheme="orange"
        >
          <Text fontWeight="light">Clear</Text>
        </Button>
        <Button
          id="filterButton"
          onClick={filterHandler}
          p="25px"
          fontSize="18px"
          bgColor="brand.primary.main"
          colorScheme="orange"
        >
          <Text fontWeight="light">Filter</Text>
        </Button>
      </HStack>
      <SkiptraceStats stats={ordersData?.stats} />

      <Box overflow="auto" mb="30px">
        <Table mt="30px" variant="simple" size="sm">
          <Thead boxShadow="sm">
            <Tr>
              {tableHeadings.map((heading, i) => (
                <Th
                  fontSize="15px"
                  color="#2F3442"
                  fontWeight="600"
                  key={i}
                  py="20px"
                  textAlign="center"
                >
                  {heading}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody color="#737373" id="tBody">
            {ordersData &&
              ordersData?.orders?.map((data: any, i: number) => (
                <Tr key={i}>
                  <Td textAlign="center">
                    {moment(data.date).format('DD-MM-YYYY')}
                  </Td>
                  <Td textAlign="center" py="20px">
                    {data.listName}
                  </Td>
                  <Td textAlign="center">{data.records || 0}</Td>
                  <Td textAlign="center">
                    {data.skiptracedRecords && data.status !== 'needsReview'
                      ? data.skiptracedRecords
                      : null}
                  </Td>
                  <Td textAlign="center">
                    {data.status !== 'needsReview' ? (
                      <>
                        {data?.skiptracedRecords && data.records
                          ? (
                              (data?.skiptracedRecords / data.records) *
                              100
                            )?.toFixed(2)
                          : 0}
                        %
                      </>
                    ) : null}
                  </Td>
                  <Td textAlign="center">${data.cost?.toFixed(2) || 0}</Td>
                  <Td textAlign="center" maxW="12px">
                    {/* @ts-ignore */}
                    <Badge variant={statuses[data.status]}>
                      {/* @ts-ignore */}
                      {statuses[data.status]}
                    </Badge>
                  </Td>
                  <Td
                    color="#737373"
                    fontWeight="400"
                    maxWidth="40vw"
                    py="20px"
                    textAlign="center"
                  >
                    {data.input && (
                      <Button
                        as="a"
                        href={data.input}
                        target="_blank"
                        rel="noreferrer"
                        id="viewFile"
                        size="sm"
                        fontSize="12px"
                        bgColor="brand.primary.main"
                        colorScheme="orange"
                      >
                        View File
                      </Button>
                    )}
                  </Td>

                  <Td
                    textAlign="center"
                    color="#737373"
                    fontWeight="400"
                    maxWidth="10vw"
                    py="20px"
                  >
                    {data.output && data.status !== 'needsReview' && (
                      <Button
                        href={data.output}
                        target="_blank"
                        rel="noreferrer"
                        as="a"
                        id="downloadLink"
                        size="sm"
                        bgColor="brand.primary.main"
                        colorScheme="orange"
                      >
                        Download
                      </Button>
                    )}
                  </Td>
                  <Td
                    color="#737373"
                    fontWeight="400"
                    maxWidth="40vw"
                    py="20px"
                    textAlign="center"
                  >
                    {data.invoiceUrl && (
                      <Button
                        as="a"
                        href={data.invoiceUrl}
                        target="_blank"
                        rel="noreferrer"
                        id="viewFile"
                        size="sm"
                        fontSize="12px"
                        bgColor="brand.primary.main"
                        colorScheme="orange"
                        leftIcon={<FaFileInvoiceDollar size={20} />}
                      >
                        Download
                      </Button>
                    )}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
        {ordersLoading && (
          <Box display="flex" py="24" w="full" justifyContent="center">
            <Spinner
              id="loading"
              color="brand.primary.main"
              thickness="3px"
              size="lg"
            />
          </Box>
        )}
        {ordersData && ordersData?.orders.length === 0 && (
          <Box
            id="emptyComponent"
            display="flex"
            py="12"
            w="full"
            justifyContent="center"
          >
            <Empty message="No Orders" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default COrders;
