import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface BadgeProps {
  children: string;
  variant: string;
}

export default function Badge(props: BadgeProps) {
  const { children, variant } = props;

  const variantProps = () => {
    switch (variant) {
      case 'pending':
        return {
          bgColor: '#FFE4D9',
          color: 'brand.primary.main',
        };
      case 'success':
        return {
          bgColor: '#EFFFE3',
          color: '#36DE1B',
        };
      case 'disputed':
        return {
          bgColor: 'pink.100',
          color: 'pink.400',
        };
      default: {
        return null;
      }
    }
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      fontWeight="600"
      {...variantProps()}
      borderRadius="5px"
      py="5px"
      px="0px"
    >
      <Text>{children.charAt(0).toUpperCase() + children.substring(1)}</Text>
    </Flex>
  );
}
