export const ERROR_MESSAGE = {
  WRONG_PASSWORD: 'Your password is incorrect.',
};

export default {
  WRONG_PASSWORD: 'auth/wrong-password',
  USER_NOT_FOUND: 'auth/user-not-found',
  INVALID_EMAIL: 'auth/invalid-email',
  INVALID_EMAIL_OR_PASSWORD: 'auth/invalid-email-or-password',
  EMAIL_ALREADY_EXSIST: 'auth/email-already-exist',
  UNAUTHORIZED: 'Unauthorized',
  VARIFY_EMAIL_ADDRESS: 'verify-email-address',
  INVALID_REFERRAL_CODE: 'auth/invalid-referral-code',
  ACCOUNT_FROZEN: 'ACCOUNT_FROZEN',
};
