import React from 'react';
import { Wrap, WrapItem } from '@chakra-ui/react';
import { ISkiptraceStats } from '../../../interfaces';
import { Stats } from '../..';

const SkiptraceStats = ({ stats }: { stats: ISkiptraceStats | undefined }) => {
  const bulkFileStats = [
    {
      id: '1',
      title: 'Total Orders',
      value: stats?.totalOrders,
      color: 'brand.primary.main',
    },
    {
      id: '2',
      title: 'Completed Orders',
      value: stats?.completedOrders,
      color: '#51D55E',
    },
    {
      id: '3',
      title: 'Pending Orders',
      value: stats?.pendingOrders,
      color: '#F85959',
    },
    {
      id: '4',
      title: 'Hit Rate',
      value: stats?.percentageHit ? `${stats?.percentageHit}%` : '0%',
      color: '#8147DA',
    },
    {
      id: '5',
      title: 'Total Orders Cost',
      value: stats?.totalOrdersCost ? `$${stats?.totalOrdersCost}` : '$0',
      color: '#51D55E',
    },
  ];
  return (
    <Wrap justify="center" spacing={5} mt="30px">
      {bulkFileStats.map((stat) => (
        <WrapItem w="18%" key={stat.id}>
          <Stats
            id={stat?.id}
            title={stat?.title}
            value={stat?.value}
            color={stat?.color}
          />
        </WrapItem>
      ))}
    </Wrap>
  );
};

export default SkiptraceStats;
