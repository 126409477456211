import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Button,
  Heading,
  HStack,
} from '@chakra-ui/react';

interface ITourModal {
  isOpen: boolean;
  onClose: () => void;
  onStart: () => void;
}

export default function TourModal(props: ITourModal) {
  const { isOpen, onClose, onStart } = props;
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent zIndex={100}>
        <ModalBody>
          <Heading
            textAlign="center"
            py="3"
            fontSize="2xl"
            color="brand.primary.main"
          >
            Tutorial
          </Heading>
          <Text textAlign="center" fontWeight="medium" fontSize="lg">
            Would you like a tutorial of how our bulk-skip tracing works?
          </Text>
        </ModalBody>

        <ModalFooter display="flex" justifyContent="center">
          <HStack>
            <Button
              variant="outline"
              colorScheme="orange"
              color="brand.primary.main"
              onClick={onClose}
            >
              Skip
            </Button>
            <Button
              onClick={onStart}
              colorScheme="orange"
              bg="brand.primary.main"
            >
              Start
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
