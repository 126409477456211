/* eslint-disable no-restricted-globals */
import { createSlice } from '@reduxjs/toolkit';
import { BillingState } from '../../interfaces';

const billingSlice = createSlice({
  name: 'billing',
  initialState: {
    isPaying: false,
    isPaymentSuccess: false,
    balance: 0,
    success: false,
    error: '',
    loading: false,
    methodsLoading: false,
    detachLoading: false,
    clientSecret: '',
    successUrl: '',
    paymentMethods: [],
    invoices: [],
  } as BillingState,
  reducers: {
    setIsPaying: (state, action) => {
      state.isPaying = action.payload;
    },
    setIsPaymentSuccess: (state, action) => {
      state.isPaymentSuccess = action.payload;
    },

    resetState: (state) => {
      state.error = '';
      state.loading = false;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    resetSuccess: (state) => {
      state.success = false;
    },
    setBalance: (state, action) => {
      state.balance = action.payload.balance;
    },
  },
});

export const {
  setIsPaying,
  setIsPaymentSuccess,
  setLoading,
  setSuccess,
  resetState,
  resetSuccess,
  setBalance,
} = billingSlice.actions;

export default billingSlice.reducer;
