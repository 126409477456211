/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { VStack, Text, Skeleton, Stack } from '@chakra-ui/react';
import CTable from './Table';
import type { IPerformanceData } from '../../../interfaces';

interface ICPerformanceDetails {
  performanceDetails: IPerformanceData;
  isLoading: boolean;
}

const CPerformanceDetails = (props: ICPerformanceDetails) => {
  const { performanceDetails, isLoading } = props;
  return (
    <VStack w="full" rounded="md" spacing="8" shadow="md" px="8" py="6">
      <Text fontSize="2xl" color="gray.600">
        Performance details
      </Text>

      {isLoading ? (
        <Stack w="90%" mx="auto">
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      ) : (
        <CTable performanceDetails={performanceDetails} />
      )}

      <Text fontSize="sm" color="gray.600">
        Note: Only the approved monetary commissions are showed in the
        "Earnings" column.
      </Text>
    </VStack>
  );
};

export default CPerformanceDetails;
