import React, { useState } from 'react';
import { VStack, Text, Box } from '@chakra-ui/react';

const Faq = () => {
  const [show, setShow] = useState(false);

  return (
    <VStack
      w="full"
      rounded="md"
      shadow="md"
      px="8"
      py="6"
      spacing="6"
      alignItems="flex-start"
      h={show ? 'full' : '64'}
    >
      <Text
        w="full"
        textAlign="center"
        color="gray.500"
        fontSize="2xl"
        fontWeight="bold"
      >
        Frequently Asked Questions
      </Text>
      <Box>
        <Text color="gray.500" fontSize="xl" fontWeight="bold">
          Can I sign up using my own referral link?
        </Text>
        <Text color="gray.400" fontSize="md">
          No. Unfortunately, self-referrals are strictly prohibited. The aim of
          a referral program is to encourage affiliates and ambassadors to
          spread the word about our product(s), not to get discounts.
        </Text>
      </Box>

      {show && (
        <>
          <Box>
            <Text color="gray.500" fontSize="xl" fontWeight="bold">
              My friend/client signed up without using the referral link. What
              can I do?
            </Text>
            <Text color="gray.400" fontSize="md">
              Send us his/her email to{' '}
              <Text as="span" color="brand.primary.main">
                skipspotllc@gmail.com
              </Text>{' '}
              and we’ll manually add them to the system.
            </Text>
          </Box>

          <Box>
            <Text color="gray.500" fontSize="xl" fontWeight="bold">
              Why can’t I see my sign-ups and/or rewards?
            </Text>
            <Text color="gray.400" fontSize="md">
              Our tracking is based on browser cookies, so if you used a
              different browser/device from the visit to sign up, you cleared
              your cookies, you used incognito, or you signed up from your own
              referral link, the signup might not be tracked. If you are 100%
              sure the sign up or sale were not tracked correctly, contact our
              referral platform provider at skipspotllc@gmail.com.
            </Text>
          </Box>

          <Box>
            <Text color="gray.500" fontSize="xl" fontWeight="bold">
              When will I get paid?
            </Text>
            <Text color="gray.400" fontSize="md">
              All payouts are processed on a monthly basis. We’ll email you when
              the payout is completed.
            </Text>
          </Box>

          <Box>
            <Text color="gray.500" fontSize="xl" fontWeight="bold">
              How can I change my referral link code?
            </Text>
            <Text color="gray.400" fontSize="md">
              Please contact us at{' '}
              <Text as="span" color="brand.primary.main">
                skipspotllc@gmail.com
              </Text>{' '}
              to change your referral link code
            </Text>
          </Box>
        </>
      )}
      <Text
        cursor="pointer"
        color="brand.primary.main"
        textDecor="underline"
        w="full"
        textAlign="center"
        onClick={() => setShow(!show)}
      >
        {show ? 'Show Less' : 'Show More'}
      </Text>
    </VStack>
  );
};
export default Faq;
