import React, { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  ButtonGroup,
  Button,
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverTrigger,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import { RiAddBoxFill } from 'react-icons/ri';
import PaddleCheckout from '../../../PaddleCheckout';

const AddBalance = () => {
  const [balance, setBalance] = useState<number>(0);
  const firstFieldRef = React.useRef(null);
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Popover
      isOpen={isOpen}
      initialFocusRef={firstFieldRef}
      onClose={onClose}
      onOpen={onOpen}
      placement="bottom-start"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Box color="brand.primary.main" cursor="pointer">
          <RiAddBoxFill size={18} />
        </Box>
      </PopoverTrigger>
      <PopoverContent p={5}>
        <PopoverArrow />
        <PopoverCloseButton />
        <Stack spacing={4}>
          <FormControl>
            <FormLabel>Add Balance</FormLabel>
            <Input
              type="number"
              onChange={(e) => setBalance(Number(e.target.value))}
            />
          </FormControl>
          <ButtonGroup d="flex" justifyContent="flex-end">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Box maxW="32">
              <PaddleCheckout
                onModalOpen={onOpen}
                onModalClose={onClose}
                cost={balance}
                btnText="Add"
                isBalancePayment
                emptyUserBalance={false}
              />
            </Box>
          </ButtonGroup>
        </Stack>
      </PopoverContent>
    </Popover>
  );
};

export default AddBalance;
