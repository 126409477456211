import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Text, Image, HStack, Flex } from '@chakra-ui/react';
import './index.css';

interface CardProps {
  icon: string;
  title: string;
  value: number | string | undefined;
}

const toUrl: { [x: string]: string } = {
  'Total Skiptraced Leads': 'completed',
  'Total Orders': 'all',
  'Total Spent': 'all',
  'Pending Files': 'pending',
};

function Card(props: CardProps) {
  const { icon, title, value } = props;
  return (
    <Flex
      className="animate"
      p="30px"
      border="1px solid #DFDFDF"
      maxWidth="300px"
      h="200px"
      as={Link}
      to={`/orders?status=${toUrl[title]}`}
      borderRadius="50px"
      direction="column"
      justifyContent="space-evenly"
    >
      <Box>
        <Image width="50px" id="card-icon" src={icon} />
      </Box>
      <Box>
        <Text
          id="card-title"
          color="#393330"
          py="2"
          fontSize="18px"
          fontWeight="600"
        >
          {title}
        </Text>
      </Box>
      <HStack w="100%" justifyContent="space-between">
        <Text id="card-value" color="#393330" fontSize="36px" fontWeight="600">
          {value}
        </Text>
      </HStack>
    </Flex>
  );
}

export default Card;
