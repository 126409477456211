import React, { useEffect } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { Confirmed } from '../../assets/svg';
import { REACT_APP_URL } from '../../constants';

const PaymentRedirect = () => {
  useEffect(() => {
    window?.top?.postMessage('payment-success', REACT_APP_URL);
  }, []);

  return (
    <VStack bgColor="white" mt="10" spacing="20px" w="full">
      <Text
        as="h1"
        fontWeight="bold"
        textAlign="center"
        color="gray.800"
        fontSize="3xl"
      >
        Payment Successful!
      </Text>
      <Text color="gray.600" pb="6" textAlign="center">
        Your payment was successful. You are redirecting to dashboard!
      </Text>
      <img src={Confirmed} alt="Payment Confirmed" width={250} height={250} />
    </VStack>
  );
};

export default PaymentRedirect;
