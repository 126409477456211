import React from 'react';
import { Button, useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { HiCreditCard } from 'react-icons/hi';
import { RootState } from '../../slices';
import { setCurrentUser } from '../../slices/app';
import { setIsPaymentSuccess, setIsPaying } from '../../slices/billing';
import { useGeneratePayLink, useGetCurrentUser } from '../../hooks';

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

interface PaddleCheckoutProps {
  cost: number;
  isBalancePayment: boolean;
  emptyUserBalance?: boolean;
  btnText?: string;
  recordId?: string;
  remainingCost?: number;
  onFieldMapping?: () => void;
  onModalClose?: () => void;
  onModalOpen?: () => void;
}

function PaddleCheckout({
  cost,
  onModalClose,
  onModalOpen,
  btnText = 'Pay With Card',
  recordId,
  remainingCost,
  isBalancePayment,
  emptyUserBalance,
  onFieldMapping,
}: PaddleCheckoutProps) {
  const toast = useToast();
  const dispatch = useDispatch();
  const {
    billing: { isPaymentSuccess },
    app: { currentUser },
  } = useSelector((state: RootState) => state);

  const { data: userData, refetch } = useGetCurrentUser();

  React.useEffect(() => {
    dispatch(setCurrentUser(userData));
  }, [userData]);

  const { mutate, isLoading } = useGeneratePayLink();

  const { Paddle }: any = window;

  const handlePayNow = () => {
    mutate(
      {
        cost,
      },
      {
        onSuccess: (res) => {
          if (res.data.success) {
            Paddle.Checkout.open({
              email: currentUser?.email,
              title: '',
              message: '',
              override: res.data.response.url,
              allowQuantity: false,

              passthrough: JSON.stringify({
                // eslint-disable-next-line no-underscore-dangle
                uid: currentUser?._id,
                isBalancePayment,
                emptyUserBalance,
                recordId,
                remainingCost,
                cost,
              }),
              successCallback: async (data: any, err: any) => {
                if (data) {
                  if (onFieldMapping) {
                    onFieldMapping();
                  }
                  if (onModalClose) {
                    onModalClose();
                  }
                  dispatch(setIsPaymentSuccess(true));
                  toast({
                    title: 'Payment Success!',
                    description: 'You have made payment successfully.',
                    status: 'success',
                    position: 'top-right',
                    duration: 5000,
                    isClosable: true,
                  });
                  dispatch(setIsPaymentSuccess(false));
                  dispatch(setIsPaying(false));
                  await delay(1500);
                  refetch();
                }
                if (err) {
                  toast({
                    title: err,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                  });
                }
              },
              loadCallback: () => {
                dispatch(setIsPaymentSuccess(false));
                if (!isPaymentSuccess && onModalClose) {
                  onModalClose();
                  dispatch(setIsPaying(true));
                }
              },
              closeCallback: () => {
                dispatch(setIsPaymentSuccess(false));
                if (!isPaymentSuccess && onModalOpen) {
                  onModalOpen();
                  dispatch(setIsPaying(false));
                }
              },
            });
          }
        },
        onError: (error: any) => {
          toast({
            title: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        },
      }
    );
  };

  return (
    <Button
      isLoading={isLoading}
      leftIcon={<HiCreditCard size={22} />}
      w="full"
      onClick={handlePayNow}
      id="makePayment"
      fontSize="18px"
      colorScheme="orange"
      bgColor="brand.primary.main"
    >
      {btnText}
    </Button>
  );
}

export default PaddleCheckout;
