import React from 'react';
import {
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { ISingleSkipTraceForm } from '../../../../interfaces';
import LocationSearchInput from '../../../shared/LocationSearchInput';

export interface ModalProps {
  onSingleTraceStepChange: (tab: number) => void;
  propertyAddress: { address: string };
  onSetFormData: (data: ISingleSkipTraceForm) => void;
  setPropertyAddress: React.Dispatch<
    React.SetStateAction<{
      address: string;
    }>
  >;
}

export default function Form(props: ModalProps) {
  const {
    onSetFormData,
    onSingleTraceStepChange,
    propertyAddress,
    setPropertyAddress,
  } = props;

  const { register, handleSubmit } = useForm<ISingleSkipTraceForm>();
  const toast = useToast();

  const submit = (data: ISingleSkipTraceForm) => {
    if (propertyAddress.address.length === 0) {
      toast({
        title: 'Error',
        description: 'Address is required',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    } else {
      onSetFormData(data);
      onSingleTraceStepChange(1);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        <VStack spacing={8} mt={12} align="stretch">
          <HStack spacing={10}>
            <FormControl id="firstName">
              <FormLabel>First name</FormLabel>
              <Input placeholder="First name" {...register('firstName')} />
            </FormControl>

            <FormControl id="lastName">
              <FormLabel>Last name</FormLabel>
              <Input placeholder="Last name" {...register('lastName')} />
            </FormControl>
            <FormControl id="businessName">
              <FormLabel>Business name (Optional) </FormLabel>
              <Input
                placeholder="Business name"
                {...register('businessName')}
              />
            </FormControl>
          </HStack>

          <HStack spacing={10} position="static" align="starts">
            <FormControl id="propertyAddress">
              <FormLabel>Property Address</FormLabel>
              <LocationSearchInput
                label="address"
                name="address"
                placeholderName="Search Property Address"
                value={propertyAddress.address}
                handleChange={(e: any) => {
                  setPropertyAddress({
                    ...propertyAddress,
                    address: e.target.value,
                  });
                }}
              />
            </FormControl>
          </HStack>

          <Box>
            <Button
              id="btn"
              background="brand.primary.main"
              colorScheme="orange"
              width={{ lg: '300px', sm: '200px' }}
              alignItems="center"
              mt={10}
              ml="35%"
              mb="3%"
              type="submit"
            >
              Skip Trace
            </Button>
          </Box>
        </VStack>
      </form>
    </>
  );
}
