import React from 'react';
import { VStack, Flex, HStack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

interface AffiliatesLayoutProps {
  children: React.ReactNode;
}

const AffiliatesLayout = ({ children }: AffiliatesLayoutProps) => (
  <VStack w="full" spacing="14">
    <Flex justifyContent="space-between" w="full">
      <Text fontSize="3xl">Affiliates</Text>
      <HStack spacing="6" color="brand.primary.main">
        <Link to="/affiliates/dashboard">Dashboard</Link>
        <Link to="/affiliates/performance">Performance</Link>
        <Link to="/affiliates/referrals">Referrals</Link>
        <Link to="/affiliates/payouts">Payouts</Link>
        <Link to="/profile">Profile</Link>
      </HStack>
    </Flex>
    {children}
  </VStack>
);

export default AffiliatesLayout;
