import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  Text,
  Flex,
  Spinner,
  HStack,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { Moment } from 'moment';
import {
  SingleSkipTraceFilesIcon,
  PendingFilesIcon,
  UploadedFilesIcon,
} from '../../assets/svg';
import { Card, CDatePicker, PageTitle } from '..';
import { ICurrentUser, IUserMetrics } from '../../interfaces';
import BarChart from './BarChart';
import FreeSignUpModal from './FreeSignUpModal';
import TourModal from './TourModal';
import { setTourStarted, setTourPending, setStep1Run } from '../../slices/app';

interface DashboardProps {
  isLoading: boolean | undefined;
  currentUser: ICurrentUser | null;
  userMetrics: IUserMetrics | undefined;
  startDatePicker: (start: Moment | null) => void;
  endDatePicker: (end: Moment | null) => void;
  startDate: Moment | null;
  endDate: Moment | null;
  dateFilterHandler: () => void;
  isOpen: boolean;
  onClose: () => void;
  freeRecords: number;
  isSignup: boolean;
  tourStarted: boolean;
}

export default function Dashboard(props: DashboardProps) {
  const dispatch = useDispatch();
  const {
    userMetrics,
    isLoading,
    endDate,
    startDate,
    startDatePicker,
    endDatePicker,
    dateFilterHandler,
    isOpen,
    onClose,
    freeRecords,
    isSignup,
  } = props;

  const {
    isOpen: isTourOpen,
    onClose: onTourClose,
    onOpen: onTourOpen,
  } = useDisclosure();

  useEffect(() => {
    if (isSignup) {
      onTourOpen();
    }
  }, [isSignup]);

  const cards = [
    {
      id: 1,
      icon: UploadedFilesIcon,
      title: 'Total Skiptraced Leads',
      value: userMetrics?.totalSkiptraced ? userMetrics?.totalSkiptraced : 0,
    },
    {
      id: 2,
      icon: SingleSkipTraceFilesIcon,
      title: 'Total Orders',
      value: userMetrics?.totalOrders ? userMetrics?.totalOrders : 0,
    },
    {
      id: 3,
      icon: PendingFilesIcon,
      title: 'Total Spent',
      value: userMetrics?.totalSpent
        ? `$${userMetrics?.totalSpent.toFixed(2)}`
        : `$${0}`,
    },
    {
      id: 4,
      icon: SingleSkipTraceFilesIcon,
      title: 'Pending Files',
      value: userMetrics?.pendingSkiptraces
        ? userMetrics?.pendingSkiptraces
        : 0,
    },
  ];

  const handleOnCloseTour = () => {
    dispatch(setTourStarted(false));
    dispatch(setTourPending(true));
    onTourClose();
    localStorage.setItem('tourStarted', JSON.stringify(false));
  };

  const onStart = () => {
    localStorage.setItem('tourStarted', JSON.stringify(true));
    dispatch(setStep1Run(true));
    onTourClose();
  };

  return (
    <>
      <TourModal
        isOpen={isTourOpen}
        onClose={handleOnCloseTour}
        onStart={onStart}
      />
      <FreeSignUpModal
        isOpen={isOpen}
        onClose={onClose}
        freeRecords={freeRecords}
      />
      <PageTitle title="Dashboard" />
      <Box w="100%" h="100vh">
        <Flex justify="space-between" alignItems="center">
          <Text
            fontWeight="600"
            fontStyle="normal"
            fontSize="40px"
            color="#393330"
          >
            Dashboard
          </Text>
          <HStack>
            <CDatePicker
              small
              startDatePicker={startDatePicker}
              startDate={startDate}
              endDate={endDate}
              endDatePicker={endDatePicker}
            />
            <Button
              size="md"
              w="20"
              id="singleSkipTrace"
              onClick={() => dateFilterHandler()}
              fontSize="md"
              colorScheme="orange"
              bgColor="brand.primary.main"
            >
              <Text fontWeight="light">Filter</Text>
            </Button>
          </HStack>
        </Flex>
        {isLoading ? (
          <Flex justifyContent="center" h="300px" alignItems="center" w="100%">
            <Spinner color="brand.primary.main" thickness="3px" size="lg" />
          </Flex>
        ) : (
          <>
            <Grid
              mt="30px"
              gap={6}
              cursor="pointer"
              templateColumns="repeat(4, 1fr)"
              id="cards"
            >
              {cards.map((card) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Card key={card.id} {...card} />
              ))}
            </Grid>
            {userMetrics?.barChartData && (
              <>
                <Text
                  pt="10"
                  fontStyle="normal"
                  fontWeight="semibold"
                  fontSize="24px"
                  color="#393330"
                >
                  Total SkipTraced Records
                </Text>
                <Box pt="8">
                  <BarChart data={userMetrics?.barChartData} />
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
}
