import React from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { API } from '../../../constants';

interface GuardedRouteProps {
  children: JSX.Element | React.ComponentType;
  redirectTo: string;
}

export default function Guarded(props: GuardedRouteProps) {
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>();
  const { children, redirectTo } = props;

  React.useEffect(() => {
    axios
      .get(`${API.API_URL}${API.ROUTES.GET_ME}`, {
        headers: {
          'x-auth': localStorage.getItem('authToken'),
        },
      })
      .then(() => {
        setIsLoggedIn(true);
      })
      .catch(() => {
        setIsLoggedIn(false);
      });
  }, []);

  // eslint-disable-next-line no-nested-ternary
  return isLoggedIn !== undefined && isLoggedIn === true ? (
    <div id="GChildren">{children}</div>
  ) : isLoggedIn !== undefined && isLoggedIn === false ? (
    <div id="redirectComp">
      <Redirect to={redirectTo} />
    </div>
  ) : null;
}
