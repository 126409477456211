import React, { useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Text,
  chakra,
  SimpleGrid,
  Flex,
  Checkbox,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { DeepMap, FieldError, useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import AuthContainer from '../Container';
import { ISignupFormState } from '../../../interfaces/auth.interface';

const PhoneInputNumber = chakra(PhoneInput);

export interface FormType {
  fName: string;
  lName: string;
  email: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  businessName: string;
  referredBy: string | undefined | null;
  type: string | undefined | null;
  terms: boolean | undefined | null;
}

export interface SignUpProps {
  referralCode: string | null;
  onSignUpSubmit: (
    name: string,
    email: string,
    phone: string,
    password: string,
    confirmPassword: string,
    businessName: string,
    referredBy: string | undefined | null,
    type: string | undefined | null
  ) => void;
  errors?: DeepMap<FormType, FieldError>;
  isLoading?: boolean;
  signupFormState: ISignupFormState;
  isChangeEmail: string | null;
}

function SignUp(props: SignUpProps) {
  const {
    referralCode,
    isLoading,
    onSignUpSubmit,
    errors,
    signupFormState,
    isChangeEmail,
  } = props;

  const { formState, handleSubmit, register, setError, control } =
    useForm<FormType>({
      defaultValues: {
        referredBy: referralCode,
      },
    });

  useEffect(() => {
    if (errors) {
      Object.entries(errors).forEach(([name, err]) => {
        if (err) setError(name as keyof FormType, err);
      });
    }
  }, [errors, setError]);
  const {
    fName: fNameErrors,
    lName: lNameErrors,
    email: emailErrors,
    phoneNumber: phoneNumberErrors,
    password: passwordErrors,
    confirmPassword: confirmPasswordErrors,
    businessName: businessNameErrors,
    referredBy: referredByErrors,
    terms,
  } = formState.errors;

  const submit = handleSubmit(
    (data) =>
      onSignUpSubmit &&
      onSignUpSubmit(
        `${data.fName} ${data.lName}`,
        data.email,
        data.phoneNumber,
        data.password,
        data.confirmPassword,
        data.businessName,
        data.referredBy,
        'signup'
      )
  );
  return (
    <AuthContainer
      buttonText="Back to Login"
      to="/login"
      description="Sign up to see why we are the best in the industry."
    >
      <Box w="80%">
        <Box textAlign="center">
          <Text fontSize="4xl" fontWeight="bold" color="brand.primary.main">
            Sign Up
          </Text>
          <Text color="gray.600" fontWeight="medium" mb="30px">
            Sign Up with your details
          </Text>
        </Box>
        <form id="signupForm" onSubmit={submit}>
          <SimpleGrid columns={2} gap={{ base: 0, md: 8 }}>
            <FormControl isInvalid={fNameErrors && true}>
              <FormLabel color="gray.500">First Name</FormLabel>
              <Input
                id="name"
                type="text"
                placeholder="Name"
                rounded="2xl"
                py="6"
                {...register('fName', {
                  required: 'First Name is required!',
                })}
              />
              <FormErrorMessage>
                {fNameErrors && fNameErrors.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={lNameErrors && true}>
              <FormLabel color="gray.500">Last Name</FormLabel>
              <Input
                id="name"
                type="text"
                placeholder="Name"
                rounded="2xl"
                py="6"
                {...register('lName', {
                  required: 'Last Name is required!',
                })}
              />
              <FormErrorMessage>
                {lNameErrors && lNameErrors.message}
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>
          <SimpleGrid columns={2} gap={{ base: 0, md: 8 }} pt="4">
            <FormControl
              pt={{ base: '3', md: '0' }}
              isInvalid={emailErrors && true}
            >
              <FormLabel color="gray.500">Email</FormLabel>
              <Input
                id="email"
                type="email"
                placeholder="mail@skipspot.com"
                rounded="2xl"
                py="6"
                autoFocus={Boolean(isChangeEmail)}
                {...register('email', {
                  required: 'Email is required!',
                  validate: (v) =>
                    // eslint-disable-next-line no-useless-escape
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                      v
                    ) || 'Please enter a valid Email!',
                })}
                defaultValue={signupFormState.email}
              />
              <FormErrorMessage>
                {emailErrors && emailErrors.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={phoneNumberErrors && true}>
              <FormLabel color="gray.500">Phone Number</FormLabel>
              <Controller
                control={control}
                name="phoneNumber"
                rules={{ required: true }}
                render={({ field: { ...field } }) => (
                  <PhoneInputNumber
                    {...field}
                    country="us"
                    onlyCountries={['us']}
                    placeholder="(000) 124-4567"
                    showDropdown={false}
                    disableDropdown
                    containerStyle={{ borderRadius: 20 }}
                    buttonStyle={{ display: 'none' }}
                    disableCountryCode
                    inputStyle={{
                      width: '100%',
                      borderRadius: 16,
                      padding: 10,
                      paddingTop: 24,
                      paddingBottom: 24,
                      border: '1px solid #E2E8F0',
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {phoneNumberErrors && 'Phone Number is required!'}
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <HStack
            pt={{ base: '2', md: '4' }}
            flexDir={{ base: 'column', md: 'row' }}
            spacing={{ base: 0, md: 8 }}
            alignItems="start"
          >
            <FormControl isInvalid={passwordErrors && true}>
              <FormLabel color="gray.500">Password</FormLabel>
              <Input
                py="6"
                id="password"
                type="password"
                rounded="2xl"
                placeholder="Min. 8 characters"
                {...register('password', {
                  required: 'password is required!',
                  minLength: {
                    value: 8,
                    message: 'password must be at least 8 characters',
                  },
                })}
                defaultValue={signupFormState.password}
              />
              <FormErrorMessage>
                {passwordErrors && passwordErrors.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              pt={{ base: '3', md: '0' }}
              isInvalid={confirmPasswordErrors && true}
            >
              <FormLabel color="gray.500">Confirm Password</FormLabel>
              <Input
                py="6"
                id="confirmPassword"
                type="password"
                placeholder="Min. 8 characters"
                rounded="2xl"
                {...register('confirmPassword', {
                  required: 'confirmed password  is required!',
                })}
                defaultValue={signupFormState.password}
              />
              <FormErrorMessage>
                {confirmPasswordErrors && confirmPasswordErrors.message}
              </FormErrorMessage>
            </FormControl>
          </HStack>
          <HStack
            pt={{ base: '2', md: '4' }}
            flexDir={{ base: 'column', md: 'row' }}
            spacing={{ base: 0, md: 8 }}
            alignItems="start"
          >
            <FormControl
              pt={{ base: '2', md: '0' }}
              isInvalid={businessNameErrors && true}
            >
              <FormLabel color="gray.500">Business Name</FormLabel>
              <Input
                py="6"
                id="businessName"
                type="text"
                placeholder="Bussiness Name"
                rounded="2xl"
                {...register('businessName', {})}
                defaultValue={signupFormState.businessName}
              />
              <FormErrorMessage>
                {businessNameErrors && businessNameErrors.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              pt={{ base: '3', md: '0' }}
              isInvalid={referredByErrors && true}
            >
              <FormLabel color="gray.500">Affiliate Code</FormLabel>
              <Input
                py="6"
                id="referredBy"
                type="text"
                placeholder="Affiliate Code"
                rounded="2xl"
                {...register('referredBy', {})}
                defaultValue={signupFormState.referredBy}
              />
              <FormErrorMessage>
                {referredByErrors && referredByErrors.message}
              </FormErrorMessage>
            </FormControl>
          </HStack>
          <FormControl mt={6} isInvalid={terms && true}>
            <Checkbox
              colorScheme="orange"
              color="gray.500"
              {...register('terms', {
                required: 'Please accept the terms of services!',
              })}
            >
              <Flex>
                Click here to accept
                <Text
                  pl="1.5"
                  fontWeight="medium"
                  color="brand.primary.main"
                  textDecor="underline"
                >
                  <a
                    href="https://www.theskipspot.com/terms-of-services"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of services
                  </a>
                </Text>
              </Flex>
            </Checkbox>
            <FormErrorMessage>{terms && terms.message}</FormErrorMessage>
          </FormControl>
          <Button
            id="btn"
            backgroundColor="brand.primary.main"
            colorScheme="orange"
            width="full"
            rounded="2xl"
            mt={6}
            py="6"
            type="submit"
            isLoading={isLoading}
          >
            Sign Up
          </Button>
        </form>

        <Text color="gray.500" mt={6} textAlign="center">
          Already have an Account?{' '}
          <Link to="/login" style={{ color: '#FF824C' }}>
            Sign In
          </Link>
        </Text>
      </Box>
    </AuthContainer>
  );
}

export default SignUp;
