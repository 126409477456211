import React, { useEffect } from 'react';
import {
  Flex,
  VStack,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Button,
  Box,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { ICurrentUser } from '../../interfaces';

interface FormType {
  name: string;
  email: string;
  phone: string;
  currentPassword: string;
  newPassword: string;
}

export interface UserProfileProps {
  onUpdateProfileSubmit: (name: string, phone: string) => void;
  userData: ICurrentUser;
  onUpdatePasswordSubmit: (
    currentPassword: string,
    newPassword: string
  ) => void;
  isLoadingProfile: boolean;
  isLoadingPassword: boolean;
}

export default function Profile({
  userData,
  onUpdateProfileSubmit,
  onUpdatePasswordSubmit,
  isLoadingProfile,
  isLoadingPassword,
}: UserProfileProps) {
  const { handleSubmit, register, setValue } = useForm<FormType>();

  useEffect(() => {
    setValue('name', userData?.name);
    setValue('phone', userData?.phone);
  }, [userData]);

  const handleUpdateProfileSubmit = handleSubmit((data) =>
    onUpdateProfileSubmit(data.name, data.phone)
  );

  const handleUpdatePasswordSubmit = handleSubmit((data) =>
    onUpdatePasswordSubmit(data.currentPassword, data.newPassword)
  );
  return (
    <Box w="100%" h="100vh">
      <Flex>
        <Box>
          <Box>
            <Text
              fontWeight="600"
              fontStyle="normal"
              fontSize="40px"
              color="#393330"
            >
              Edit Profile
            </Text>
          </Box>
          <form id="form" onSubmit={handleUpdateProfileSubmit}>
            <VStack mt="30px" spacing={6} align="start">
              <HStack width="400px">
                <FormControl id="name" isRequired>
                  <FormLabel>Name</FormLabel>
                  <Input
                    id="inputName"
                    type="text"
                    placeholder="Name"
                    {...register('name')}
                  />
                </FormControl>
              </HStack>

              <HStack width="400px">
                <FormControl id="email">
                  <FormLabel>Email</FormLabel>
                  <Input
                    id="inputEmail"
                    placeholder="Email"
                    value={userData?.email}
                    readOnly
                  />
                </FormControl>
              </HStack>
              <HStack width="400px">
                <FormControl id="phoneNumber" isRequired>
                  <FormLabel>Phone Number</FormLabel>
                  <Input
                    type="text"
                    placeholder="Phone Number"
                    {...register('phone')}
                  />
                </FormControl>
              </HStack>
              <HStack>
                <Button
                  id="updateProfileBtn"
                  background="brand.primary.main"
                  colorScheme="orange"
                  type="submit"
                  width={{ lg: '400px', sm: '200px' }}
                  mt="30px"
                  fontSize={14}
                  isLoading={isLoadingProfile}
                >
                  Update Profile
                </Button>
              </HStack>
            </VStack>
          </form>
        </Box>
        <Box ml={250}>
          <form id="passwordForm" onSubmit={handleUpdatePasswordSubmit}>
            <VStack mt="90px" spacing={6} align="start">
              <HStack width="400px">
                <FormControl id="currentPassword" isRequired>
                  <FormLabel>Current Password</FormLabel>
                  <Input
                    type="password"
                    placeholder="Min 8 characters"
                    {...register('currentPassword')}
                  />
                </FormControl>
              </HStack>
              <HStack width="400px">
                <FormControl id="newPassword" isRequired>
                  <FormLabel>New Password</FormLabel>
                  <Input
                    type="password"
                    minLength={8}
                    placeholder="Min 8 characters"
                    {...register('newPassword')}
                  />
                </FormControl>
              </HStack>
              <HStack>
                <Button
                  id="updatePasswordBtn"
                  background="brand.primary.main"
                  colorScheme="orange"
                  type="submit"
                  width={{ lg: '400px', sm: '200px' }}
                  mt="30px"
                  fontSize={14}
                  isLoading={isLoadingPassword}
                >
                  Update Password
                </Button>
              </HStack>
            </VStack>
          </form>
        </Box>
      </Flex>
    </Box>
  );
}
