import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Moment } from 'moment';
import React from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import { AiOutlineCalendar } from 'react-icons/ai';
import './styles.css';

interface DatePickerProps {
  startDatePicker: (start: Moment | null) => void;
  endDatePicker: (end: Moment | null) => void;
  startDate: Moment | null;
  endDate: Moment | null;
  small?: boolean;
}

export default function DatePicker({
  endDatePicker,
  startDatePicker,
  endDate,
  startDate,
  small = false,
}: DatePickerProps) {
  const [focusInput, setFocusInput] = React.useState<FocusedInputShape | null>(
    null
  );

  return (
    <DateRangePicker
      small={small}
      startDate={startDate}
      isOutsideRange={() => false}
      startDateId="your_unique_start_date_id"
      endDate={endDate}
      endDateId="your_unique_end_date_id"
      onDatesChange={({ startDate: start, endDate: end }) => {
        startDatePicker(start);
        endDatePicker(end);
      }}
      focusedInput={focusInput}
      onFocusChange={(input) => setFocusInput(input)}
      numberOfMonths={1}
      customInputIcon={<AiOutlineCalendar fontSize="20px" color="#33333" />}
      displayFormat="MMM DD, YYYY"
    />
  );
}
