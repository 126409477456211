import React from 'react';
import { VStack, Text, Container } from '@chakra-ui/react';
import CTable from './Table';

const tableHeadings = ['Status', 'Amount', 'From Customer', 'Created'];

const AffiliatesRewards = () => (
  <VStack w="full">
    <Container maxW="3xl">
      <VStack w="full" spacing="12">
        <VStack w="full" rounded="md" spacing="8" shadow="md" px="8" py="6">
          <Text fontSize="2xl" color="gray.600">
            Your latest commissions
          </Text>

          <CTable tableHeadings={tableHeadings} />
        </VStack>
      </VStack>
    </Container>
  </VStack>
);

export default AffiliatesRewards;
