import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  VStack,
  Text,
  Container,
  Avatar,
  chakra,
  Grid,
  GridItem,
  Divider,
  HStack,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  AvatarBadge,
  Tooltip,
  Skeleton,
  Box,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { BiEditAlt, BiPencil } from 'react-icons/bi';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { FaFacebook } from 'react-icons/fa';
import { AiFillTwitterCircle } from 'react-icons/ai';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import Faq from './Faq';
import ReferralCodeUpdateModal from './ReferralCodeUpdateModal';

const CLink = chakra(Link);

export interface AffiliatesDashboardProps {
  referralCode: string;
  onAccountAdd: () => void;
  accountId: string | undefined | null;
  handleAffiliateStatusUpdate: () => void;
  isLoading: boolean;
  affiliateStatus: string | undefined;
  affiliateName: string | undefined;
  statsLoading: boolean;
  statsData: {
    linkClicks: number;
    customers: number;
    signups: number;
    unpaidEarnings: number;
  };
  handleReferralCodeUpdate: (referralCode: string) => void;
  isLoadingUpdateReferralCode: boolean;
}

const AffiliatesDashboard = (props: AffiliatesDashboardProps) => {
  const {
    referralCode,
    onAccountAdd,
    accountId,
    handleAffiliateStatusUpdate,
    isLoading,
    affiliateStatus,
    affiliateName,
    statsData,
    statsLoading,
    handleReferralCodeUpdate,
    isLoadingUpdateReferralCode,
  } = props;
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleEditModal = () => {
    onOpen();
  };

  let affiliateLabel;
  let affiliateTooltipBgColor;
  let affiliateAvatarBadgeBorderColor;
  let affiliateAvatarBadgeBgColor;
  switch (affiliateStatus) {
    case 'inactive':
      affiliateLabel = affiliateStatus;
      affiliateTooltipBgColor = 'red.500';
      affiliateAvatarBadgeBorderColor = 'red.50';
      affiliateAvatarBadgeBgColor = 'red.500';
      break;
    case 'active':
      affiliateLabel = affiliateStatus;
      affiliateTooltipBgColor = 'green.500';
      affiliateAvatarBadgeBorderColor = 'green.50';
      affiliateAvatarBadgeBgColor = 'green.500';
      break;
    case 'requested':
      affiliateLabel = affiliateStatus;
      affiliateTooltipBgColor = 'brand.primary.main';
      affiliateAvatarBadgeBorderColor = 'orange.50';
      affiliateAvatarBadgeBgColor = 'brand.primary.main';
      break;
    default:
      affiliateLabel = affiliateStatus;
      affiliateTooltipBgColor = 'red.500';
      affiliateAvatarBadgeBorderColor = 'red.50';
      affiliateAvatarBadgeBgColor = 'red.500';
  }

  return (
    <VStack w="full">
      <Container maxW="3xl">
        <VStack w="full" spacing="12">
          <Grid
            templateColumns="repeat(12, 1fr)"
            w="full"
            shadow="md"
            rounded="md"
            px="12"
            py="4"
          >
            <GridItem colSpan={4}>
              <VStack w="full">
                <Avatar size="2xl" src="https://bit.ly/broken-link">
                  <Tooltip
                    label={affiliateLabel}
                    bgColor={affiliateTooltipBgColor}
                    fontFamily="Poppins"
                  >
                    <AvatarBadge
                      borderColor={affiliateAvatarBadgeBorderColor}
                      bg={affiliateAvatarBadgeBgColor}
                      boxSize="2.5rem"
                    />
                  </Tooltip>
                </Avatar>
                <Text fontSize="lg" fontWeight="semibold">
                  {affiliateName}
                </Text>
                <CLink
                  textDecor="underline"
                  color="brand.primary.main"
                  to="/profile"
                >
                  Edit Profile
                </CLink>
                {affiliateStatus === 'inactive' && (
                  <Button
                    size="sm"
                    isLoading={isLoading}
                    onClick={() => handleAffiliateStatusUpdate()}
                    color="white"
                    _hover={{
                      bg: 'brand.primary.main',
                    }}
                    bg="brand.primary.main"
                  >
                    Request for affiliate
                  </Button>
                )}
              </VStack>
            </GridItem>
            <GridItem colSpan={8}>
              <VStack w="full" spacing="9">
                <Grid templateColumns="repeat(12, 1fr)" w="full">
                  <GridItem colSpan={5}>
                    <VStack>
                      {statsLoading && (
                        <Box w="10" pt="3" pb="1">
                          <Skeleton height="20px" rounded="md" />
                        </Box>
                      )}
                      {statsData && (
                        <Text
                          fontSize="2xl"
                          fontWeight="black"
                          color="brand.primary.main"
                        >
                          {statsData?.signups}
                        </Text>
                      )}
                      <Text color="gray.500">SIGNUPS</Text>
                    </VStack>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <VStack w="full" h="full">
                      <Divider orientation="vertical" />
                    </VStack>
                  </GridItem>
                  <GridItem colSpan={5}>
                    <VStack>
                      {statsLoading && (
                        <Box w="10" pt="3" pb="1">
                          <Skeleton height="20px" rounded="md" />
                        </Box>
                      )}
                      {statsData && (
                        <Text
                          fontSize="2xl"
                          fontWeight="black"
                          color="brand.primary.main"
                        >
                          {statsData?.linkClicks}
                        </Text>
                      )}
                      <Text color="gray.500">CLICKS</Text>
                    </VStack>
                  </GridItem>
                </Grid>

                <Grid templateColumns="repeat(12, 1fr)" w="full">
                  <GridItem colSpan={5}>
                    <VStack>
                      {statsLoading && (
                        <Box w="10" pt="3" pb="1">
                          <Skeleton height="20px" rounded="md" />
                        </Box>
                      )}
                      {statsData && (
                        <Text
                          fontSize="2xl"
                          fontWeight="black"
                          color="brand.primary.main"
                        >
                          {statsData?.customers}
                        </Text>
                      )}
                      <Text color="gray.500">CUSTOMERS</Text>
                    </VStack>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <VStack w="full" h="full">
                      <Divider orientation="vertical" />
                    </VStack>
                  </GridItem>
                  <GridItem colSpan={5}>
                    <VStack>
                      {statsLoading && (
                        <Box w="10" pt="3" pb="1">
                          <Skeleton height="20px" rounded="md" />
                        </Box>
                      )}
                      {statsData && (
                        <Text
                          fontSize="2xl"
                          fontWeight="black"
                          color="brand.primary.main"
                        >
                          ${statsData.unpaidEarnings}
                        </Text>
                      )}
                      <Text color="gray.500">UNPAID EARNINGS</Text>
                    </VStack>
                  </GridItem>
                </Grid>
              </VStack>
            </GridItem>
          </Grid>

          {accountId ? (
            <VStack w="full" rounded="md" shadow="md" px="8" py="6">
              <HStack>
                <HStack
                  rounded="xl"
                  py="2"
                  px="4"
                  bg="brand.primary.main"
                  color="white"
                >
                  <Text>Stripe Connected Account ID: </Text>
                  <Text>{accountId}</Text>
                </HStack>
                <Button
                  p="0"
                  rounded="full"
                  onClick={() => setToggle(!toggle)}
                  color="white"
                  _hover={{
                    bg: 'brand.primary.main',
                  }}
                  bg="brand.primary.main"
                >
                  <BiEditAlt size={24} />
                </Button>
              </HStack>
              {toggle && (
                <Button
                  isLoading={loading}
                  onClick={() => {
                    onAccountAdd();
                    setLoading(true);
                  }}
                  color="white"
                  _hover={{
                    bg: 'brand.primary.main',
                  }}
                  bg="brand.primary.main"
                  size="sm"
                >
                  Connect Payment Method
                </Button>
              )}
            </VStack>
          ) : (
            <VStack w="full" rounded="md" shadow="md" px="8" py="6">
              <Text color="red.500" fontWeight="bold">
                Important
              </Text>
              <Text color="gray.500">
                You don't have any connected payment method. Please connect one
                now so we can pay you.
              </Text>
              <Button
                isLoading={loading}
                onClick={() => {
                  onAccountAdd();
                  setLoading(true);
                }}
                color="white"
                _hover={{
                  bg: 'brand.primary.main',
                }}
                bg="brand.primary.main"
                size="sm"
              >
                Connect Payment Method
              </Button>
            </VStack>
          )}

          <VStack w="full" rounded="md" shadow="md" px="8" py="6">
            <HStack>
              <VStack color="brand.primary.main">
                <HiOutlineUserCircle size={70} />
              </VStack>
              <VStack>
                <Text color="gray.500" fontWeight="bold">
                  Your reward for referring new customers
                </Text>
                <Text color="gray.500">
                  You get 20% recurring commission for each referred customer
                </Text>
              </VStack>
            </HStack>
          </VStack>

          <VStack w="full" rounded="md" shadow="md" px="8" py="6">
            <HStack>
              <VStack color="brand.primary.main">
                <HiOutlineUserCircle size={70} />
              </VStack>
              <VStack>
                <Text color="gray.500" fontWeight="bold">
                  New Customer Reward
                </Text>
                <Text color="gray.500">
                  The people that sign up using your referral code will get 10%
                  off
                </Text>
              </VStack>
            </HStack>
          </VStack>

          {referralCode ? (
            <VStack w="full" rounded="md" shadow="md" px="8" py="6">
              <Text color="gray.500" fontSize="xl" fontWeight="bold">
                Share on social media
              </Text>
              <HStack color="brand.primary.main">
                <FacebookShareButton
                  url={`${window.location.protocol}//${window.location.host}signup?r=${referralCode}`}
                  children={<FaFacebook size={40} />}
                />
                <TwitterShareButton
                  url={`${window.location.protocol}//${window.location.host}signup?r=${referralCode}`}
                  children={<AiFillTwitterCircle size={45} />}
                />
              </HStack>
              <Text color="gray.500" fontSize="lg">
                Share this referral link to your friends and followers
              </Text>

              <InputGroup size="md">
                <Input
                  disabled
                  pr="4.5rem"
                  value={`${window.location.protocol}//${window.location.host}/signup?r=${referralCode}`}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    colorScheme="orange"
                    bgColor="brand.primary.main"
                    size="sm"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        `${window.location.protocol}//${window.location.host}/signup?r=${referralCode}`
                      )
                    }
                  >
                    Copy
                  </Button>
                </InputRightElement>
              </InputGroup>

              <Text color="gray.500" fontSize="lg">
                Referral Code
              </Text>
              <InputGroup size="md">
                <Input disabled pr="4.5rem" value={referralCode} />
                <InputRightElement width="7.5rem">
                  <Tooltip
                    label="Edit Referral Code"
                    bg="brand.primary.main"
                    fontSize="xs"
                  >
                    <IconButton
                      onClick={() => handleEditModal()}
                      colorScheme="orange"
                      bgColor="brand.primary.main"
                      size="sm"
                      icon={<BiPencil />}
                      aria-label="edit"
                      mx="1.5"
                    />
                  </Tooltip>

                  <Button
                    colorScheme="orange"
                    bgColor="brand.primary.main"
                    size="sm"
                    onClick={() => navigator.clipboard.writeText(referralCode)}
                  >
                    Copy
                  </Button>
                </InputRightElement>
              </InputGroup>
            </VStack>
          ) : null}

          {/* Faqs */}
          <Faq />
        </VStack>
      </Container>
      <ReferralCodeUpdateModal
        isOpen={isOpen}
        onClose={onClose}
        referralCode={referralCode}
        updateReferralCode={handleReferralCodeUpdate}
        isLoadingUpdateReferralCode={isLoadingUpdateReferralCode}
      />
    </VStack>
  );
};

export default AffiliatesDashboard;
