import React from 'react';
import {
  Box,
  InputGroup,
  InputRightAddon,
  Input,
  Text,
  Button,
  FormErrorMessage,
  FormControl,
  Spinner,
  HStack,
} from '@chakra-ui/react';
import { IoWallet } from 'react-icons/io5';

interface PromoCodeInputProps {
  promoCode: string;
  onSetPromoCode: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onApplyPromoCode: () => void;
  error: any;
  applyPromoCodeLoading: boolean;
  message: string;
  applyPromo: boolean;
  setApplyPromo: (val: boolean) => void;
}

export default function PromoCodeInput(props: PromoCodeInputProps) {
  const {
    onSetPromoCode,
    promoCode,
    onApplyPromoCode,
    error,
    applyPromoCodeLoading,
    message,
    applyPromo,
    setApplyPromo,
  } = props;

  return (
    <Box>
      {!applyPromo ? (
        <Text
          color="brand.primary.main"
          bgColor="orange.100"
          py="2px"
          px="10px"
          fontSize="15px"
          textDecor="underline"
          cursor="pointer"
          onClick={() => setApplyPromo(true)}
        >
          Click Here To Use A Promo Code
        </Text>
      ) : applyPromoCodeLoading ? (
        <Box
          w="200px"
          h="50px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner color="brand.primary.main" thickness="3px" size="md" />
        </Box>
      ) : message ? (
        <HStack spacing={3} mt="14px">
          <Text fontSize="4xl" color="brand.primary.main">
            <IoWallet />
          </Text>
          <Box>
            <Text color="brand.primary.main" textDecor="underline">
              {message}
            </Text>
            <Text fontSize="14px">Promo Code Applied!</Text>
          </Box>
        </HStack>
      ) : (
        <FormControl isInvalid={error}>
          <InputGroup>
            <Input
              type="text"
              value={promoCode}
              onChange={onSetPromoCode}
              placeholder="Promo Code"
            />
            <InputRightAddon
              as={Button}
              colorScheme="orange"
              bgColor="brand.primary.main"
              border="1px"
              borderColor="brand.primary.main"
              _focus={{
                outline: 'none',
                boxShadow: 'none',
              }}
              _hover={{
                transform: 'none',
                transition: 'none',
              }}
              onClick={onApplyPromoCode}
            >
              Apply
            </InputRightAddon>
          </InputGroup>
          <FormErrorMessage>{error?.response?.data?.message}</FormErrorMessage>
        </FormControl>
      )}
    </Box>
  );
}
