import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
  Box,
  Text,
  IconButton,
  Flex,
  Divider,
  Spinner,
} from '@chakra-ui/react';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import FieldMapping from './FieldMapping';
import PreviewData from './PreviewData';
import CostPage from './CostPage';

interface FieldMappingModalProps {
  onClose: () => void;
  onOpen: () => void;
  isOpen: boolean;
  fieldMappingStep: number;
  onFieldMappingStepChange: (increBy: number) => void;
  filename: string;
  onSetFilename: (name: string) => void;
  fieldMappingColumns: {
    Sheet1: any;
  };
  isUserComingBack: boolean;
  onSetIsUserComingBack: (val: boolean) => void;
  selectedFields:
    | {
        value: string;
        columnIndex: number;
      }[];
  onUpdateFileDocument: () => void;
  updateFileDocLoading: boolean;
}

export default function FieldMappingModal(props: FieldMappingModalProps) {
  const {
    isOpen,
    onClose,
    onOpen,
    fieldMappingStep,
    onFieldMappingStepChange,
    fieldMappingColumns,
    filename,
    onSetFilename,
    isUserComingBack,
    onSetIsUserComingBack,
    selectedFields,
    onUpdateFileDocument,
    updateFileDocLoading,
  } = props;

  const isPropertyAddressAvailable =
    selectedFields.filter((fields) => fields.value === 'Property Address')
      .length === 0;

  // eslint-disable-next-line consistent-return
  function modalBody() {
    switch (fieldMappingStep) {
      case 0: {
        return (
          <FieldMapping
            isUserComingBack={isUserComingBack}
            fieldMappingColumns={fieldMappingColumns}
            onSetIsUserComingBack={onSetIsUserComingBack}
            isPropertyAddressAvailable={isPropertyAddressAvailable}
          />
        );
      }
      case 1: {
        return (
          <PreviewData
            onGoBack={() => {
              onSetIsUserComingBack(true);
              onFieldMappingStepChange(-1);
            }}
            onSetFilename={onSetFilename}
            filename={filename}
          />
        );
      }
      case 2: {
        return updateFileDocLoading ? (
          <Box
            w="full"
            h="full"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner color="brand.primary.main" thickness="3px" size="md" />
          </Box>
        ) : (
          <CostPage
            onFieldMappingClose={onClose}
            filename={filename}
            onFieldMappingOpen={onOpen}
          />
        );
      }
      default: {
        return <Text>Nothing To Show</Text>;
      }
    }
  }

  // eslint-disable-next-line consistent-return
  function modalHeader() {
    switch (fieldMappingStep) {
      case 0: {
        return (
          <Flex>
            <Text fontWeight="600" color="#737373" fontSize="24px">
              Field Mapping
            </Text>
            <Box mt="12px" ml="5px">
              <AiOutlineInfoCircle color="#959595" />
            </Box>
          </Flex>
        );
      }
      case 1: {
        return (
          <Flex>
            <Text fontWeight="600" color="#737373" fontSize="24px">
              Preview Data
            </Text>
            <Box mt="10px" ml="5px">
              <AiOutlineInfoCircle color="#959595" />
            </Box>
          </Flex>
        );
      }
      case 2: {
        return (
          <Flex>
            <Text fontWeight="600" color="#737373" fontSize="24px">
              Make Payment
            </Text>
            <Box mt="12px" ml="5px">
              <AiOutlineInfoCircle color="#959595" />
            </Box>
          </Flex>
        );
      }
      default: {
        return <Text>Nothing To Show</Text>;
      }
    }
  }

  return (
    <Box fontFamily="rubik">
      <Modal
        autoFocus={false}
        size="6xl"
        id="modal"
        isOpen={isOpen}
        onClose={() => {
          onSetFilename('');
          onClose();
        }}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent fontFamily="rubik">
          <ModalHeader>
            <HStack my="30px" justifyContent="space-between" w="90%" mx="auto">
              {['Field Mapping', 'Preview Data', 'Payment'].map((title, i) => (
                <React.Fragment key={i}>
                  <HStack>
                    <Box
                      bgColor={`${
                        fieldMappingStep >= i ? '#FF824C' : '#c4c4c4'
                      } `}
                      w="20px"
                      h="20px"
                      borderRadius="50px"
                    />
                    <Text
                      color={`${
                        fieldMappingStep >= i ? '#FF824C' : '#737373'
                      } `}
                      fontSize="15px"
                    >
                      {title}
                    </Text>
                  </HStack>
                  {i < 2 && (
                    <Box w="30%">
                      <Divider
                        border="none"
                        bgColor={`${
                          fieldMappingStep >= i + 1 ? '#FF824C' : '#c4c4c4'
                        } `}
                        h="1.5px"
                      />
                    </Box>
                  )}
                </React.Fragment>
              ))}
            </HStack>
            {modalHeader()}
          </ModalHeader>
          <ModalCloseButton
            color="brand.primary.main"
            _focus={{ offset: '0' }}
            onClick={() => {
              onSetIsUserComingBack(false);
            }}
          />
          <ModalBody>{modalBody()}</ModalBody>

          <ModalFooter>
            <HStack mx="auto">
              <IconButton
                colorScheme="gray"
                aria-label="back arrow"
                size="lg"
                onClick={() => {
                  onSetIsUserComingBack(true);
                  onFieldMappingStepChange(-1);
                }}
                icon={<IoIosArrowBack fontSize="20px" color="#fff" />}
                bgColor="#c4c4c4"
                disabled={fieldMappingStep === 0}
                borderRadius="50"
                _focus={{
                  boxShadow: 'none',
                }}
              />
              {fieldMappingStep === 2 ? null : (
                <>
                  <IconButton
                    className="step5"
                    colorScheme="orange"
                    aria-label="forward arrow"
                    size="lg"
                    disabled={
                      isPropertyAddressAvailable
                        ? true
                        : !!(!filename && fieldMappingStep === 1) ||
                          selectedFields.length === 0
                    }
                    onClick={() => {
                      if (fieldMappingStep === 1) {
                        onUpdateFileDocument();
                      }
                      onSetIsUserComingBack(false);
                      onFieldMappingStepChange(1);
                    }}
                    icon={<IoIosArrowForward fontSize="20px" color="#fff" />}
                    bgColor="brand.primary.main"
                    _focus={{
                      boxShadow: 'none',
                    }}
                    borderRadius="50"
                    _hover={{
                      pointerEvents: 'all',
                    }}
                  />
                </>
              )}
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
