import React from 'react';
import { HStack } from '@chakra-ui/react';
import { IPerformanceStats } from '../../../interfaces';
import { Stats } from '../..';

const PerfromanceStats = ({
  stats,
  isLoading,
}: {
  stats: IPerformanceStats | undefined;
  isLoading: boolean;
}) => {
  const performanceStats = [
    {
      id: '1',
      title: 'Records Traced',
      value: stats?.recordsTraced,
      color: 'brand.primary.main',
    },
    {
      id: '2',
      title: 'Records Traced / Customer',
      value: stats?.avgRecordsTracedPerCustomer,
      color: '#51D55E',
    },
    {
      id: '3',
      title: 'Orders Placed',
      value: stats?.ordersPlaced,
      color: '#F85959',
    },
  ];
  return (
    <HStack justify="start" spacing={5} mt="30px" w="full">
      {performanceStats.map((stat) => (
        <Stats
          id={stat?.id}
          title={stat?.title}
          value={stat?.value}
          color={stat?.color}
          isLoading={isLoading}
        />
      ))}
    </HStack>
  );
};

export default PerfromanceStats;
