import moment, { Moment } from 'moment';
import { useQuery, useMutation } from 'react-query';
import { API } from '../constants';
import { ISingleLeads, IAddress, ISkiptraceStats } from '../interfaces';
import { apiClient, queryClient } from '../services';

export const useGetSkiptraceFilesRecords = () => {
  const getSkiptraceFilesRecords = async () => {
    const { data } = await apiClient.get(API.ROUTES.SKIPTRACE_FIlES);
    return data;
  };
  return useQuery('SkiptraceFilesRecords', getSkiptraceFilesRecords);
};

export const useUploadFile = () => {
  const uploadFile = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('filename', file);

      const res = await apiClient.post(
        API.ROUTES.UPLOAD_SKIPTRACE_FIlE,
        formData
      );
      return res;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  };
  return useMutation(uploadFile);
};

export const useGetSingleLeads = () => {
  const getSingleLeads = async () => {
    const { data } = await apiClient.get<{
      stats: ISkiptraceStats;
      records: ISingleLeads[];
    }>(API.ROUTES.SKIPTRACE_SINGLE_LEADS);
    return data;
  };

  return useQuery('singleLeads', getSingleLeads);
};

export const useCreateSingleSkiptrace = () => {
  const ModalM = async (body: {
    businessName: string;
    firstName: string;
    lastName: string;
    propertyAddress: IAddress;
    name: string;
  }) => {
    const res = await apiClient.post(API.ROUTES.SKIPTRACE_SINGLE_LEAD, body);
    return res;
  };
  return useMutation(ModalM);
};

export const useFieldMapping = () => {
  const fieldMapping = async (body: {
    recordId: string;
    sheetName: string;
    columns: {
      value: string;
      columnIndex: number;
    }[];
    name: string;
    records: number;
    cost: number;
    testOrder: boolean;
  }) => {
    try {
      const result = await apiClient.post(API.ROUTES.SKIPTRACE_MAPPING, body);
      return result;
    } catch (error) {
      return error;
    }
  };

  return useMutation(fieldMapping, {
    onSuccess() {
      setTimeout(async () => {
        await queryClient.refetchQueries({ active: true });
      }, 3000);
    },
  });
};

export const useGetFilteredFileRecords = () => {
  const filteredFileRecords = async ({
    startDate,
    endDate,
    listName,
    status,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
    listName: string;
    status: string;
  }) => {
    const fromDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
    const toDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';

    const { data } = await apiClient.get(
      `${API.ROUTES.SKIPTRACE_FIlES}?fromDate=${fromDate}&toDate=${toDate}&status=${status}&listName=${listName}`
    );
    return data;
  };

  const {
    mutate: mutateFilteredFileRecords,
    isLoading: FilteredFileRecordsLoading,
    data: filteredFileRecordsData,
  } = useMutation(filteredFileRecords);

  return {
    mutateFilteredFileRecords,
    FilteredFileRecordsLoading,
    filteredFileRecordsData,
  };
};

export const useGetFilteredSingleLeads = () => {
  const getFilteredSingleLeads = async ({
    startDate,
    endDate,
    status,
    listName,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
    status: string;
    listName: string;
  }) => {
    const fromDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
    const toDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';

    const { data } = await apiClient.get(
      `${API.ROUTES.SKIPTRACE_SINGLE_LEADS}?fromDate=${fromDate}&toDate=${toDate}&status=${status}&name=${listName}`
    );
    return data;
  };
  const {
    mutate: mutateFilteredSingleLeads,
    data: filteredSingleLeads,
    isLoading: filteredSingleLeadsLoading,
  } = useMutation(getFilteredSingleLeads);

  return {
    filteredSingleLeads,
    filteredSingleLeadsLoading,
    mutateFilteredSingleLeads,
  };
};

export const useUpdateFileDocument = () => {
  const updateFileDocument = async (body: {
    records: number;
    cost: number;
    recordId: string;
    referralId: string;
  }) => {
    const res = await apiClient.post(
      API.ROUTES.UPLOAD_SKIPTRACE_FIlE_DOC,
      body
    );
    return res.data;
  };

  return useMutation(updateFileDocument);
};
