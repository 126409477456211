import React from 'react';
import moment from 'moment';
import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  Text,
  Box,
} from '@chakra-ui/react';
import { Badge, Empty } from '../..';
import { ISkiptraceFilesRecords } from '../../../interfaces';

const singleleadTableHeading = [
  'Date',
  'List Name',
  'Records Uploaded',
  'Hits',
  'Status',
  'Input',
  'Output',
];

interface SingleLeadTableProps {
  singleLeadsData: ISkiptraceFilesRecords[] | undefined;
}

export default function SingleLeadTable(props: SingleLeadTableProps) {
  const { singleLeadsData } = props;

  return (
    <>
      <Table mx="auto" w="100%" mt="30px" variant="simple">
        <Thead>
          <Tr boxShadow="sm">
            {singleleadTableHeading.map((heading, i) => (
              <Th
                fontFamily="poppins"
                fontSize="15px"
                color="#2F3442"
                fontWeight="600"
                key={i}
                minWidth="10px"
                py="20px"
                textAlign="center"
              >
                {heading}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {singleLeadsData === undefined ? (
            <Text>No Single Leads Records</Text>
          ) : (
            singleLeadsData.map((record) => (
              // @ts-ignore
              // eslint-disable-next-line no-underscore-dangle
              <Tr key={record._id} fontSize="14px">
                <Td color="#737373" textAlign="center">
                  {moment(record?.createdAt).format('DD-MM-YYYY')}
                </Td>
                <Td color="#737373" textAlign="center">
                  {record?.name}
                </Td>

                {/* <Td textAlign="center" w="300px" color="#737373">
                  {record.propertyAddress.address
                    ? Object.values(record.propertyAddress).join(', ')
                    : ''}
                </Td> */}
                <Td textAlign="center" color="#737373">
                  {record?.skiptraced && record.skiptraced === true ? 1 : 0}
                </Td>
                <Td textAlign="center" color="#737373">
                  {record?.skiptraced &&
                  record.skiptraced === true &&
                  record.status === 'success'
                    ? 1
                    : 0}
                </Td>

                <Td textAlign="center" width="170px">
                  <Badge
                    variant={
                      record.status.toLowerCase() === 'needsreview' ||
                      record.status.toLocaleLowerCase() === 'processing'
                        ? 'pending'
                        : record.status.toLowerCase()
                    }
                  >
                    {record.status === 'needsReview' ||
                    record.status.toLocaleLowerCase() === 'processing'
                      ? 'Pending'
                      : record.status === 'success'
                      ? 'Completed'
                      : record.status}
                  </Badge>
                </Td>
                <Td py="20px" textAlign="center">
                  {record.inputUrl && (
                    <Button
                      as="a"
                      id="SLInputFileLink"
                      colorScheme="orange"
                      size="sm"
                      bgColor="brand.primary.main"
                      href={record.inputUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View File
                    </Button>
                  )}
                </Td>

                <Td py="20px" textAlign="center">
                  {record?.outputUrl && record.status === 'success' && (
                    <Button
                      as="a"
                      id="SLdownloadLink"
                      colorScheme="orange"
                      size="sm"
                      bgColor="brand.primary.main"
                      href={record?.outputUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download
                    </Button>
                  )}
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
      {singleLeadsData?.length === 0 && (
        <Box
          id="emptyComponent"
          display="flex"
          py="12"
          w="full"
          justifyContent="center"
        >
          <Empty message="No records available" />
        </Box>
      )}
    </>
  );
}
