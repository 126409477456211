import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Text,
  Container,
  Image,
  VStack,
  Spinner,
  Heading,
  Button,
} from '@chakra-ui/react';
import { MailSentSvg } from '../../assets/svg';

interface ICVerifyEmail {
  // userInfo: ISignupFormState;
  isLoading: boolean;
  error: any;
  // onResendEmail: () => void;
  // resendEmailLoading: boolean;
}

export default function CVerifyEmail(props: ICVerifyEmail) {
  const { isLoading, error } = props;

  return (
    <Box
      fontFamily="barlow"
      w="full"
      px={{ base: '0', md: '20' }}
      height={{ base: 'full', md: '100vh' }}
      bgColor="gray.50"
      alignItems="center"
      justifyContent="center"
      display="flex"
      py={{ base: 10, md: '32px' }}
    >
      <Container
        textAlign="center"
        bgColor="#fff"
        boxShadow="lg"
        p="30px"
        borderRadius="10px"
      >
        <VStack>
          <Image w="40%" mx="auto" src={MailSentSvg} alt="mail-sent" />
          {isLoading ? (
            <>
              <Spinner size="xl" color="brand.primary.main" thickness="3px" />
              <Text color="brand.primary.main">verifying...</Text>
            </>
          ) : error ? (
            <Text color="red.400" fontSize="20px">
              {error?.response?.data.message}
            </Text>
          ) : (
            <>
              <Heading fontFamily="barlow" size="md" py="10px">
                Your email has been verified successfully
              </Heading>
              <Link to="/dashboard">
                <Button
                  id="gotoDashboardBtn"
                  colorScheme="orange"
                  bgColor="brand.primary.main"
                >
                  Go to Dashboard
                </Button>
              </Link>
            </>
          )}
        </VStack>
      </Container>
    </Box>
  );
}
