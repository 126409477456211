import { createSlice } from '@reduxjs/toolkit';
import { Moment } from 'moment';
import { IMappedColumns } from '../../interfaces/skiptrace.interface';

const appSlice = createSlice({
  name: 'skiptrace',
  initialState: {
    fieldMappingStep: 0,
    filename: '',
    isUserComingBack: false,
    destinationFields: [] as string[],
    selectedFields: [] as { value: string; columnIndex: number }[],
    SkiptraceFilesRecords: {
      status: null,
      name: null,
      url: null,
      records: null,
      outputFile: null,
    },
    fieldMappingColumns: {
      oldColumns: {
        columns: {},
        documentId: '',
      },
      mappedColumns: {
        columns: [''],
        data: [['']],
      } as IMappedColumns,
    },
    singleSkiptrace: {
      singleTraceStep: 0,
      formData: null,
      listName: '',
    },
    states: {
      bulkTrace: {
        bulkStraceData: [],
        startDate: null,
        endDate: null,
        listName: '',
        status: '',
        eligibleForReferralDiscount: true,
        referralCode: '',
      } as {
        status: string;
        listName: string;
        startDate: Moment | null;
        endDate: Moment | null;
        bulkStraceData: any[];
        eligibleForReferralDiscount: boolean;
        referralCode: string;
      },
      singleLead: {
        singleLeadData: undefined,
        singleLeadStartDate: null,
        singleLeadEndDate: null,
        status: '',
        listName: '',
      },
      orders: {
        type: '',
        status: '',
        orderStartDate: null,
        orderEndDate: null,
      },
      dashboard: {
        dashboardStartDate: null,
        dashboardEndDate: null,
      },
    },
  },
  reducers: {
    resetOrderStates: (state) => {
      state.states.orders = {
        type: '',
        status: '',
        orderStartDate: null,
        orderEndDate: null,
      };
    },
    setDashboardStartDate: (state, action) => {
      state.states.dashboard.dashboardStartDate = action.payload;
    },
    setDashboardEndDate: (state, action) => {
      state.states.dashboard.dashboardEndDate = action.payload;
    },
    setOrdersStartDate: (state, action) => {
      state.states.orders.orderStartDate = action.payload;
    },
    setOrdersEndDate: (state, action) => {
      state.states.orders.orderEndDate = action.payload;
    },
    setOrdersType: (state, action) => {
      state.states.orders.type = action.payload;
    },
    setOrdersStatus: (state, action) => {
      state.states.orders.status = action.payload;
    },
    setBulkSkiptraceStartDate: (state, action) => {
      state.states.bulkTrace.startDate = action.payload;
    },
    setBulkSkiptraceEndDate: (state, action) => {
      state.states.bulkTrace.endDate = action.payload;
    },
    setSingleLeadStartDate: (state, action) => {
      state.states.singleLead.singleLeadStartDate = action.payload;
    },
    setSingleLeadEndDate: (state, action) => {
      state.states.singleLead.singleLeadEndDate = action.payload;
    },
    setBulkStraceData: (state, action) => {
      state.states.bulkTrace.bulkStraceData = action.payload;
    },
    setSingleLeadsData: (state, action) => {
      state.states.singleLead.singleLeadData = action.payload;
    },
    setSkiptraceFilesRecords: (state, action) => {
      state.SkiptraceFilesRecords = action.payload;
    },
    setFieldMappingColumns: (state, action) => {
      state.fieldMappingColumns.oldColumns = action.payload;
      const { columns, documentId } = state.fieldMappingColumns.oldColumns;
      const sheetNames = Object.keys(columns);

      const currentSheet = sheetNames[0];

      state.fieldMappingColumns.mappedColumns = {
        // @ts-ignore
        ...state.fieldMappingColumns.oldColumns.columns[currentSheet],
        recordId: documentId,
        sheetname: currentSheet,
      };
    },
    setMappedColumns: (state, action) => {
      state.fieldMappingColumns.mappedColumns = action.payload;
    },
    setDestinationMappedColumns: (state, action) => {
      state.fieldMappingColumns.mappedColumns.columns = action.payload;
    },
    setFieldMappingStep: (state, action) => {
      if (action.payload === 0) {
        state.fieldMappingStep = 0;
      } else {
        state.fieldMappingStep += action.payload;
      }
    },
    setFilename: (state, action) => {
      state.filename = action.payload;
    },
    setListName: (state, action) => {
      state.states.bulkTrace.listName = action.payload;
    },
    setBulkStatus: (state, action) => {
      state.states.bulkTrace.status = action.payload;
    },
    setSingleStatus: (state, action) => {
      state.states.singleLead.status = action.payload;
    },
    setSingleListName: (state, action) => {
      state.states.singleLead.listName = action.payload;
    },
    setSingleTraceStep: (state, action) => {
      if (action.payload === 0) {
        state.singleSkiptrace.singleTraceStep = 0;
      } else {
        state.singleSkiptrace.singleTraceStep += action.payload;
      }
    },
    setFormData: (state, action) => {
      state.singleSkiptrace.formData = action.payload;
    },
    setSingleLeadListName: (state, action) => {
      state.singleSkiptrace.listName = action.payload;
    },
    setIsUserComingBack: (state, action) => {
      state.isUserComingBack = action.payload;
    },
    setDestinationFields: (state, action) => {
      const { value, columnIndex } = action.payload;

      if (columnIndex >= 0) {
        state.destinationFields[columnIndex] = value;
      } else {
        state.destinationFields = value;
      }
    },
    setSelectedField: (state, action) => {
      state.selectedFields = action.payload;
    },
    updateMappedColumnsData: (state, action) => {
      const { payload } = action;
      const { mappedColumns } = state.fieldMappingColumns;
      mappedColumns.cost = payload.cost;
      mappedColumns.originalCost = payload.originalCost;
    },
    resetMappedColumnsData: (state) => {
      state.fieldMappingColumns.mappedColumns = {
        columns: [''],
        data: [['']],
        records: 0,
        cost: 0,
        sheetname: '',
        recordId: '',
        originalCost: 0,
        numOfDuplicatedRows: 0,
      };
    },
    setEligibleForReferralDiscount: (state, action) => {
      state.states.bulkTrace.eligibleForReferralDiscount = action.payload;
    },
    setReferralCode: (state, action) => {
      state.states.bulkTrace.referralCode = action.payload;
    },
  },
});

export const {
  setSkiptraceFilesRecords,
  setFieldMappingColumns,
  setMappedColumns,
  setDestinationMappedColumns,
  setFieldMappingStep,
  setFilename,
  setSingleTraceStep,
  setFormData,
  setSingleLeadListName,
  setBulkStraceData,
  setBulkSkiptraceStartDate,
  setBulkSkiptraceEndDate,
  setSingleListName,
  setSingleLeadEndDate,
  setSingleLeadStartDate,
  setSingleLeadsData,
  setOrdersEndDate,
  setOrdersStartDate,
  setOrdersStatus,
  setOrdersType,
  resetOrderStates,
  setListName,
  setDashboardEndDate,
  setDashboardStartDate,
  setBulkStatus,
  setSingleStatus,
  setIsUserComingBack,
  setDestinationFields,
  setSelectedField,
  updateMappedColumnsData,
  resetMappedColumnsData,
  setReferralCode,
  setEligibleForReferralDiscount,
} = appSlice.actions;

export default appSlice.reducer;
