import { useMutation } from 'react-query';
import { API } from '../constants';
import { apiClient } from '../services';

const useForgotPassword = () => {
  const forgetPassword = async (email: string) => {
    const { data } = await apiClient.post(API.ROUTES.AUTH_FORGOT_PASSWORD, {
      email,
    });
    return data;
  };

  return useMutation(forgetPassword);
};

export const useResetPassword = () => {
  const resetPassword = async (body: {
    password: string;
    verificationToken: string | null;
  }) => {
    const { password, verificationToken } = body;
    const res = await apiClient.post(
      `${API.ROUTES.AUTH_RESET_PASSWORD}?token=${verificationToken}`,
      { password }
    );
    return res;
  };

  return useMutation(resetPassword);
};

export default useForgotPassword;
