import {
  Login,
  SignUp,
  BulkTrace,
  Billing,
  Dashboard,
  Profile,
  PaymentRedirect,
  ForgetPassword,
  BulkFilePaymentRedirect,
  SingleLead,
  Orders,
  Success,
  AddBalanceRedirect,
  Affiliates,
  Performance,
  Referrals,
  Rewards,
  Assets,
  Payouts,
  VerifyEmail,
  ResetPassword,
} from '../containers';

export const routes = [
  {
    exact: false,
    path: '/login',
    Comp: Login,
    guarded: false,
  },
  {
    exact: false,
    path: '/signup',
    Comp: SignUp,
    guarded: false,
  },
  {
    exact: false,
    path: '/verify-email',
    Comp: VerifyEmail,
    guarded: false,
  },
  {
    path: '/forget-password',
    Comp: ForgetPassword,
    guarded: false,
  },
  {
    path: '/reset-password',
    Comp: ResetPassword,
    guarded: false,
  },
  {
    path: '/payment-redirect',
    Comp: PaymentRedirect,
    guarded: false,
  },
  {
    path: '/success',
    Comp: Success,
    guarded: false,
  },
  {
    path: '/bulk-file-payment-redirect',
    Comp: BulkFilePaymentRedirect,
    guarded: false,
  },
  {
    path: '/add-balance-payment-redirect',
    Comp: AddBalanceRedirect,
    guarded: false,
  },
  {
    exact: false,
    path: '/bulk-trace',
    Comp: BulkTrace,
    guarded: true,
  },
  {
    exact: false,
    path: '/single-lead',
    Comp: SingleLead,
    guarded: true,
  },
  {
    exact: false,
    path: '/orders',
    Comp: Orders,
    guarded: true,
  },
  {
    exact: false,
    path: '/billing',
    Comp: Billing,
    guarded: true,
  },
  {
    path: '/dashboard',
    Comp: Dashboard,
    guarded: true,
  },
  {
    path: '/profile',
    Comp: Profile,
    guarded: true,
  },
  {
    path: '/affiliates/dashboard',
    Comp: Affiliates,
    guarded: true,
  },
  {
    path: '/affiliates/performance',
    Comp: Performance,
    guarded: true,
  },
  {
    path: '/affiliates/referrals',
    Comp: Referrals,
    guarded: true,
  },
  {
    path: '/affiliates/rewards',
    Comp: Rewards,
    guarded: true,
  },
  {
    path: '/affiliates/assets',
    Comp: Assets,
    guarded: true,
  },
  {
    path: '/affiliates/payouts',
    Comp: Payouts,
    guarded: true,
  },
];
