import React, { useEffect } from 'react';
import {
  Flex,
  Box,
  Button,
  Checkbox,
  Spacer,
  Text,
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { DeepMap, FieldError, useForm } from 'react-hook-form';
import AuthContainer from '../Container';

interface FormType {
  email: string;
  password: string;
  terms: string;
}

export interface LoginProps {
  onLoginSubmit: (email: string, password: string) => void;
  errors?: DeepMap<FormType, FieldError>;
  isLoading?: boolean;
}

function Login(props: LoginProps) {
  const { formState, handleSubmit, register, setError } = useForm<FormType>();
  const { onLoginSubmit, errors, isLoading } = props;

  useEffect(() => {
    if (errors) {
      Object.entries(errors).forEach(([name, err]) => {
        if (err) setError(name as keyof FormType, err);
      });
    }
  }, [errors, setError]);

  const { email: emailErrors, password: passwordErrors } = formState.errors;

  const submit = handleSubmit(
    (data) => onLoginSubmit && onLoginSubmit(data.email, data.password)
  );
  return (
    <AuthContainer
      buttonText="Back to Signup"
      to="/signup"
      description="Welcome Back!"
    >
      <Box w="80%">
        <Box textAlign="center">
          <Text fontSize="4xl" fontWeight="bold" color="brand.primary.main">
            Login
          </Text>
          <Text color="gray.600" fontWeight="medium" mb="30px">
            Login to access your account
          </Text>
        </Box>
        <form id="loginForm" onSubmit={submit} noValidate>
          <FormControl mt={6} isInvalid={emailErrors && true}>
            <FormLabel color="gray.500">Email</FormLabel>
            <Input
              id="email"
              type="email"
              placeholder="mail@skipspot.com"
              rounded="2xl"
              py="6"
              {...register('email', {
                required: 'Email is required!',
                validate: (v) =>
                  // eslint-disable-next-line no-useless-escape
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    v
                  ) || 'Please enter a valid Email!',
              })}
            />
            <FormErrorMessage>{emailErrors?.message}</FormErrorMessage>
          </FormControl>
          <FormControl mt={6} isInvalid={passwordErrors && true}>
            <FormLabel color="gray.500">Password</FormLabel>
            <Input
              id="password"
              type="password"
              rounded="2xl"
              py="6"
              placeholder="Min. 8 characters"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('password', {
                required: 'Password is required!',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters',
                },
              })}
            />
            <FormErrorMessage>
              {passwordErrors && passwordErrors.message}
            </FormErrorMessage>
          </FormControl>

          <Flex mt={3} pt="10">
            <Checkbox colorScheme="orange" color="gray.500" defaultIsChecked>
              Remember me
            </Checkbox>
            <Spacer />
            <Box as={Link} to="/forget-password" color="brand.primary.main">
              Forgot password?
            </Box>
          </Flex>
          <Button
            id="loginBtn"
            bgColor="brand.primary.main"
            colorScheme="orange"
            mt={6}
            w="full"
            rounded="2xl"
            py="6"
            type="submit"
            isLoading={isLoading}
          >
            Login
          </Button>
        </form>
        <Box mt={6} fontSize={{ base: 'md', md: 'lg' }} textAlign="center">
          <Text color="gray.500">
            Don&apos;t have an account?{' '}
            <Link to="/signup" style={{ color: '#FF824C' }}>
              Create Account
            </Link>
          </Text>
        </Box>
      </Box>
    </AuthContainer>
  );
}

export default Login;
