import React, { useEffect } from 'react';
import {
  VStack,
  HStack,
  Image,
  Text,
  Input,
  Button,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { DeepMap, FieldError, useForm } from 'react-hook-form';
import { login } from '../../assets/svg';

import { IResetPasswordState } from '../../interfaces/auth.interface';

export interface FormType {
  password: string;
  confirmPassword: string;
}

export interface ResetPasswordProps {
  resetPasswordState: IResetPasswordState;
  onResetPasswordSubmit: (password: string, confirmPassword: string) => void;
  isLoading: boolean;
  errors?: DeepMap<FormType, FieldError>;
}

const ResetPassword = (props: ResetPasswordProps) => {
  const { resetPasswordState, onResetPasswordSubmit, errors, isLoading } =
    props;

  const { formState, handleSubmit, register, setError } = useForm<FormType>();

  useEffect(() => {
    if (errors) {
      Object.entries(errors).forEach(([name, err]) => {
        if (err) setError(name as keyof FormType, err);
      });
    }
  }, [errors, setError]);
  const { password: passwordErrors, confirmPassword: confirmPasswordErrors } =
    formState.errors;

  const submit = handleSubmit((data) =>
    onResetPasswordSubmit(data.password, data.confirmPassword)
  );

  return (
    <HStack w="100%">
      <Image src={login} w="40%" h="100vh" ml="50px" />
      <VStack spacing={5} w="100%" mx="auto">
        <Text fontWeight="600" color="#374151" fontSize="40px">
          Reset Password
        </Text>

        <form id="ResetForm" onSubmit={submit}>
          <VStack spacing={2} mx="auto" alignItems="start">
            <FormControl isInvalid={passwordErrors && true}>
              <FormLabel color="gray.500">New Password</FormLabel>
              <Input
                w="320px"
                id="password"
                type="password"
                rounded="2xl"
                placeholder="Min. 8 characters"
                {...register('password', {
                  required: 'password is required!',
                  minLength: {
                    value: 8,
                    message: 'new password must be at least 8 characters',
                  },
                })}
                defaultValue={resetPasswordState.password}
              />
              <FormErrorMessage>
                {passwordErrors && passwordErrors.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              pt={{ base: '3', md: '0' }}
              isInvalid={confirmPasswordErrors && true}
            >
              <FormLabel color="gray.500">Confirm Password</FormLabel>
              <Input
                w="320px"
                id="confirmPassword"
                type="password"
                placeholder="Min. 8 characters"
                rounded="2xl"
                {...register('confirmPassword', {
                  required: 'confirmed password  is required!',
                })}
                defaultValue={resetPasswordState.password}
              />
              <FormErrorMessage>
                {confirmPasswordErrors && confirmPasswordErrors.message}
              </FormErrorMessage>
            </FormControl>
          </VStack>

          <Button
            isLoading={isLoading}
            id="resetPasswordBtn"
            background="brand.primary.main"
            colorScheme="orange"
            px="30px"
            w="full"
            mt={8}
            type="submit"
          >
            Submit
          </Button>
        </form>
      </VStack>
    </HStack>
  );
};

export default ResetPassword;
