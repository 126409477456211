import { CancelTokenSource } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { apiClient } from '../services';
import { API } from '../constants';

export const useCreatePaymentMethod = () => {
  const handleRequest = async (body: {
    paymentMethodId: string | undefined;
    cancelToken: CancelTokenSource;
  }) => {
    const result = await apiClient.post(API.ROUTES.CREATE_PAYMENT_METHOD, {
      paymentMethodId: body.paymentMethodId,
      cancelToken: body.cancelToken,
    });
    return result;
  };

  const { mutate } = useMutation(handleRequest);
  const mutateCreatePaymentMethod = mutate;
  return { mutateCreatePaymentMethod };
};

export const useCreatePaymentIntent = () => {
  const handleSubmit = async (body: {
    amount: number;
    cost: number | undefined;
  }) => {
    const result = await apiClient.post(API.ROUTES.CREATE_PAYMENT_INTENT, {
      amount: body.amount,
      cost: body.cost,
    });
    return result;
  };

  const { mutate } = useMutation(handleSubmit);
  const mutateCreatePaymentIntent = mutate;
  return { mutateCreatePaymentIntent };
};

export const useChargeWithExistingCard = () => {
  const handleRequest = async (body: {
    amount: number;
    paymentMethodId: string;
    cost: number | undefined;
  }) => {
    const result = await apiClient.post(API.ROUTES.CHARGE_WITH_EXISTING_CARD, {
      amount: body.amount,
      paymentMethodId: body.paymentMethodId,
      cost: body.cost,
    });
    return result;
  };

  const { mutate } = useMutation(handleRequest);
  const mutateChargeWithExistingCard = mutate;
  return { mutateChargeWithExistingCard };
};

export const usePayWithCurrentBalance = () => {
  const payWithCurrentBalance = async (body: {
    recordId: string;
    testOrder: boolean;
  }) => {
    const result = await apiClient.post(
      API.ROUTES.PAY_WITH_CURRENT_BALANCE,
      body
    );
    return result;
  };

  return useMutation(payWithCurrentBalance);
};

export const useGetUserInvoices = () => {
  const getUserInvoices = () => {
    const invoicesResponse = apiClient.get(API.ROUTES.SKIPTRACE_INVOICES);
    return invoicesResponse;
  };

  const { data, isLoading: invoicesLoading } = useQuery(
    'useGetUserInvoices',
    getUserInvoices
  );
  return { invoicesData: data?.data, invoicesLoading };
};

export const useApplyPromoCode = () => {
  const applyPromoCode = async (body: { code: string; recordId: string }) => {
    const res = apiClient.post(API.ROUTES.APPLY_PROMO_CODE, body);
    return res;
  };

  return useMutation(applyPromoCode);
};
