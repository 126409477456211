import React from 'react';
import {
  HStack,
  Text,
  Box,
  VStack,
  Button,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';

interface SingleLeadCostPageInterface {
  formSubmit: () => void;
  isLoading: boolean;
  onSetSingleLeadListName: (name: string) => void;
}

export default function SingleLeadCostPage(props: SingleLeadCostPageInterface) {
  const { formSubmit, isLoading, onSetSingleLeadListName } = props;
  return (
    <HStack
      align="start"
      fontFamily="Poppins"
      w="100%"
      justifyContent="space-between"
    >
      <Box w="55%" align="start" boxShadow="md" p="20px" borderRadius="md">
        <VStack align="start" spacing={2}>
          <Text fontSize="14px" fontWeight="600" color="#737373">
            Estimated cost
          </Text>

          <Text>
            <Text
              as="span"
              fontSize="50px"
              fontWeight="600"
              color="brand.primary.main"
            >
              1
            </Text>{' '}
            <Text as="span" fontWeight="600" color="#737373">
              records eligible for skip tracing
            </Text>
          </Text>

          <Text fontWeight="600">
            <Text as="span" fontSize="20px" color="#737373">
              Total cost:
            </Text>{' '}
            <Text as="span" color="brand.primary.main" fontSize="30px">
              $0.20
            </Text>
            {/* <Text as="span" color="brand.primary.main"> */}
            {/* </Text> */}
          </Text>
        </VStack>
      </Box>
      <Box w="35%" p="20px" borderRadius="md" boxShadow="md">
        <VStack align="start" spacing={10} w="full">
          <Box w="full">
            <FormControl>
              <FormLabel>List Name (optional)</FormLabel>
              <Input
                onChange={(e) => onSetSingleLeadListName(e.target.value)}
                id="singleTraceListName"
                type="text"
              />
            </FormControl>
          </Box>
          <VStack spacing={5} align="start" w="full">
            <Text>
              Pay{' '}
              <Text as="span" color="brand.primary.main">
                $0.20
              </Text>{' '}
              with current balance
            </Text>
            <Button
              w="full"
              id="makePayment"
              fontSize="18px"
              colorScheme="orange"
              bgColor="brand.primary.main"
              onClick={formSubmit}
              isLoading={isLoading}
            >
              Proceed To Pay
            </Button>
          </VStack>
        </VStack>
      </Box>
    </HStack>
  );
}
