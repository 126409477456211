import { createSlice } from '@reduxjs/toolkit';
import {
  ISignupFormState,
  IResetPasswordState,
} from '../../interfaces/auth.interface';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    signupFormState: {} as ISignupFormState,
    resetPasswordEmail: '',
    resetPasswordState: {} as IResetPasswordState,
  },
  reducers: {
    setResetPasswordEmail: (state, action) => {
      state.resetPasswordEmail = action.payload;
    },
    setSignupFormState: (state, action) => {
      state.signupFormState = action.payload;
    },
    setResetPassword: (state, action) => {
      state.resetPasswordState = action.payload;
    },
  },
});

export const { setResetPasswordEmail, setSignupFormState, setResetPassword } =
  authSlice.actions;

export default authSlice.reducer;
