import React from 'react';
import {
  VStack,
  Text,
  Container,
  Button,
  Stack,
  Skeleton,
} from '@chakra-ui/react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { BsDownload } from 'react-icons/bs';
import ReferralsTable from './ReferralsTable';
import OrdersTable from './OrdersTable';

import { IReferralsDetails } from '../../../interfaces';

const leadsHeadings = [
  'Name',
  'Date Joined',
  'Orders',
  'Traced Leads',
  'Commission Generated',
];

const ordersHeadings = [
  'Name',
  'Date Of Order',
  'Traced Leads',
  'Commission Generated',
];

interface IAffiliateReferralsProps {
  referralsDetailsData: IReferralsDetails;
  isLoading: boolean;
}

const AffiliatesReferrals = (props: IAffiliateReferralsProps) => {
  const { referralsDetailsData, isLoading } = props;

  const leadsFormatedData = React.useMemo(() => {
    return referralsDetailsData?.leads?.map((lead) => [
      lead.name,
      moment(lead.createdAt).format('DD/MM/YYYY'),
      lead.numberOfOrders,
      lead.tracedLeads,
      lead.affiliateCommission,
    ]);
  }, [referralsDetailsData]);

  const ordersFormatedData = React.useMemo(() => {
    return referralsDetailsData?.leads?.map((lead) => [
      lead.name,
      moment(lead.createdAt).format('DD/MM/YYYY'),
      lead.tracedLeads,
      lead.affiliateCommission,
    ]);
  }, [referralsDetailsData]);

  return (
    <VStack w="full">
      <Container maxW="3xl">
        <VStack w="full" spacing="12">
          <VStack
            align="flex-end"
            w="full"
            rounded="md"
            spacing="8"
            shadow="md"
            px="8"
            py="6"
          >
            {leadsFormatedData?.length ? (
              <CSVLink
                headers={leadsHeadings}
                data={leadsFormatedData}
                filename={`affiliate-leads-${moment().format(
                  'MMMM Do YYYY, h:mm:ss a'
                )}`}
              >
                <Button
                  size="sm"
                  rightIcon={<BsDownload />}
                  id="leadsDownloadBtn"
                  colorScheme="orange"
                  bgColor="brand.primary.main"
                >
                  Download as CSV
                </Button>
              </CSVLink>
            ) : null}
            <Text textAlign="center" w="full" fontSize="2xl" color="gray.600">
              Referrals
            </Text>

            {isLoading ? (
              <Stack w="full">
                <Skeleton height="20px" mt="10px" />
                <Skeleton height="20px" mt="10px" />
              </Stack>
            ) : (
              <ReferralsTable
                tableHeadings={leadsHeadings}
                tableData={referralsDetailsData?.leads}
              />
            )}

            <Text fontSize="sm" color="gray.600">
              Note: This is just an approximation, the values shown on this
              table might be lower than real values.
            </Text>
          </VStack>

          <VStack
            align="flex-end"
            w="full"
            rounded="md"
            spacing="8"
            shadow="md"
            px="8"
            py="6"
          >
            {leadsFormatedData?.length ? (
              <CSVLink
                headers={ordersHeadings}
                data={ordersFormatedData}
                filename={`affiliate-orders-${moment().format(
                  'MMMM Do YYYY, h:mm:ss a'
                )}`}
              >
                <Button
                  size="sm"
                  rightIcon={<BsDownload />}
                  id="ordersDownloadBtn"
                  colorScheme="orange"
                  bgColor="brand.primary.main"
                >
                  Download as CSV
                </Button>
              </CSVLink>
            ) : null}

            <Text w="full" textAlign="center" fontSize="2xl" color="gray.600">
              Orders
            </Text>

            {isLoading ? (
              <Stack w="full">
                <Skeleton height="20px" mt="10px" />
                <Skeleton height="20px" mt="10px" />
              </Stack>
            ) : (
              <OrdersTable
                tableHeadings={ordersHeadings}
                tableData={referralsDetailsData?.orders}
                isLoading={isLoading}
              />
            )}

            <Text fontSize="sm" color="gray.600">
              Note: This is just an approximation, the values shown on this
              table might be lower than real values.
            </Text>
          </VStack>
        </VStack>
      </Container>
    </VStack>
  );
};

export default AffiliatesReferrals;
