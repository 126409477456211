import React from 'react';

import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
  Divider,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { BiFileBlank } from 'react-icons/bi';
import Form from './Form';
import { IAddress, ISingleSkipTraceForm } from '../../../interfaces';
import CostPage from '../SingleLeadCostPage';

export interface ISingleModalProps {
  onSingleSkiptraceModalSubmit: (
    businessName: string,
    firstName: string,
    lastName: string,
    propertyAddress: IAddress,
    name: string,
    reset: () => void,
    onClose: () => void,
    onSingleTraceStepChange: (increBy: number) => void,
    resetAddresses: () => void
  ) => void;
  isLoading: boolean;
  singleTraceStep: number;
  onSingleTraceStepChange: (increBy: number) => void;
  singLeadListName: string;
  onSingleLeadListNameChange: (name: string) => void;
  formData: ISingleSkipTraceForm | null;
  onSetFormData: (data: ISingleSkipTraceForm) => void;
}

export default function SingleleadModal(props: ISingleModalProps) {
  const {
    onSingleSkiptraceModalSubmit,
    isLoading,
    onSingleTraceStepChange,
    singleTraceStep,
    onSingleLeadListNameChange,
    singLeadListName,
    formData,
    onSetFormData,
  } = props;
  const [propertyAddress, setPropertyAddress] = React.useState({ address: '' });
  const { reset } = useForm<ISingleSkipTraceForm>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const resetAddresses = () => {
    setPropertyAddress({ address: '' });
  };

  const formSubmit = () => {
    if (formData) {
      onSingleSkiptraceModalSubmit(
        formData.businessName,
        formData.firstName,
        formData.lastName,
        propertyAddress,
        singLeadListName,
        reset,
        onClose,
        onSingleTraceStepChange,
        resetAddresses
      );
    }
  };

  const renderModalBody = () => {
    switch (singleTraceStep) {
      case 0: {
        return (
          <Box id="popUp" mt="3px" mb="10px" ml="20px" mr="20px">
            <Form
              propertyAddress={propertyAddress}
              setPropertyAddress={setPropertyAddress}
              onSingleTraceStepChange={onSingleTraceStepChange}
              onSetFormData={onSetFormData}
            />
          </Box>
        );
      }
      case 1: {
        return (
          <VStack mb="30px" align="start" p="0px 20px">
            <CostPage
              onSetSingleLeadListName={onSingleLeadListNameChange}
              formSubmit={formSubmit}
              isLoading={isLoading}
            />
            ;
          </VStack>
        );
      }
      default: {
        return null;
      }
    }
  };

  const renderModalHeader = () => {
    switch (singleTraceStep) {
      case 0: {
        return (
          <Box fontSize="25px" mt="5px">
            Single Skip Trace
          </Box>
        );
      }
      case 1: {
        return (
          <Box fontSize="25px" ml="20px" mt="5px">
            Payment
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div>
      <Button
        mt={{ sm: '1', xl: '0' }}
        h="43px"
        id="modalBtn"
        colorScheme="orange"
        bgColor="brand.primary.main"
        color="#fff"
        leftIcon={<BiFileBlank />}
        onClick={onOpen}
      >
        <Text fontWeight="light">Single Skiptrace</Text>
      </Button>

      <Modal
        closeOnOverlayClick={false}
        autoFocus={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent maxW="80%" fontFamily="rubik">
          <HStack my="30px" justifyContent="space-between" w="60%" mx="auto">
            {['Single Skiptace', 'Payment'].map((title, i) => (
              <React.Fragment key={i}>
                <HStack>
                  <Box
                    bgColor={`${singleTraceStep >= i ? '#FF824C' : '#c4c4c4'} `}
                    w="16px"
                    h="16px"
                    borderRadius="50px"
                  />
                  <Text
                    color={`${singleTraceStep >= i ? '#FF824C' : '#737373'} `}
                    fontSize="12px"
                    fontWeight="500"
                  >
                    {title}
                  </Text>
                </HStack>
                {i < 1 && (
                  <Box w="60%">
                    <Divider
                      border="none"
                      bgColor={`${
                        singleTraceStep >= i + 1 ? '#FF824C' : '#c4c4c4'
                      } `}
                      h="1.5px"
                    />
                  </Box>
                )}
              </React.Fragment>
            ))}
          </HStack>
          <ModalHeader>{renderModalHeader()}</ModalHeader>
          <ModalCloseButton
            onClick={() => onSingleTraceStepChange(0)}
            color="brand.primary.main"
            _focus={{ offset: 0 }}
          />

          <ModalBody>{renderModalBody()}</ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
