import * as React from 'react';
import ReactDOM from 'react-dom';
import 'focus-visible/dist/focus-visible';
import 'react-dates/initialize';
import 'react-phone-input-2/lib/style.css';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { queryClient } from './services';
import { skipSpotTheme } from './theme/theme';
import store from './slices';
import App from './App';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={skipSpotTheme}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
