import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  HStack,
  Text,
  Box,
  VStack,
  Divider,
  Button,
  useToast,
} from '@chakra-ui/react';
import Joyride, { CallBackProps } from 'react-joyride';
import PromoCodeInput from './PromoCodeInput';
import { RootState } from '../../../../slices';
import { resetState, resetSuccess } from '../../../../slices/billing';
import {
  resetMappedColumnsData,
  updateMappedColumnsData,
} from '../../../../slices/skiptrace';
import {
  usePayWithCurrentBalance,
  useGetCurrentUser,
  useFieldMapping,
  useApplyPromoCode,
} from '../../../../hooks';
import { costAbove5kRecords, costUnder5kRecords } from '../../../../constants';
import PaddleCheckout from '../../../PaddleCheckout';

export default function CostPage(props: {
  onFieldMappingClose: () => void;
  onFieldMappingOpen: () => void;
  filename: string;
}) {
  const [run, setRun] = useState(false);
  const { onFieldMappingClose, filename, onFieldMappingOpen } = props;

  // react-query hooks
  const { refetch } = useGetCurrentUser();
  const { mutate: FieldMappingMutate } = useFieldMapping();
  const { mutate, isLoading } = usePayWithCurrentBalance();
  const {
    mutate: applyPromoCodeMutate,
    data,
    error,
    isLoading: applyPromoCodeLoading,
  } = useApplyPromoCode();

  // react-redux hooks
  const dispatch = useDispatch();
  const {
    skiptrace: {
      fieldMappingColumns: {
        mappedColumns: {
          records,
          cost,
          sheetname,
          recordId,
          originalCost,
          numOfDuplicatedRows,
        },
      },
      selectedFields,
      states: {
        bulkTrace: { eligibleForReferralDiscount, referralCode },
      },
    },
    app: { tourStarted },
  } = useSelector((state: RootState) => state);

  const {
    billing: { successUrl, success },
    app: { currentUser },
  } = useSelector((state: RootState) => state);

  const { freeRecordsLimit, balance } = currentUser!;

  // chakra-ui hooks
  const toast = useToast();

  // local state
  const [applyPromo, setApplyPromo] = React.useState(
    eligibleForReferralDiscount
  );
  const [promoCode, setPromoCode] = React.useState<string>('');

  const onSetPromoCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(e.target.value.toUpperCase());
  };

  React.useEffect(() => {
    setPromoCode(() => {
      if (eligibleForReferralDiscount) {
        return referralCode;
      }
      return '';
    });
  }, [referralCode, eligibleForReferralDiscount]);

  useEffect(() => {
    if (success) {
      onFieldMappingClose();
      dispatch(resetSuccess());
    }
  }, [success]);

  useEffect(() => {
    if (tourStarted && !JSON.parse(localStorage.getItem('step8')!)) {
      setRun(true);
    }
  }, [tourStarted, JSON.parse(localStorage.getItem('step8')!)]);

  React.useEffect(() => {
    dispatch(resetState());
  }, []);

  const closeFieldMappingModal = () => {
    dispatch(resetMappedColumnsData());
    onFieldMappingClose();
  };

  const onFieldMapping = () => {
    FieldMappingMutate(
      {
        columns: selectedFields,
        recordId,
        sheetName: sheetname,
        name: filename,
        records,
        cost,
        testOrder: Boolean(freeRecordsLimit),
      },
      {
        onSuccess: async (res: any) => {
          toast({
            title: res.data.message,
            duration: 4000,
            isClosable: true,
            position: 'top-right',
            status: 'success',
          });

          closeFieldMappingModal();
        },
        onError: (err) => {
          toast({
            // @ts-ignore
            title: err.response.data.message,
            duration: 4000,
            isClosable: true,
            position: 'top-right',
            status: 'error',
          });
        },
      }
    );
  };

  const onPayWithCurrentBalance = () => {
    mutate(
      {
        recordId,
        testOrder: Boolean(freeRecordsLimit),
      },
      {
        onSuccess(result) {
          toast({
            title: result.data.message,
            duration: 4000,
            isClosable: true,
            position: 'top-right',
            status: 'success',
          });
          refetch();
          onFieldMapping();
        },
        onError(err) {
          toast({
            // @ts-ignore
            title: err.response.data.message,
            duration: 4000,
            isClosable: true,
            position: 'top-right',
            status: 'error',
          });
        },
      }
    );
  };

  const onApplyPromoCode = () => {
    applyPromoCodeMutate(
      { recordId, code: promoCode },
      {
        onSuccess: (res: any) => {
          dispatch(
            updateMappedColumnsData({
              cost: res.data.cost,
              originalCost: res.data.originalCost,
            })
          );
        },
      }
    );
  };

  const splitCost = (c: number) => c?.toFixed(2).toString().split('.');

  const handleClose = (callbackData: CallBackProps) => {
    if (callbackData.action === 'close') {
      localStorage.setItem('step8', JSON.stringify(1));
    }
  };

  const payWithBalanceButton = () => {
    return successUrl || cost > balance ? null : (
      <>
        {cost > 0.7 && (
          <Box w="full">
            <Text
              textAlign="center"
              mb="15px"
              fontWeight="600"
              color="#000"
              opacity=".2"
            >
              OR
            </Text>
            <Divider />
          </Box>
        )}

        <VStack spacing={5} align="start" w="full">
          <Text>
            Pay{' '}
            <Text as="span" color="brand.primary.main">
              ${cost && freeRecordsLimit ? balance : cost.toFixed(2)}
              {originalCost ? (
                <Text as="span" px="3px" textDecor="line-through">
                  ${originalCost?.toFixed(2)}
                </Text>
              ) : null}
            </Text>{' '}
            with current balance
          </Text>
          <Button
            isDisabled={!balance}
            w="full"
            id="makePayment"
            fontSize="18px"
            colorScheme="orange"
            bgColor="brand.primary.main"
            onClick={onPayWithCurrentBalance}
            isLoading={isLoading}
          >
            Proceed To Pay
          </Button>
        </VStack>
      </>
    );
  };

  const userMessage = () => {
    return balance > 0 && cost > balance ? (
      <Text pb="15px" hidden={Boolean(freeRecordsLimit)} textAlign="center">
        Your current balance is{' '}
        <Text as="span" color="brand.primary.main">
          ${balance.toFixed(2)}
        </Text>{' '}
        <br />
        <br />
        Pay remaining{' '}
        <Text as="span" color="brand.primary.main">
          ${(cost - balance).toFixed(2)}
        </Text>{' '}
        {originalCost ? (
          <Text as="span" textDecor="line-through" color="brand.primary.main">
            ${(originalCost - balance)?.toFixed(2)}
          </Text>
        ) : null}{' '}
        amount with your Credit Card
      </Text>
    ) : (
      !successUrl && cost > 0.4 && (
        <Text pb="15px" hidden={Boolean(freeRecordsLimit)}>
          Pay{' '}
          <Text as="span" color="brand.primary.main">
            ${cost && `${cost.toFixed(2)}`}
            {originalCost ? (
              <Text
                as="span"
                color="brand.primary.main"
                px="3px"
                textDecor="line-through"
              >
                ${originalCost?.toFixed(2)}
              </Text>
            ) : null}
          </Text>{' '}
          with credit card
        </Text>
      )
    );
  };

  return (
    <HStack
      align="start"
      fontFamily="Poppins"
      w="100%"
      justifyContent="space-evenly"
    >
      <Box w="50%" boxShadow="md" p="20px" borderRadius="md">
        <VStack align="start" spacing={2}>
          <Text fontSize="14px" fontWeight="600" color="#737373">
            Estimated cost
          </Text>

          <Text>
            <Text
              as="span"
              fontSize="50px"
              fontWeight="600"
              color="brand.primary.main"
            >
              {freeRecordsLimit || records}
            </Text>{' '}
            <Text as="span" fontWeight="600" color="#737373">
              records eligible for skip tracing
            </Text>
            {freeRecordsLimit > 0 && (
              <Box>
                <Text
                  as="span"
                  fontSize="50px"
                  fontWeight="600"
                  color="brand.primary.main"
                >
                  {records}
                </Text>
                <Text as="span" fontWeight="600" color="#737373">
                  Total Records
                </Text>
              </Box>
            )}
            <Text opacity="0.6" hidden={Boolean(freeRecordsLimit)}>
              {records <= 5000 ? costUnder5kRecords : costAbove5kRecords}{' '}
              <Text as="span" opacity="0.4">
                per match
              </Text>
            </Text>
          </Text>

          <Divider />
          <Text fontSize="xl" fontWeight="bold" color="brand.primary.main">
            <Text
              as="span"
              fontSize="sm"
              opacity="0.7"
              color="#737373"
              fontWeight="normal"
            >
              Duplicate Records:
            </Text>{' '}
            {numOfDuplicatedRows}
          </Text>
          <Divider />

          <Text fontWeight="600" hidden={Boolean(freeRecordsLimit)}>
            <Text as="span" fontSize="20px" color="#737373">
              Total Cost:
            </Text>{' '}
            <Text as="span" color="brand.primary.main" fontSize="30px">
              {cost && `$${splitCost(cost)[0]}`}
              <Text as="span" color="brand.primary.main">
                {cost && `.${splitCost(cost)[1]}`}
              </Text>
              {originalCost ? (
                <Text
                  as="span"
                  px="14px"
                  textDecor="line-through"
                  fontSize="24px"
                >
                  ${originalCost?.toFixed(2)}
                </Text>
              ) : null}
            </Text>
          </Text>
          <Box hidden={Boolean(freeRecordsLimit)}>
            <PromoCodeInput
              onSetPromoCode={onSetPromoCode}
              promoCode={promoCode}
              onApplyPromoCode={onApplyPromoCode}
              error={error}
              applyPromoCodeLoading={applyPromoCodeLoading}
              message={data?.data.message}
              applyPromo={applyPromo}
              setApplyPromo={(val: boolean) => setApplyPromo(val)}
            />
          </Box>
          {eligibleForReferralDiscount && !data?.data.message ? (
            <Text fontSize="sm" color="orange.500">
              Use your referral code for 10% discount!
            </Text>
          ) : null}
          <Box pt="30px">
            <Text fontSize="14px" color="#737373">
              *You will only be charged for matches. The actual price you pay
              will be lower.
            </Text>
          </Box>
        </VStack>
      </Box>
      <Box w="35%" p="20px" borderRadius="md" boxShadow="md" className="step8">
        <Joyride
          run={run}
          callback={(callbackData: CallBackProps) => handleClose(callbackData)}
          steps={[
            {
              target: '.step8',
              content: (
                <div className="custom-tooltip">
                  <h1>Step 8:</h1>
                  <p>
                    {cost && cost > 0.7
                      ? 'You can pay with your current balance or you can pay though your card!'
                      : 'Click on Pay button.'}
                  </p>
                </div>
              ),
              disableBeacon: true,
              disableOverlayClose: true,
              hideCloseButton: true,
              placement: 'left',
              locale: {
                close: 'Got it',
              },
            },
          ]}
          styles={{
            options: {
              primaryColor: '#ff824c',
              zIndex: 2000,
            },
          }}
        />
        {userMessage()}
        <VStack spacing={10} w="full">
          {cost && cost > 0.7 && (
            <>
              <Box w="full" zIndex={1000}>
                <PaddleCheckout
                  isBalancePayment={false}
                  emptyUserBalance={!!(balance > 0 && cost > balance)}
                  cost={balance > 0 && cost > balance ? cost - balance : cost}
                  recordId={recordId}
                  remainingCost={cost - balance}
                  onModalClose={onFieldMappingClose}
                  onModalOpen={onFieldMappingOpen}
                  onFieldMapping={onFieldMapping}
                />
              </Box>
            </>
          )}
          {payWithBalanceButton()}
        </VStack>
      </Box>
    </HStack>
  );
}
