import React from 'react';
import { Table, Thead, Tr, Th, Tbody, Td, IconButton } from '@chakra-ui/react';
import moment from 'moment';
import { BsDownload } from 'react-icons/bs';
import type { IAffiliatesCommissionPaidData } from '../../../interfaces';

interface ICTable {
  paidcommissionsData: IAffiliatesCommissionPaidData[];
}

const tableHeadings = ['Pay Date', 'Pay Period', 'Commission Paid', 'Invoice'];

const PaidCommissionTable = ({ paidcommissionsData }: ICTable) => (
  <Table variant="simple" w="full">
    <Thead boxShadow="sm">
      <Tr>
        {tableHeadings.map((heading, i) => (
          <Th
            fontFamily="poppins"
            fontSize="15px"
            color="brand.primary.main"
            fontWeight="600"
            key={i}
            minWidth="10px"
            py="20px"
            textAlign="center"
          >
            {heading}
          </Th>
        ))}
      </Tr>
    </Thead>
    <Tbody>
      {paidcommissionsData.map((data) => (
        <Tr fontSize="14px">
          <Td color="#737373" fontWeight="400" py="20px" textAlign="center">
            {moment(data?.createdAt).format('YYYY-MM-DD')}
          </Td>

          <Td color="#737373" fontWeight="400" py="20px" textAlign="center">
            {moment(data?.payPeriodStart).format('YYYY-MM-DD')} -
            {moment(data?.payPeriodEnd).format('YYYY-MM-DD')}
          </Td>
          <Td color="#737373" fontWeight="400" py="20px" textAlign="center">
            ${data?.amount}
          </Td>
          <Td color="#737373" fontWeight="400" py="20px" textAlign="center">
            <a href={data.invoiceUrl} target="_blank" rel="noreferrer">
              <IconButton
                icon={<BsDownload />}
                aria-label="invoice-btn"
                size="sm"
                bgColor="brand.primary.main"
                colorScheme="orange"
              />
            </a>
          </Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);

export default PaidCommissionTable;
