import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { ICurrentUser } from '../../../interfaces';

interface LayoutProps {
  children?: React.ReactNode;
  balance: number;
  currentUser: ICurrentUser | null;
  logout: () => void;
}

export default function Layout(props: LayoutProps) {
  const { children, balance, currentUser, logout } = props;
  return (
    <>
      <Flex w="100%" justifyContent="space-between">
        <Box
          w="80px"
          minHeight="850px"
          bgColor="#fff"
          boxShadow="md"
          id="sidebar"
        >
          <Sidebar />
        </Box>
        <Box w="100%">
          <Flex id="header" direction="column" alignItems="flex-start">
            <Header
              logout={logout}
              balance={balance}
              currentUser={currentUser}
            />
            <Box id="children" p="30px" w="100%">
              {children}
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
}

Layout.defaultProps = {
  children: (
    <Box bgColor="red.400">
      <h1>Skip Trace</h1>
    </Box>
  ),
};
