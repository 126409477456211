import React from 'react';
import {
  Flex,
  Spinner,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Box,
} from '@chakra-ui/react';
import moment from 'moment';
import { IOrders } from '../../../interfaces';

interface OrdersTableProps {
  tableHeadings: string[];
  tableData: IOrders[];
  isLoading: boolean;
}
const OrdersTable = ({
  tableHeadings,
  tableData,
  isLoading,
}: OrdersTableProps) => {
  const [currentRows, setCurrentRows] = React.useState(10);
  return (
    <Box w="full">
      <Table mt="30px" variant="simple" size="sm">
        <Thead boxShadow="sm">
          <Tr>
            {tableHeadings.map((heading, i) => (
              <Th
                fontFamily="poppins"
                fontSize="15px"
                color="brand.primary.main"
                fontWeight="600"
                key={i}
                minWidth="10px"
                py="20px"
                textAlign="center"
              >
                {heading}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {tableData?.map(
            (data: IOrders, i: number) =>
              i < currentRows && (
                // eslint-disable-next-line no-underscore-dangle
                <Tr fontSize="14px" key={data?._id}>
                  <Td
                    color="#737373"
                    fontWeight="400"
                    maxWidth="10vw"
                    py="20px"
                    textAlign="center"
                  >
                    {data?.name}
                  </Td>
                  <Td
                    color="#737373"
                    fontWeight="400"
                    maxWidth="10vw"
                    py="20px"
                    textAlign="center"
                  >
                    {moment(data?.createdAt).format('DD/MM/YYYY')}
                  </Td>

                  <Td
                    color="#737373"
                    fontWeight="400"
                    maxWidth="10vw"
                    py="20px"
                    textAlign="center"
                  >
                    {data?.tracedLeads}
                  </Td>
                  <Td
                    color="#737373"
                    fontWeight="400"
                    maxWidth="10vw"
                    py="20px"
                    textAlign="center"
                  >
                    ${data?.affiliateCommission}
                  </Td>
                </Tr>
              )
          )}
        </Tbody>
      </Table>
      {isLoading && (
        <Flex w="full" justifyContent="center" mt="10px">
          <Spinner color="brand.primary.main" thickness="2px" size="md" />
        </Flex>
      )}

      {tableData?.length === 0 && (
        <Box mt="30px" w="100%" h="300px" color="#737373" fontWeight="400">
          <Text textAlign="center">No records available</Text>
        </Box>
      )}

      {tableData?.length > 10 && (
        <Text
          id="showAllBtn"
          color="brand.primary.main"
          textDecor="underline"
          onClick={() => {
            if (currentRows === 10) {
              setCurrentRows(tableData?.length);
            } else {
              setCurrentRows(10);
            }
          }}
          textAlign="center"
          mt="5"
          cursor="pointer"
        >
          {currentRows === tableData?.length ? 'Show Only Ten' : 'Show All'}
        </Text>
      )}
    </Box>
  );
};

export default OrdersTable;
