import React from 'react';
import {
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Box,
  Button,
  Flex,
  Spinner,
  Select,
} from '@chakra-ui/react';
import { Moment } from 'moment';
import { BsSearch } from 'react-icons/bs';
import SingleLeadsTable from './SingleLeadsTable';
import {
  IAddress,
  ISingleSkipTraceForm,
  ISkiptraceFilesRecords,
  ISkiptraceStats,
} from '../../interfaces';
import SingleSkiptraceModal from './SingleSkiptraceModal';
import { CDatePicker, SkiptraceStats, PageTitle } from '..';

export interface ISingleLeadProps {
  singleLeadsData:
    | { stats: ISkiptraceStats; records: ISkiptraceFilesRecords[] }
    | undefined;
  singleLeadsDataLoading: boolean;
  singleLeadClearFilters: () => void;
  singleLeadStartDatePicker: (start: Moment | null) => void;
  singleLeadEndDatePicker: (end: Moment | null) => void;
  singleLeadStartDate: Moment | null;
  singleLeadEndDate: Moment | null;
  onSingleSkiptraceModalSubmit: (
    businessName: string,
    firstName: string,
    lastName: string,
    propertyAddress: IAddress,
    name: string,
    reset: () => void,
    onClose: () => void,
    onSingleTraceStepChange: (increBy: number) => void,
    resetAddresses: () => void
  ) => void;
  isSingleSkiptraceLoading: boolean;
  singleTraceStep: number;
  onSingleTraceStepChange: (increBy: number) => void;
  singLeadListName: string;
  onSingleLeadListNameChange: (name: string) => void;
  formData: ISingleSkipTraceForm | null;
  onSetFormData: (data: ISingleSkipTraceForm) => void;
  onSetSingleStatus: (status: string) => void;
  status: string;
  setSingleListName: (status: string) => void;
  searchListName: string;
}

export default function CSingleLead({
  singleLeadsData,
  singleLeadsDataLoading,
  singleLeadClearFilters,
  singleLeadEndDate,
  singleLeadEndDatePicker,
  singleLeadStartDate,
  singleLeadStartDatePicker,
  isSingleSkiptraceLoading,
  onSingleSkiptraceModalSubmit,
  onSingleTraceStepChange,
  singleTraceStep,
  formData,
  onSetFormData,
  onSingleLeadListNameChange,
  onSetSingleStatus,
  singLeadListName,
  status,
  searchListName,
  setSingleListName,
}: ISingleLeadProps) {
  return (
    <>
      <PageTitle title="Single Skip Trace" />
      <div>
        <Text
          fontWeight="600"
          fontStyle="normal"
          fontSize="40px"
          color="#393330"
        >
          Single Skip Trace
        </Text>
        <HStack w="full" mt="30px" flexWrap="wrap" justifyContent="flex-end">
          <Box>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <BsSearch color="#FF824C" />
              </InputLeftElement>
              <Input
                onChange={(e) => setSingleListName(e.target.value)}
                value={searchListName}
                py="5"
                id="searchInput"
                color="#737373"
                type="text"
                focusBorderColor="brand.primary.light"
                placeholder="Search by list name"
              />
            </InputGroup>
          </Box>
          <Box>
            <Select
              h="43px"
              placeholder="Select By Status"
              color="gray.400"
              focusBorderColor="brand.primary.light"
              value={status}
              onChange={(e) => onSetSingleStatus(e.target.value)}
            >
              <option value="pending">Pending</option>
              <option value="success">Completed</option>
            </Select>
          </Box>
          <Box pt={{ sm: 1, md: '0' }}>
            <CDatePicker
              small
              startDatePicker={singleLeadStartDatePicker}
              startDate={singleLeadStartDate}
              endDate={singleLeadEndDate}
              endDatePicker={singleLeadEndDatePicker}
            />
          </Box>
          <Box>
            <Button
              mt={{ sm: 1, lg: '0' }}
              h="43px"
              id="singleSkipTrace"
              onClick={singleLeadClearFilters}
              colorScheme="orange"
              bgColor="brand.primary.main"
            >
              <Text fontWeight="light">Clear</Text>
            </Button>
          </Box>
          <Box>
            <SingleSkiptraceModal
              formData={formData}
              onSetFormData={onSetFormData}
              onSingleLeadListNameChange={onSingleLeadListNameChange}
              singLeadListName={singLeadListName}
              onSingleTraceStepChange={onSingleTraceStepChange}
              singleTraceStep={singleTraceStep}
              isLoading={isSingleSkiptraceLoading}
              onSingleSkiptraceModalSubmit={onSingleSkiptraceModalSubmit}
            />
          </Box>
        </HStack>
        {singleLeadsDataLoading ? (
          <Flex justifyContent="center" alignItems="center" h="300px" w="100%">
            <Spinner color="brand.primary.main" thickness="3px" size="lg" />
          </Flex>
        ) : (
          <>
            <SkiptraceStats stats={singleLeadsData?.stats} />
            <SingleLeadsTable singleLeadsData={singleLeadsData?.records} />
          </>
        )}
      </div>
    </>
  );
}
