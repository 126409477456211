import React, { useEffect, useState } from 'react';
import { Moment } from 'moment';
import {
  Box,
  Text,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  HStack,
  Button,
  Flex,
  Spinner,
  Select,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import Joyride, { CallBackProps } from 'react-joyride';
import { BsSearch } from 'react-icons/bs';
import { ImFilesEmpty } from 'react-icons/im';
import BulkSkipTraceModal from './BulkFileModal';
import { CDatePicker, SkiptraceStats, PageTitle } from '..';
import { ISkiptraceFilesRecords, ISkiptraceStats } from '../../interfaces';
import BulkTraceTable from './BulkTraceTable';
import FieldMappingModal from './FieldMappingModal';
import { setTourStarted } from '../../slices/app';

export interface ISkiptraceProps {
  data: {
    stats: ISkiptraceStats;
    files: ISkiptraceFilesRecords[] | undefined;
  };
  isfileUploadLoading: boolean;
  isLoading?: boolean;
  clearFilters: () => void;
  onUploadFile: (file: File) => void;
  startDatePicker: (start: Moment | null) => void;
  endDatePicker: (end: Moment | null) => void;
  startDate: Moment | null;
  endDate: Moment | null;
  isFieldMappingOpen: boolean;
  onFieldMappingClose: () => void;
  fieldMappingColumns: any;
  fieldMappingStep: number;
  onFieldMappingStepChange: (increBy: number) => void;
  filename: string;
  onSetFilename: (name: string) => void;
  onSetSearchListName: (name: string) => void;
  listName: string;
  onSetBulkStatus: (staus: string) => void;
  status: string;
  isUserComingBack: boolean;
  onSetIsUserComingBack: (val: boolean) => void;
  selectedFields: {
    value: string;
    columnIndex: number;
  }[];
  onUpdateFileDocument: () => void;
  updateFileDocLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  onFieldMappingOpen: () => void;
  tourStarted: boolean;
}

export default function BulkTrace(props: ISkiptraceProps) {
  const dispatch = useDispatch();
  const [run, setRun] = useState(false);

  const {
    data,
    isfileUploadLoading,
    onUploadFile,
    isLoading,
    endDatePicker,
    startDatePicker,
    endDate,
    startDate,
    clearFilters,
    isFieldMappingOpen,
    onFieldMappingClose,
    onFieldMappingOpen,
    fieldMappingColumns,
    fieldMappingStep,
    onFieldMappingStepChange,
    filename,
    onSetFilename,
    onSetSearchListName,
    listName,
    onSetBulkStatus,
    status,
    isUserComingBack,
    onSetIsUserComingBack,
    selectedFields,
    onUpdateFileDocument,
    updateFileDocLoading,
    isOpen,
    onClose,
    onOpen,
    tourStarted,
  } = props;

  useEffect(() => {
    onFieldMappingStepChange(0);
  }, [fieldMappingColumns]);

  useEffect(() => {
    if (tourStarted && !JSON.parse(localStorage.getItem('step2')!)) {
      setRun(true);
    }
  }, [tourStarted, JSON.parse(localStorage.getItem('step2')!)]);

  useEffect(() => {
    dispatch(setTourStarted(JSON.parse(localStorage.getItem('tourStarted')!)));
  }, [dispatch, JSON.parse(localStorage.getItem('tourStarted')!)]);

  const handleClose = (callbackData: CallBackProps) => {
    if (callbackData.action === 'close') {
      localStorage.setItem('step2', JSON.stringify(1));
    }
  };

  return (
    <>
      <PageTitle title="Bulk Skip Trace" />
      <Box w="100%" fontFamily="Poppins">
        <Box>
          <Text
            fontWeight="600"
            fontStyle="normal"
            fontSize="40px"
            color="#393330"
          >
            Bulk Skip Trace
          </Text>
          <Divider h="20px" />
        </Box>
        <FieldMappingModal
          selectedFields={selectedFields}
          filename={filename}
          onSetFilename={onSetFilename}
          fieldMappingColumns={fieldMappingColumns}
          onFieldMappingStepChange={onFieldMappingStepChange}
          fieldMappingStep={fieldMappingStep}
          onClose={onFieldMappingClose}
          onOpen={onFieldMappingOpen}
          isOpen={isFieldMappingOpen}
          isUserComingBack={isUserComingBack}
          onSetIsUserComingBack={onSetIsUserComingBack}
          onUpdateFileDocument={onUpdateFileDocument}
          updateFileDocLoading={updateFileDocLoading}
        />
        <HStack mt="30px" flexWrap="wrap" justifyContent="flex-end">
          <Box>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <BsSearch color="#FF824C" />
              </InputLeftElement>
              <Input
                py="5"
                id="searchInput"
                color="#737373"
                type="text"
                focusBorderColor="brand.primary.light"
                placeholder="Search by list name"
                value={listName}
                onChange={(e) => onSetSearchListName(e.target.value)}
              />
            </InputGroup>
          </Box>
          <Box>
            <Select
              h="43px"
              placeholder="Select By Status"
              color="gray.400"
              focusBorderColor="brand.primary.light"
              value={status}
              onChange={(e) => onSetBulkStatus(e.target.value)}
            >
              <option value="pending">Pending</option>
              <option value="success">Completed</option>
            </Select>
          </Box>
          <Box pt={{ sm: 1, md: '0' }}>
            <CDatePicker
              small
              startDatePicker={startDatePicker}
              startDate={startDate}
              endDate={endDate}
              endDatePicker={endDatePicker}
            />
          </Box>
          <Box>
            <Button
              mt={{ sm: 1, lg: '0' }}
              h="43px"
              id="clearButton"
              onClick={clearFilters}
              bgColor="brand.primary.main"
              colorScheme="orange"
            >
              <Text fontWeight="light">Clear</Text>
            </Button>
          </Box>
          <Box className="step2">
            <Joyride
              run={run}
              callback={(callbackData: CallBackProps) =>
                handleClose(callbackData)
              }
              steps={[
                {
                  target: '.step2',
                  content: (
                    <div className="custom-tooltip">
                      <h1>Step 2:</h1>
                      <p>Click on bulk skiptrace button</p>
                    </div>
                  ),
                  disableBeacon: true,
                  disableOverlayClose: true,
                  hideCloseButton: true,
                  placement: 'right',
                  locale: {
                    close: 'Got it',
                  },
                },
              ]}
              styles={{
                options: {
                  primaryColor: '#ff824c',
                },
              }}
            />
            <Button
              mt={{ sm: '1', xl: '0' }}
              h="43px"
              id="bulkSkipTrace"
              onClick={onOpen}
              colorScheme="orange"
              bgColor="brand.primary.main"
              leftIcon={<ImFilesEmpty />}
            >
              <Text fontWeight="light">Bulk SkipTrace</Text>
            </Button>
          </Box>
        </HStack>
        <BulkSkipTraceModal
          isOpen={isOpen}
          onClose={onClose}
          isLoading={isfileUploadLoading}
          onUploadFile={onUploadFile}
        />
        {isLoading ? (
          <Flex justifyContent="center" h="300px" alignItems="center" w="100%">
            <Spinner color="brand.primary.main" thickness="3px" size="lg" />
          </Flex>
        ) : (
          <>
            <SkiptraceStats stats={data.stats} />
            <BulkTraceTable bulkTraceData={data} />
          </>
        )}
      </Box>
    </>
  );
}
