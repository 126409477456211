import { lazy } from 'react';

export const Dashboard = lazy(() => import('./Dashboard'));
export const PaymentRedirect = lazy(() => import('./PaymentRedirect'));
export const AddBalanceRedirect = lazy(() => import('./AddBalanceRedirect'));
export const BulkFilePaymentRedirect = lazy(
  () => import('./BulkFilePaymentRedirect')
);
export const SignUp = lazy(() => import('./SignUp'));
export const BulkTrace = lazy(() => import('./BulkTrace'));
export const Login = lazy(() => import('./Login'));
export const Billing = lazy(() => import('./Billing'));
export const Profile = lazy(() => import('./Profile'));
export const ForgetPassword = lazy(() => import('./ForgetPassword'));
export const SingleLead = lazy(() => import('./SingleLead'));
export const Orders = lazy(() => import('./Orders'));
export const Affiliates = lazy(() => import('./Affiliates/Dashboard'));
export const Performance = lazy(() => import('./Affiliates/Performance'));
export const Referrals = lazy(() => import('./Affiliates/Referrals'));
export const Rewards = lazy(() => import('./Affiliates/Rewards'));
export const Assets = lazy(() => import('./Affiliates/Assets'));
export const Payouts = lazy(() => import('./Affiliates/Payouts'));
export const VerifyEmail = lazy(() => import('./VerifyEmail'));
export const ResetPassword = lazy(() => import('./ResetPassword'));
export { default as Success } from './Success';
