import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
// import logger from 'redux-logger';
import rootReducer from './reducer';

// const middlewares = () => {
//   if (process.env.NODE_ENV === 'development') {
//     return [...getDefaultMiddleware(), logger];
//   }

//   return [...getDefaultMiddleware()];
// };

const store = configureStore({
  reducer: rootReducer,
  // middleware: middlewares,
});

export default store;

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
