import React from 'react';
import moment, { Moment } from 'moment';
import {
  VStack,
  Container,
  Box,
  Text,
  Button,
  HStack,
  Skeleton,
  Stack,
} from '@chakra-ui/react';
import { CSVLink } from 'react-csv';
import { BsDownload } from 'react-icons/bs';
import { Stats } from '../..';
import DateFilter from '../DateFilter';
import { IAffiliatesCommissionPaidData } from '../../../interfaces';
import PaidCommissionTable from './PaidCommissionTable';

const paidcommissionsHeaders = [
  'Amount',
  'Pay Date',
  'Pay Period Start',
  'Pay Perion End',
];

interface IAffiliatesPayouts {
  setStartDate: (sDate: Moment | null) => void;
  setEndDate: (eDate: Moment | null) => void;
  startDate: Moment | null;
  endDate: Moment | null;
  fetchAllTimeStats: () => void;
  isLoading: boolean;
  paidcommissionsData: IAffiliatesCommissionPaidData[];
  revenueGenerated: number;
  commissionOwed: number;
  allPaidCommissions: number;
  statsLoading: boolean;
  skiptracedRecords: number;
  totalLeadsTraced: number;
}

const AffiliatesPayouts = ({
  setEndDate,
  setStartDate,
  startDate,
  endDate,
  fetchAllTimeStats,
  paidcommissionsData,
  isLoading,
  revenueGenerated,
  allPaidCommissions,
  commissionOwed,
  statsLoading,
  skiptracedRecords,
  totalLeadsTraced,
}: IAffiliatesPayouts) => {
  const formatedPaidcommissionsData = React.useMemo(
    () =>
      paidcommissionsData?.map((data) => [
        data.amount,
        moment(data.createdAt).format('YYYY-MM-DD'),
        moment(data.payPeriodStart).format('YYYY-MM-DD'),
        moment(data.payPeriodEnd).format('YYYY-MM-DD'),
      ]),
    [paidcommissionsData]
  );

  const topStats = React.useMemo(
    () => [
      {
        id: '1',
        title: 'Total Revenue Generated',
        value: `$${revenueGenerated?.toFixed(2)}`,
        color: 'brand.primary.main',
      },
      {
        id: '2',
        title: 'Total Traced Leads',
        value: totalLeadsTraced,
        color: '#51D55E',
      },
    ],
    [revenueGenerated, skiptracedRecords]
  );

  const futurePayoutsStats = React.useMemo(
    () => [
      // {
      //   id: '1',
      //   title: 'Skiptraced Records',
      //   value: skiptracedRecords,
      //   color: '#8147DA',
      // },
      {
        id: '2',
        title: 'Commission Owed',
        value: `$${commissionOwed?.toFixed(2)}`,
        color: '#F85959',
      },
    ],
    [skiptracedRecords, commissionOwed]
  );

  return (
    <VStack w="full">
      <Container maxW="3xl">
        <VStack spacing="8">
          <Box alignSelf="flex-end">
            {paidcommissionsData ? (
              <Button
                rightIcon={<BsDownload />}
                id="downloadBtn"
                colorScheme="orange"
                bgColor="brand.primary.main"
              >
                <CSVLink
                  data={[
                    ['Future Payouts'],
                    // TODO: show Future Payouts here...
                    [],
                    ['Paid Commissions'],
                    paidcommissionsHeaders,
                    ...formatedPaidcommissionsData,
                    [],
                    ['Revenue Generated'],
                    [revenueGenerated?.toFixed(2)],
                  ]}
                  filename={`payouts-details-${moment().format(
                    'MMMM Do YYYY, h:mm:ss a'
                  )}`}
                >
                  Download Report
                </CSVLink>
              </Button>
            ) : null}
          </Box>
          <HStack w="full" pb="20">
            {topStats?.map((stat) => (
              <Stats
                id={stat?.id}
                title={stat?.title}
                value={stat.value}
                color={stat.color}
                isLoading={statsLoading}
                className="pattern"
                boxShadow="lg"
              />
            ))}
          </HStack>
          <VStack w="full" rounded="md" spacing="8" shadow="base" p="5">
            <Text color="brand.primary.main" fontSize="24px" fontWeight="black">
              Future Payouts
            </Text>
            <HStack spacing={5} mt="30px" w="full">
              {futurePayoutsStats?.map((stat) => (
                <Box w="50%">
                  <Stats
                    id={stat?.id}
                    title={stat?.title}
                    value={stat.value}
                    color={stat.color}
                    isLoading={statsLoading}
                  />
                </Box>
              ))}
            </HStack>
          </VStack>

          <VStack align="start" w="full">
            <DateFilter
              endDate={endDate}
              startDate={startDate}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              fetchAllTimeStats={fetchAllTimeStats}
            />
          </VStack>

          <VStack w="full" rounded="md" spacing="8" shadow="base" px="5" py="6">
            <Text color="brand.primary.main" fontSize="24px" fontWeight="black">
              Paid Commissions
            </Text>
            <VStack w="full">
              <HStack w="full" spacing={10} alignItems="start">
                <Box w="50%">
                  <Stats
                    title="Commission Paid"
                    value={`$${allPaidCommissions?.toFixed(2)}`}
                    color="#51D55E"
                    isLoading={isLoading}
                  />
                </Box>
              </HStack>
              {isLoading ? (
                <Stack w="full">
                  <Skeleton height="20px" mt="10px" />
                  <Skeleton height="20px" mt="10px" />
                </Stack>
              ) : (
                <VStack w="full">
                  <PaidCommissionTable
                    paidcommissionsData={paidcommissionsData}
                  />
                  {paidcommissionsData?.length === 0 ? (
                    <Text pt="20px" color="gray.500">
                      No Data
                    </Text>
                  ) : null}
                </VStack>
              )}
            </VStack>
          </VStack>
        </VStack>
      </Container>
    </VStack>
  );
};

export default AffiliatesPayouts;
