import React from 'react';
import { Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import type { IPerformanceData } from '../../../interfaces';

interface ICTable {
  performanceDetails: IPerformanceData;
}

const tableHeadings = [
  'Link Clicks',
  'Signups',
  'Customers Acquired',
  'Earnings',
  'Earnings Per Customers',
];

const CTable = ({ performanceDetails }: ICTable) => (
  <Table mt="30px" variant="simple" size="sm">
    <Thead boxShadow="sm">
      <Tr>
        {tableHeadings.map((heading, i) => (
          <Th
            fontFamily="poppins"
            fontSize="15px"
            color="brand.primary.main"
            fontWeight="600"
            key={i}
            minWidth="10px"
            py="20px"
            textAlign="center"
          >
            {heading}
          </Th>
        ))}
      </Tr>
    </Thead>
    <Tbody>
      <Tr fontSize="14px">
        <Td
          color="#737373"
          fontWeight="400"
          maxWidth="10vw"
          py="20px"
          textAlign="center"
        >
          {performanceDetails?.linkClicks}
        </Td>
        <Td
          color="#737373"
          fontWeight="400"
          maxWidth="10vw"
          py="20px"
          textAlign="center"
        >
          {performanceDetails?.signups}
        </Td>
        <Td
          color="#737373"
          fontWeight="400"
          maxWidth="10vw"
          py="20px"
          textAlign="center"
        >
          {performanceDetails?.customersAcquired}
        </Td>
        <Td
          color="#737373"
          fontWeight="400"
          maxWidth="10vw"
          py="20px"
          textAlign="center"
        >
          ${performanceDetails?.earnings?.toFixed(2)}
        </Td>
        <Td
          color="#737373"
          fontWeight="400"
          maxWidth="10vw"
          py="20px"
          textAlign="center"
        >
          ${performanceDetails?.earningsPerCustomers?.toFixed(2)}
        </Td>
      </Tr>
    </Tbody>
  </Table>
);

export default CTable;
