import React from 'react';
import { FormControl, Select } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../slices';
import { setDestinationFields } from '../../../../../slices/skiptrace';

const DestinationFieldSelect = (props: {
  index: number;
  onFieldChange: (e: any, index: number) => void;
  destinationOptions: string[];
  sheetname: string;
  selectedFields: { value: string; columnIndex: number }[];
  isUserComingBack: boolean;
  onSetSelectedFields: (
    e: any,
    destinationField: string,
    index: number
  ) => void;
}) => {
  const {
    index,
    onFieldChange,
    destinationOptions,
    sheetname,
    selectedFields,
    isUserComingBack,
    onSetSelectedFields,
  } = props;
  const dispatch = useDispatch();

  const { destinationFields } = useSelector(
    (state: RootState) => state.skiptrace
  );

  const isSelectedFieldExist = (value: string) => {
    const exists = selectedFields.find((field) => field.value === value);
    if (exists) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    if (!isUserComingBack) {
      dispatch(setDestinationFields({ value: [] }));
      // setting to empty []
      onSetSelectedFields(null, '', 0);
    }
  }, [sheetname]);

  return (
    <FormControl key={Math.random()}>
      <Select
        id="destinationFieldSelect"
        placeholder="Select Destination Field"
        value={destinationFields[index]}
        focusBorderColor="brand.primary.main"
        onChange={(e) => {
          onFieldChange(e, index);
          onSetSelectedFields(e, destinationFields[index], index);
          dispatch(
            setDestinationFields({ value: e.target.value, columnIndex: index })
          );
        }}
      >
        {destinationOptions.map((value: string) => (
          <option
            disabled={isSelectedFieldExist(value)}
            value={value}
            key={value}
          >
            {value}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default DestinationFieldSelect;
