import React from 'react';
import { Box, VStack, HStack, Container, Button } from '@chakra-ui/react';
import moment, { Moment } from 'moment';
import { BsDownload } from 'react-icons/bs';
import { CSVLink } from 'react-csv';
import PerformanceDetails from './PerformanceDetails';
import DateFilter from '../DateFilter';
import PerfromanceStats from './PerfromanceStats';
import type { IPerformanceDetails } from '../../../interfaces';

interface AffiliatesPerformanceProps {
  startDate: Moment | null;
  endDate: Moment | null;
  setStartDate: (sDate: Moment | null) => void;
  setEndDate: (eDate: Moment | null) => void;
  performanceDetails: IPerformanceDetails;
  isLoading: boolean;
  fetchAllTimeStats: () => void;
}
const csvFileHeaders = [
  { label: 'Link Clicks', key: 'linkClicks' },
  { label: 'Signups', key: 'signups' },
  { label: 'Customers Acquired', key: 'customersAcquired' },
  { label: 'Earnings', key: 'earnings' },
  { label: 'Earnings Per Customers', key: 'earningsPerCustomers' },
];

const AffiliatesPerformance = (props: AffiliatesPerformanceProps) => {
  const {
    endDate,
    startDate,
    setEndDate,
    setStartDate,
    performanceDetails,
    isLoading,
    fetchAllTimeStats,
  } = props;

  return (
    <VStack w="full">
      <Container maxW="container.lg">
        <VStack w="full" spacing={2}>
          <HStack w="full" justify="flex-end">
            {performanceDetails !== undefined && (
              <Button
                rightIcon={<BsDownload />}
                id="downloadBtn"
                colorScheme="orange"
                bgColor="brand.primary.main"
              >
                <CSVLink
                  data={[performanceDetails?.data]}
                  headers={csvFileHeaders}
                  filename={`performance-details-${moment().format(
                    'MMMM Do YYYY, h:mm:ss a'
                  )}`}
                >
                  Download Stats
                </CSVLink>
              </Button>
            )}
            <DateFilter
              endDate={endDate}
              startDate={startDate}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              fetchAllTimeStats={fetchAllTimeStats}
            />
          </HStack>
          <Box w="full" justify="flex-start">
            <PerfromanceStats
              stats={performanceDetails?.stats}
              isLoading={isLoading}
            />
          </Box>

          <PerformanceDetails
            performanceDetails={performanceDetails?.data}
            isLoading={isLoading}
          />
        </VStack>
      </Container>
    </VStack>
  );
};

export default AffiliatesPerformance;
