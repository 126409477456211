import React from 'react';
import {
  Box,
  Text,
  Divider,
  HStack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spinner,
} from '@chakra-ui/react';
import moment from 'moment';
import { AiOutlineDownload } from 'react-icons/ai';
import { Invoices } from '../../interfaces';
import Empty from '../shared/Empty';
import { PageTitle } from '..';

interface BillingProps {
  invoices: [Invoices] | [];
  invoicesLoading: boolean;
}

const tableHeadings = ['Description', 'Date', 'Amount', 'Download'];

function Billing({ invoices, invoicesLoading }: BillingProps) {
  return (
    <>
      <PageTitle title="Billing" />
      <Box w="100%">
        <Box>
          <Text
            fontWeight="600"
            fontStyle="normal"
            fontSize="40px"
            color="#393330"
          >
            Billing
          </Text>
          <Divider h="10px" />
        </Box>
        <Text
          mt="20px"
          fontWeight="600"
          fontStyle="normal"
          fontSize="20px"
          color="#737373"
        >
          Invoices
        </Text>
        <HStack spacing="4" alignItems="flex-start">
          {invoicesLoading ? (
            <Box
              w="full"
              h="36"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner color="brand.primary.main" thickness="3px" size="md" />
            </Box>
          ) : invoices && invoices.length > 0 ? (
            <Box w="full">
              {/* <HStack mt="10px" justifyContent="space-between">
                <HStack>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <BsSearch color="#FF824C" />
                    </InputLeftElement>
                    <Input
                      id="searchInput"
                      color="#737373"
                      type="text"
                      focusBorderColor="brand.primary.light"
                      placeholder="Search"
                    />
                  </InputGroup>
                </HStack>
                <HStack>
                  <Select placeholder="Select option" color="#737373">
                    <option value="option1">Show All</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </Select>
                </HStack>
              </HStack> */}
              <Table mt="35px" variant="simple">
                <Thead>
                  <Tr boxShadow="base">
                    {tableHeadings.map((heading, i) => (
                      <Th
                        color="#737373"
                        textAlign="center"
                        fontWeight="600"
                        key={i}
                      >
                        {heading}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {invoices.map((data: Invoices, i: number) => (
                    <Tr fontSize="14px" key={i}>
                      <Td
                        fontSize="xs"
                        textAlign="center"
                        color="#737373"
                        fontWeight="400"
                      >
                        {data.description}
                      </Td>
                      <Td
                        fontSize="xs"
                        textAlign="center"
                        color="#737373"
                        fontWeight="400"
                      >
                        {moment(data.createdAt).format('MMM DD, YYYY')}
                      </Td>
                      <Td
                        fontSize="xs"
                        textAlign="center"
                        color="#737373"
                        fontWeight="400"
                      >
                        ${data.amount}
                      </Td>{' '}
                      <Td
                        fontSize="xs"
                        display="flex"
                        justifyContent="center"
                        textAlign="center"
                      >
                        <a href={data.link} target="_blank" rel="noreferrer">
                          <AiOutlineDownload size={20} color="#FF824C" />
                        </a>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          ) : (
            <Box
              w="65%"
              h="64"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Empty message="No Invoice" />
            </Box>
          )}
        </HStack>
      </Box>
    </>
  );
}
export default Billing;
