import axios from 'axios';
import { useMutation } from 'react-query';
import { API } from '../constants';

export const useUpdateProfileRecords = () => {
  const updateProfileRecords = async (body: {
    name: string;
    phone: string;
  }) => {
    const { data } = await axios.put(
      `${API.API_URL}${API.ROUTES.UPDATE_PROFILE}`,
      body,
      {
        headers: {
          'x-auth': localStorage.getItem('authToken'),
        },
      }
    );
    return data;
  };

  const { mutate, isLoading } = useMutation(updateProfileRecords);
  const mutateProfile = mutate;
  const isLoadingProfile = isLoading;
  return { mutateProfile, isLoadingProfile };
};

export const useUpdateProfilePassword = () => {
  const updateProfilePassword = async (body: {
    currentPassword: string;
    newPassword: string;
  }) => {
    const { data } = await axios.put(
      `${API.API_URL}${API.ROUTES.UPDATE_PASSWORD}`,
      body,
      {
        headers: {
          'x-auth': localStorage.getItem('authToken'),
        },
      }
    );
    return data;
  };
  const { mutate, isLoading } = useMutation(updateProfilePassword);
  const mutatePassword = mutate;
  const isLoadingPassword = isLoading;

  return { mutatePassword, isLoadingPassword };
};
