import React, { useState, useEffect } from 'react';
import {
  FormLabel,
  Select,
  FormControl,
  HStack,
  Box,
  Text,
  Input,
  VStack,
} from '@chakra-ui/react';
import Joyride, { CallBackProps } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import {
  setMappedColumns,
  setDestinationMappedColumns,
  setSelectedField,
  setDestinationFields,
} from '../../../../slices/skiptrace';
import { RootState } from '../../../../slices';
import DestinationFieldSelect from './DestinationFieldSelect';

interface FieldMappingProps {
  fieldMappingColumns: any;
  isUserComingBack: boolean;
  onSetIsUserComingBack: (val: boolean) => void;
  isPropertyAddressAvailable: boolean;
}

const destinationOptions = [
  'First Name',
  'Last Name',
  'Business Name',
  'Property Address',
  'Property State',
  'Property City',
  'Property Zip Code',
  'Mailing Address',
  'Mailing State',
  'Mailing City',
  'Mailing Zip Code',
  'Property Unit Number',
  'Mailing Unit #',
];

const prefillColumns: any = {
  Address: 'Property Address',
  'Property Address': 'Property Address',
  'Unit number': 'Property Unit Number',
  'Property Unit Number': 'Property Unit Number',
  City: 'Property City',
  'Property City': 'Property City',
  'Property State': 'Property State',
  'Zip code': 'Property Zip Code',
  'Property Zip Code': 'Property Zip Code',
  'Owner1 first name': 'First Name',
  'First Name': 'First Name',
  'Owner1 last name': 'Last Name',
  'Last Name': 'Last Name',
  'Mailing Address': 'Mailing Address',
  'Mailing Unit #': 'Mailing Unit #',
  'Mailing City': 'Mailing City',
  'Mailing Zip': 'Mailing Zip Code',
  'Mailing State': 'Mailing State',
  'Mailing Zip Code': 'Mailing Zip Code',
};

export default function FieldMapping(props: FieldMappingProps) {
  const [run, setRun] = useState(false);
  const dispatch = useDispatch();
  const {
    skiptrace: {
      fieldMappingColumns: { mappedColumns, oldColumns },
      selectedFields,
    },
    app: { tourStarted },
  } = useSelector((state: RootState) => state);
  const {
    fieldMappingColumns,
    isUserComingBack,
    onSetIsUserComingBack,
    isPropertyAddressAvailable,
  } = props;
  const sheetNames = Object.keys(fieldMappingColumns.columns);
  const [currentSheet, setCurrentSheet] = React.useState<string>(
    mappedColumns.sheetname || sheetNames[0]
  );

  useEffect(() => {
    if (tourStarted && !JSON.parse(localStorage.getItem('step4')!)) {
      setRun(true);
    }
  }, [tourStarted, JSON.parse(localStorage.getItem('step4')!)]);

  React.useEffect(() => {
    if (!isUserComingBack) {
      // @ts-ignore
      const columnsToMap = oldColumns.columns[currentSheet];
      dispatch(
        setMappedColumns({
          ...columnsToMap,
          recordId: fieldMappingColumns.documentId,
          sheetname: currentSheet,
        })
      );

      const columns = Object.keys(prefillColumns);

      const prefilledMappedColumns = columnsToMap.columns.map((val: string) => {
        if (columns.includes(val)) {
          return prefillColumns[val];
        }
        return val;
      });

      const filteredFields = columnsToMap.columns.reduce(
        (
          acc: { value: string; columnIndex: number }[],
          val: string,
          i: number
        ) => {
          if (columns.includes(val)) {
            dispatch(
              setDestinationFields({
                value: prefillColumns[val],
                columnIndex: i,
              })
            );
            acc.push({ value: prefillColumns[val], columnIndex: i });
          }
          return acc;
        },
        [] as { value: string; columnIndex: number }[]
      );

      dispatch(setDestinationMappedColumns(prefilledMappedColumns));
      dispatch(setSelectedField(filteredFields));
    }
  }, [oldColumns, currentSheet, dispatch]);

  const updateMappedColumns = (
    sourceIndex: number,
    destinationField: string
  ) => {
    const mappedCol = mappedColumns.columns.map((col: string, i: number) => {
      return i === sourceIndex ? destinationField : col;
    });

    dispatch(setDestinationMappedColumns(mappedCol));
  };

  const columns = fieldMappingColumns.columns[currentSheet];
  const onFieldChange = (e: any, index: number) => {
    const { value } = e.target;
    updateMappedColumns(index, value);
  };

  const onSetSelectedFields = (
    e: any,
    destinationField: string,
    index: number
  ) => {
    if (e) {
      const isSelectedFieldExist = selectedFields.find(
        (field) => field.value === destinationField
      );
      if (isSelectedFieldExist) {
        const filteredState = selectedFields.filter(
          (el) => el.value !== destinationField
        );
        if (e.target.value === '') {
          dispatch(setSelectedField([...filteredState]));
        } else {
          dispatch(
            setSelectedField([
              ...filteredState,
              { value: e.target.value, columnIndex: index },
            ])
          );
        }
      } else {
        dispatch(
          setSelectedField([
            ...selectedFields,
            { value: e.target.value, columnIndex: index },
          ])
        );
      }
    } else {
      dispatch(setSelectedField([]));
    }
  };

  const handleClose = (callbackData: CallBackProps) => {
    if (callbackData.status === 'finished') {
      localStorage.setItem('step4', JSON.stringify(1));
      localStorage.setItem('step5', JSON.stringify(1));
    }
  };

  return (
    <HStack fontFamily="Poppins" w="full" align="start">
      <Joyride
        run={run}
        callback={(callbackData: CallBackProps) => handleClose(callbackData)}
        steps={[
          {
            target: '.step4',
            content: (
              <div className="custom-tooltip">
                <h1>Step 4:</h1>
                <p>Map all columns in your file to the information required</p>
              </div>
            ),
            placement: 'left',
            disableBeacon: true,
            disableCloseOnEsc: true,
            disableOverlayClose: true,
          },
          {
            target: '.step5',
            content: (
              <div className="custom-tooltip">
                <h1>Step 5:</h1>
                <p>Click Next</p>
              </div>
            ),
            disableBeacon: true,
            hideBackButton: true,
            disableCloseOnEsc: true,
            disableOverlayClose: true,
            locale: {
              last: 'Got it',
            },
          },
        ]}
        styles={{
          options: {
            primaryColor: '#ff824c',
            zIndex: 2000,
          },
        }}
        continuous
      />
      <Box w="40%">
        <FormControl>
          <FormLabel fontSize="18px" color="#737373">
            Select Spreadsheet Tab
          </FormLabel>
          <Select
            w="50%"
            id="sheetSelect"
            value={currentSheet}
            onChange={(e) => {
              onSetIsUserComingBack(false);
              setCurrentSheet(e.target.value);
            }}
            focusBorderColor="brand.primary.main"
          >
            {sheetNames.map((name) => (
              <option value={name} key={name}>
                {name}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
      {columns === undefined ? (
        <Text textAlign="center" fontWeight="bold">
          Please Select Sheet
        </Text>
      ) : (
        <HStack w="60%" spacing={10} align="start" className="step4">
          <Box w="50%">
            <VStack align="start">
              <Text fontWeight="600" fontSize="18px" color="#737373">
                Source Fields
              </Text>
              {columns.columns.map((column: string) => (
                <FormControl key={Math.random()}>
                  <Input
                    focusBorderColor="brand.primary.main"
                    value={column}
                    readOnly
                  />
                </FormControl>
              ))}
            </VStack>
          </Box>

          <Box w="50%">
            <VStack align="start">
              <Text fontWeight="600" fontSize="18px" color="#737373">
                Destination Fields
              </Text>
              {columns.columns.map((col: string, i: number) => (
                <DestinationFieldSelect
                  onFieldChange={onFieldChange}
                  index={i}
                  sheetname={currentSheet}
                  destinationOptions={destinationOptions}
                  selectedFields={selectedFields}
                  isUserComingBack={isUserComingBack}
                  onSetSelectedFields={onSetSelectedFields}
                />
              ))}
              {isPropertyAddressAvailable ? (
                <Text color="red.500">Property Address is required!</Text>
              ) : null}
            </VStack>
          </Box>
        </HStack>
      )}
    </HStack>
  );
}
