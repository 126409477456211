import React from 'react';
import {
  MenuButton,
  MenuList,
  Menu,
  Button,
  // MenuItemOption,
  // MenuOptionGroup,
  Text,
  Box,
} from '@chakra-ui/react';
import { Moment } from 'moment';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { CDatePicker } from '../..';

interface DateFilterProps {
  startDate: Moment | null;
  endDate: Moment | null;
  setStartDate: (sDate: Moment | null) => void;
  setEndDate: (eDate: Moment | null) => void;
  fetchAllTimeStats?: () => void;
}

const DateFilter = (props: DateFilterProps) => {
  const { endDate, startDate, setEndDate, setStartDate, fetchAllTimeStats } =
    props;
  return (
    <Menu>
      <MenuButton
        as={Button}
        id="timeFilterBtn"
        colorScheme="orange"
        bgColor="brand.primary.main"
        rightIcon={<ChevronDownIcon />}
      >
        Filter By Time
      </MenuButton>
      <MenuList id="menuList">
        <Text
          onClick={fetchAllTimeStats}
          mx="3"
          my="2"
          p="2"
          cursor="pointer"
          bgColor={startDate || endDate ? 'white' : 'gray.100'}
          id="allTimeBtn"
        >
          All Time
        </Text>

        <Box px="10px">
          <CDatePicker
            small
            startDate={startDate}
            endDate={endDate}
            endDatePicker={setEndDate}
            startDatePicker={setStartDate}
          />
        </Box>
      </MenuList>
    </Menu>
  );
};

export default DateFilter;
