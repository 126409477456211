import React from 'react';
import Chart from 'react-apexcharts';

interface BarChartProps {
  data: string[];
}
const BarChart = ({ data }: BarChartProps) => (
  <Chart
    options={{
      noData: {
        text: 'No Data',
        align: 'center',
        verticalAlign: 'middle',
        style: {
          color: 'white',
        },
      },
      legend: {
        fontSize: '24px',
      },
      grid: {
        borderColor: 'white',
      },
      chart: {
        background: '#ffffff',
      },
      plotOptions: {
        bar: {
          columnWidth: '10px',
          borderRadius: 8,
        },
      },
      tooltip: {
        enabled: true,
        fillSeriesColor: true,
        theme: 'dark',
        style: {
          fontSize: '12px',
        },
      },
      theme: {
        mode: 'light',
        palette: '#400080',
        monochrome: {
          enabled: true,
          color: '#400080',
          shadeTo: 'light',
          shadeIntensity: 0.65,
        },
      },
      labels: data.map((month) => month[0]),
    }}
    series={[
      {
        name: 'Records Per Month',
        data: data.map((record) => record[1]),
      },
    ]}
    type="bar"
    height={300}
    offsetX={100}
  />
);

export default BarChart;
