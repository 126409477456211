import { useMutation } from 'react-query';
import moment, { Moment } from 'moment';
import { apiClient } from '../services';
import { API } from '../constants';

export const useGetUserMetrics = () => {
  const getUserMetrics = async ({
    startDate,
    endDate,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    const fromDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
    const toDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';

    const res = apiClient.get(
      `${API.ROUTES.USER_METRICS}/?startDate=${fromDate}&endDate=${toDate}`
    );
    return res;
  };

  return useMutation(getUserMetrics);
};
