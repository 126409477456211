import { useQuery } from 'react-query';
import { API } from '../constants';
import { apiClient } from '../services';

const useGetCurrentUser = () => {
  const getCurrentUser = async () => {
    const { data } = await apiClient.get(`${API.ROUTES.GET_ME}`);
    return data;
  };
  return useQuery('currentUser', getCurrentUser);
};

export default useGetCurrentUser;
