import { useMutation } from 'react-query';
import { apiClient } from '../services';
import { API } from '../constants';

export const useGeneratePayLink = () => {
  const UseGeneratePayLink = ({ cost }: { cost: number }) =>
    apiClient.post(API.ROUTES.PADDLE_GENERATE_PAY_LINK, { cost });

  return useMutation(UseGeneratePayLink);
};
