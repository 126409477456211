import { extendTheme } from '@chakra-ui/react';
import styles from './styles';
import borders from './foundations/borders';
import Input from './components/input';
import Button from './components/button';

const skipSpotTheme = extendTheme({
  styles,
  borders,
  colors: {
    brand: {
      primary: {
        main: '#FF824C',
        light: '#FF9466',
      },
    },
    success: { 500: '#51D55E' },
    loading: { 500: '#FF824C' },
  },
  fontSizes: {},
  fontWeights: {},
  fonts: {
    body: 'Poppins',
    heading: 'Poppins',
    rubik: 'Rubik, sans-serif',
    barlow: 'Barlow, sans-serif',
  },
  components: {
    Button,
    Input,
  },
});

export { skipSpotTheme };
