import React from 'react';
import { VStack, HStack, Image, Text, Input, Button } from '@chakra-ui/react';
import { login } from '../../assets/svg';

interface ForgetPasswordProps {
  onSendEmail: (e: React.FormEvent<HTMLFormElement>) => void;
  onSetEmail: (email: string) => void;
  isLoading: boolean;
}

const ForgetPassword = (props: ForgetPasswordProps) => {
  const { isLoading, onSendEmail, onSetEmail } = props;
  return (
    <HStack w="100%">
      <Image src={login} w="50%" h="100vh" />
      <VStack spacing={3} w="100%" mx="auto">
        <Text fontWeight="600" color="#374151" fontSize="40px">
          Forgotten Password
        </Text>
        <Text fontWeight="500" fontSize="14px" color="#737373">
          Enter your email to reset your password
        </Text>
        <form id="forgotForm" onSubmit={(e) => onSendEmail(e)}>
          <VStack spacing={3} mx="auto">
            <Input
              w="320px"
              id="email"
              type="email"
              placeholder="mail@skipspot.com"
              focusBorderColor="brand.primary.light"
              onChange={(e) => onSetEmail(e.target.value)}
              required
            />

            <Button
              isLoading={isLoading}
              id="loginBtn"
              background="brand.primary.main"
              colorScheme="orange"
              px="30px"
              mt={12}
              type="submit"
            >
              Submit
            </Button>
          </VStack>
        </form>
      </VStack>
    </HStack>
  );
};

export default ForgetPassword;
